import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { logos, icons, MAIL_FORMATTING } from "../helpers/constants";
import Button from '../components/Buttons/Button';
import LanguagePicker from '../components/LanguagePicker';
import * as request from "../services/FetchService";
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '../helpers/customHooks';
import { useContextData } from '../components/ContextData';
import toast from 'react-hot-toast';

const Login = (props) => {
    const MOBILE = useIsMobile();
    const { accessToken, setAccessToken } = useContextData();
    const navigate = useNavigate()
	const { t } = useTranslation();
    const [email, setEmail] = useState("")
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [errorEmail, setErrorEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorPassword, setErrorPassword] = useState("")

    useEffect(() => {

    },[])
    
    const handleChange = (type, e) => {
        if(type === "email"){
            setErrorEmail("")
            if(MAIL_FORMATTING.test(e.target.value)){
                setInvalidEmail(false)
            }else{
                setInvalidEmail(true)
            }
            setEmail(e.target.value)
        }else{
            setErrorPassword("")
            setPassword(e.target.value)
        }
    }

    const handleLoginError = (code, msg) => {
        switch(code){
            case "20018": setErrorEmail(msg); break;
            case "20016": setErrorPassword(msg); break;
            case "20116": setErrorPassword(msg); break;
            case "20019": setErrorPassword(msg); break;
            default: {}
        }
    }

    const handleLoginSuccess = async (token, username) => {
        localStorage.setItem("accesstoken", token)
        localStorage.setItem("userinfo", JSON.stringify({email: email, username: username}))
        await setAccessToken(token)
        navigate("/devices")
    }

    const handleLogin = (e) => {
        e.preventDefault();
        if (email === "" || invalidEmail) {
            setErrorEmail(t("login.invalid_email"));
            if (password === "") {
                setErrorPassword(t("login.invalid_password"));
            }
            return;
        }
        if (password === "") {
            setErrorPassword(t("login.invalid_password"));
            return;
        }
        let obj = {
            email: email,
            password: password,
            language: localStorage.getItem("language")
        }
        request.post("", "login/signin", obj).then((response) => {
            try{
                if(response.data.error || response.status !== 200){
                    toast.error(response.data.message)
                    handleLoginError(response.data.errorCode, response.data.message)
                }else{
                    toast.success(t("toast.login", {username: response.data.username}))
                    handleLoginSuccess(response.data.AccessToken, response.data.username)
                }
            }catch(error){
                
            }
		});
    }
    
    return(
        <>
        <div className="container-fluid h-100">
        <div className="d-flex justify-content-center h-100">
            <div className={`d-flex flex-column col-10 col-md-6 col-xl-4 loginfield`}>
                <img className="login_logo mx-auto mb-5" src={logos.big} alt="logo"/>
                <form onSubmit={(e) => handleLogin(e)}>
                    <div className="form-floating form-floating-group flex-grow-1 is-valid mb-5">
                        <input
                            type="email"
                            placeholder="0"
                            id="email"
                            className="form-control login-input-field pe-4"
                            name="email"
                            value={email}
                            onChange={(e) => {
                                handleChange("email", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                document.getElementById("password").focus()
                                }
                            }}
                        />
                        <img className={`position-absolute end-0 bottom-0 mb-2 ${email === "" ? "d-none" : ""}`} src={invalidEmail ? icons.cross : icons.check} alt=""/>
                        <label htmlFor="floatingInput">
                            {t("login.email_placeholder")}
                        </label>
                        <div className="invalid-feedback ms-1 bottom">
                            {errorEmail === "" ? "" : errorEmail}
                        </div>
                    </div>
                    <div className="form-floating form-floating-group flex-grow-1 is-valid">
                        <input
                            type="password"
                            placeholder="0"
                            id="password"
                            className="form-control login-input-field pe-4"
                            name="password"
                            value={password}
                            onChange={(e) => {
                                handleChange("password", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                handleLogin(e)
                                }
                            }}
                        />
                        {/* <img className={`position-absolute end-0 bottom-0 mb-1 ${email === "" ? "d-none" : ""}`} src={invalidEmail ? icons.cross : icons.check} alt=""/> */}
                        <label htmlFor="floatingInput">
                            {t("login.password_placeholder")}
                        </label>
                    </div>  
                    <div className="invalid-feedback ms-1 bottom">
                        {errorPassword === "" ? "" : errorPassword}
                    </div>
                    <p className="float-end mt-1 mb-5 cursor-pointer" onClick={() => navigate("/forgot")}>{t("login.forgot_password")}</p>
                    <Button btnClass="btn-primary w-100" onClick={(e) => handleLogin(e)}>{t("login.login")}</Button>
                    <Button btnClass="btn-transparent w-100" onClick={() => navigate("/signup")}><span className="underline">{t("login.sign_up")}</span></Button>
                </form>
            </div>
        </div>
        </div>
        <div className={`position-absolute left-0 bottom-0 ${MOBILE ? "ms-4 mb-2" : "ms-5 mb-5"} bg-grey cursor-pointer rounded`}>
            <LanguagePicker />
        </div>
        </>
    )
}

export default Login;