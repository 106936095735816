import { Accordion, AccordionSummary, Collapse } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { INDEX_URL, GOOGLE_MAPS_KEY, icons } from "../../helpers/constants";
import { fixDate } from "../../helpers/helperFunctions";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';

const FailedRow = (props) => {
    const { t } = useTranslation();

    return (
        <div className={`row m-0 p-2 primary ${props.index % 2 === 0 ? "bg-white" : "bg-light-grey"}`}>
            <div className="row d-flex align-items-center">
                <div className="col-4">
                    <p className="text-truncate bold">{props.data.name}</p>
                    <p className="m-0">{props.data.uniqueid}</p>
                </div>
                <p className="col-3 bold">{props.data.plan} {props.data.period}</p>
                <p className="col-3 bold">{props.data.payment}</p>
                <Button className="col-2" onClick={() => props.handleFailed()} btnClass="btn btn-primary gradient-button-primary">{t("subscription.handle")}</Button>
            </div>
        </div>
    )

}

export default FailedRow;