import { Accordion, AccordionSummary, Collapse } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from '../../../helpers/helperFunctions';
import { icons } from '../../../helpers/constants';
import Button from '../../Buttons/Button';

const CardRowEdit = (props) => {
    const { t } = useTranslation();
    const card = props.data.card
    const year_short = String(props.data.card.exp_year).slice(-2); // Extract the last two characters

    return (
        <div className="card d-flex flex-row p-3 justify-content-between align-items-center border-12 grey-bg">
            <p className="bold me-2">**** **** **** {card.last4} {card.exp_month}/{year_short} {capitalizeFirstLetter(card.brand)}</p>
            <Button btnClass="btn btn-danger" onClick={() => props.removeCard(props.data.id)}>{t("subscription.remove")}</Button>
            {/* <img className="size_20 red cursor-pointer" onClick={() => props.removeCard(props.data.id)} src={icons.trashcan} alt=""/> */}
        </div>
    )
}

export default CardRowEdit;