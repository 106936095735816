import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';


const  AccountModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)
    const [errorText, setErrorText] = useState("")

    useEffect(() => {
        setShow(props.show)

    },[props.show])

    
    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }


    const clearContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.trashcan} alt=""/>
                    <h3 className="ms-2">{t("settings.clear_history")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p>{t("settings.clear_history_body")}</p>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => props.clearHistory()}btnClass="btn-danger">{t("settings.clear_history")}</Button>
            </div>
            </>
        )
    }

    const logoutContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.power_off} alt=""/>
                    <h3 className="ms-2">{t("settings.logout")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p>{t("settings.logout_all_body")}</p>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => props.logoutAll()} btnClass="btn-danger">{t("settings.logout_all_sessions")}</Button>
            </div>
            </>
        )
    }

    const typeRender = () => {
        switch(props.type){
            case "clear": return clearContent()
            case "logout": return logoutContent()
            default: return null
        }
    }
    
    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                    {typeRender()}
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default AccountModal;