import React, { createContext, useContext, useEffect, useState } from 'react';
import { icons } from "../../helpers/constants";
import { useLocalStorage } from '../../helpers/customHooks';

// Initial state of mapToggles
const initialState = [
    {
        label: "show_alarms",
        value: 2,
        active: false,
        img: icons.show_alarms,
        visible: true
    },
    {
        label: "show_geofence",
        value: 3,
        active: false,
        img: icons.show_geofences,
        visible: true
    },
    {
        label: "trace_unit",
        value: 4,
        active: false,
        img: icons.trace_unit,
        visible: true
    },
    {
        label: "hide_offline",
        value: 5,
        active: false,
        img: icons.hide_offline,
        visible: true
    },
    {
        label: "cluster_markers",
        value: 6,
        active: false,
        img: icons.cluster_markers,
        visible: true
    },
    {
        label: "show_names",
        value: 7,
        active: false,
        img: icons.show_name,
        visible: true
    },
    {
        label: "show_groups",
        value: 1,
        active: false,
        img: icons.show_groups,
        visible: process.env.REACT_APP_FLAVOR === "bike" ? false : true
    }
];

const initialMap =
    [
        {
            label: "light",
            id: "light",
            value: 1,
            active: false,
            img: icons.light_map
        },
        {
            label: "dark",
            id: "dark",
            value: 2,
            active: false,
            img: icons.dark_map
        },
        {
            label: "satellite",
            id: "satellite",
            value: 3,
            active: false,
            img: icons.satellite_map
        },
        {
            label: "hybrid",
            id: "hybrid",
            value: 4,
            active: false,
            img: icons.hybrid_map
        },
        {
            label: "terrain",
            id: "terrain",
            value: 5,
            active: false,
            img: icons.terrain_map
        }
    ]

const MapTogglesContext = createContext({
    mapTypes: initialMap,
    mapToggles: initialState,
    toggleMode: () => {},
    setActiveDevice: () => {},
    setActiveEvent: () => {},
    setActiveGeo: () => {},
    setCreatingGeo: () => {},
    setContextGeoMethod: () => {},
    setGeofenceDeleted: () => {},
    setGeofenceCreated: () => {},
    setHistoryData: () => {},
    setRoutesData: () => {},
    setGroupArray: () => {},
    setRoutePoint: () => {},
    setGeoData: () => {}
});

export const useMapToggles = () => useContext(MapTogglesContext);

export const MapTogglesProvider = ({ children }) => {
    const [storage, setStorage] = useLocalStorage("mapsettings")
    const [mapTypes, setMapTypes] = useState(initialMap);
    const [mapToggles, setMapToggles] = useState(initialState);
    const [mapType, setMapType] = useState(storage ? storage.maptype : "light")
   
    const [activeDevice, setActiveDevice] = useState(null)
    const [activeEvent, setActiveEvent] = useState(null)

    const [activeGeo, setActiveGeo] = useState(null)
    const [creatingGeo, setCreatingGeo] = useState(false)
    const [contextGeoMethod, setContextGeoMethod] = useState("poly")
    const [geoData, setGeoData] = useState()
    const [contextSaveGeofence, setContextSaveGeofence] = useState(false)
    const [initialGeoData, setInitialGeoData] = useState()
    const [geofenceDeleted, setGeofenceDeleted] = useState(false)
    const [geofenceCreated, setGeofenceCreated] = useState(false)
    const [historyData, setHistoryData] = useState()
    const [routesData, setRoutesData] = useState()
    const [routePoint, setRoutePoint] = useState(-1)

    const [groupArray, setGroupArray] = useState([])
    const updateGeoData = (newPath) => {
        setGeoData(newPath)
    }
    useEffect(() => {
        if (storage) {
            // Iterate through the stored map settings and update mapToggles accordingly
            setMapToggles(prevMapToggles => prevMapToggles.map(toggle => ({
                ...toggle,
                active: storage[toggle.label] || false // Set to stored value if exists, otherwise false
            })));
        }
    }, [storage]);

    const toggleMap = (selected) => {
        setMapTypes(mapTypes.map(type => ({
            ...type,
            active: type.id === selected, // Only the matching type becomes active
        })));

        setStorage((prev) => ({
            ...prev,
            maptype: selected ? selected : "light",
        }))
    }

    const toggleMode = (selectedLabel, activate = false) => { 
        //OLD
        // setMapToggles(mapToggles.map(toggle => {
        //     // Check if the current toggle matches the selectedLabel
        //     if (toggle.label === selectedLabel) {
        //         const updatedToggle = {
        //             ...toggle,
        //             active: activate
        //         };
        //         setStorage((prev) => ({
        //             ...prev,
        //             [updatedToggle.label]: updatedToggle.active
        //         }))
        //         return updatedToggle;
        //     }
        //     // For toggles that do not match the selectedLabel, return them as is
        //     return toggle;
        // }));

        //NEW
        setStorage((prev) => ({
            ...prev,
            [selectedLabel]: activate
        }));
    };

    return (
        <MapTogglesContext.Provider value={{ 
            mapToggles, 
            toggleMode,
            mapTypes,
            toggleMap,
            mapType,
            activeDevice, 
            setActiveDevice, 
            activeEvent, 
            setActiveEvent, 
            activeGeo, 
            setActiveGeo, 
            creatingGeo, 
            setCreatingGeo,
            contextGeoMethod,
            setContextGeoMethod,
            geoData,
            setGeoData,
            updateGeoData,
            contextSaveGeofence,
            setContextSaveGeofence,
            initialGeoData,
            setInitialGeoData,
            geofenceDeleted,
            setGeofenceDeleted,
            geofenceCreated,
            setGeofenceCreated,
            historyData,
            setHistoryData,
            routesData,
            setRoutesData,
            groupArray,
            setGroupArray,
            routePoint,
            setRoutePoint
        }}>
            {children}
        </MapTogglesContext.Provider>
    );
};