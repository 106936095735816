import { useState, useEffect } from 'react';

export function useIsMobile() {
	const [isMobile, setIsMobile] = useState(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 786);
  
	useEffect(() => {
	  const handleResize = () => {
		setIsMobile(window.innerWidth < 786);
	  };
  
	  window.addEventListener('resize', handleResize);
  
	  // Cleanup function to remove the event listener when the component unmounts
	  return () => {
		window.removeEventListener('resize', handleResize);
	  };
	}, []); // Empty dependency array ensures that this effect runs only once, similar to componentDidMount
  
	return isMobile;
}

export function useLocalStorage(key) {
    const [value, setValue] = useState(() => {
      try {
        const storedValue = window.localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : undefined;
      } catch (error) {
        console.error(error);
        return undefined;
      }
    });
  
    useEffect(() => {
      try {
        // Check if value is valid JSON before storing
        if (value !== undefined && value !== null) {
          window.localStorage.setItem(key, JSON.stringify(value));
        } else {
          // If value is undefined or null, remove the item from localStorage
          window.localStorage.removeItem(key);
        }
      } catch (error) {
        console.error(error);
      }
    }, [key, value]);
  
    return [value, setValue];
  }