import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons } from "../../helpers/constants";



const DeviceActivationList = (props) => {

    return ( 
        <div className={`d-flex flex-column ${props.index % 2 === 0 ? "bg-white" : "bg-light-grey"}`}>
            <div className="d-flex align-items-center justify-content-between w-100 py-2 px-3" onClick={() => props.onClick()}>
                <div className="d-flex flex-column py-1">
                    <p className="m-0 bold primary">{props.text}</p>
                    <p className={`m-0 dark_grey ${props.subtitle ? "" : "d-none"}`}>{props.subtitle}</p>
                </div>
                <div className={`${props.selected ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1`}>
                    {props.selected && <img className="white-icon" src={icons.check} alt={""} />}
                </div>
            </div>
            <div className="grey_line"/>
        </div>
    )
}

export default DeviceActivationList;