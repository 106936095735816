import React, { useState, useEffect } from 'react';
import Button from '../Buttons/Button';
import { icons, STRIPE_TEST_KEY } from '../../helpers/constants';
import { useTranslation } from "react-i18next";
import { useIsMobile } from '../../helpers/customHooks';
import DeviceActivationList from '../Devices/DeviceActivationList';
import MonthYearSegment from '../PeriodSegment/PeriodSegement';
import { Collapse } from '@mui/material';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCard from '../Subscription/Stripe/AddCard';
import CardRow from './Stripe/CardRow';
import * as request from "../../services/FetchService";

const stripePromise = loadStripe(STRIPE_TEST_KEY);


const Modify = (props) => {
    const [devices, setDevices] = useState(props.devices)
    const { t } = useTranslation();
    const MOBILE = useIsMobile();
    const [step, setStep] = useState(1)
	const [period, setPeriod] = useState(false)
	const [selectedPlan, setSelectedPlan] = useState()
	const pricing = require(`./Stripe/Pricing/${process.env.REACT_APP_ASSETS}/pricelist.json`);
	const [country, setCountry] = useState(props.cards && props.cards.length > 0 ? props.cards[0].card.country : "SE")
	const [ssf, setSsf] = useState(false)
    const [selectedDevice, setSelectedDevice] = useState()
	const [showModal, setShowModal] = useState(false)
	const [type, setType] = useState()
	const [paymentType, setPaymentType] = useState()
	const [cards, setCards] = useState(props.cards)
	const [dots, setDots] = useState('')
	const [thinking, setThinking] = useState(false)

	const updatePlan = (data) => {
		request.postSubscription("", "payments/dataplan/modify", data).then((response) => {
			if(response.data.successful){
				setThinking(false)
				nextStep()
			}else{
				setThinking(false)
				nextStep(true)
			}
		})
	}

	const resetData = () => {

		setPaymentType("")
		setStep(1)
		setSsf(false)
		setSelectedPlan("")
		setPeriod(false)
		let updatedDevices = devices? [...devices] : [];
		updatedDevices.forEach((device, index) => {
			device.selected = false;
		});
		setDevices(updatedDevices);
	
        props.finalize()
		//setActivating(false)
	}

	const segments = [
		{
			label: t("subscription.monthly"),
			value: true
		},
		{
			label: t("subscription.yearly"),
			value: false
		}
	]

	const openModal = (variant) => {
		setShowModal(!showModal)
		setType(variant)
	}
    const nextStep = (fail) => {
		if(fail){
			setStep(6)
		}else{
			setStep(step + 1)
		}
		// setSelectedPlan(plan)
	}

    const getCheckedDevices = () => {
		let checkedDevices = devices?.filter(device => device.selected);
		return checkedDevices?.length
	}

	const getCheckedImeis = () => {
		let checkedDevices = devices?.filter(device => device.selected).map(device => device.uniqueid);
		return checkedDevices
	}

	useEffect(() => {
		if(step === 1 && selectedDevice){
			setSelectedPlan(selectedDevice.plan)
			setPeriod(selectedDevice.period === "year" ? false : true)
		}
		if(step === 4){
			setThinking(true)
			let data = {
				imei: selectedDevice.uniqueid,
				period: period ? "month" : "year",
				priceplan: selectedPlan,
				upgrade: true,
				addon: ssf
			}
			updatePlan(data)
		}
	}, [step])


    const calculatePrice = () => {
		let variant = period ? "monthly" : "yearly"
		let length = getCheckedDevices()
		let combined = selectedPlan ? period ? pricing[country][selectedPlan][variant] : (pricing[country][selectedPlan][variant] * 12 + (ssf ? 120 : 0)) : 0
		let result = combined * length
		return result
	}

	const calculateDifference = () => {
		let length = getCheckedDevices()
		let actual = selectedPlan ? (pricing[country][selectedPlan]["yearly"] * 12) * length : 0
		let expensive = selectedPlan ? (pricing[country][selectedPlan]["monthly"] * 12) * length : 0
		let result = expensive - actual
		return result	
	}

	const selectDevice = (index) => {
		// ONLY ONE
		let updatedDevices = [...devices];
		if (index !== -1) {
			// Determine if the current device is already selected
			const isAlreadySelected = updatedDevices[index].selected;
            if(!isAlreadySelected){
                setSelectedDevice(updatedDevices[index])
				setSelectedPlan(updatedDevices[index].plan)
				setPeriod(updatedDevices[index].period === "year" ? false : true)
            }else{
                setSelectedDevice(null)
				setSelectedPlan()
            }
			// Set all devices' selected property to false initially
			updatedDevices.forEach(device => {
				device.selected = false;
			});
			// Toggle the selected state of the chosen device
			// If it was already selected, it becomes false, else true
			updatedDevices[index].selected = !isAlreadySelected;
		}
	
		// Update the state with the new list of devices
		setDevices(updatedDevices);
    }

    const stepCounter = () => (
		<div className="d-flex flex-row align-items-center justify-content-center mt-3">
			<div className="d-flex flex-column align-items-center" onClick={() => setStep(1)}>
				<div className={`activate-step-counter bold ${step === 1 ? "current": step > 1 ? "done" : "shadow-inner dark_grey"}`}><p>1</p></div>
				<p className={`bold mt-2 ${step === 1 ? "current": step > 1 ? "primary" : "dark_grey"}`}>{t("subscription.select_device")}</p>
			</div>
			<div className="d-flex flex-column align-items-center mx-2">
				<div className={`activate-step-divider mb-4 bold ${step > 1 ? "done" : ""}`} />
			</div>
			<div className="d-flex flex-column align-items-center">
				<div className={`activate-step-counter bold ${step === 2 ? "current": step > 2 ? "done" : "shadow-inner dark_grey"}`}><p>2</p></div>
				<p className={`bold mt-2 ${step === 2 ? "current": step > 2 ? "primary" : "dark_grey"}`}>{t("subscription.modify_details")}</p>
			</div>
			<div className="d-flex flex-column align-items-center mx-2">
				<div className={`activate-step-divider mb-4 bold ${step > 2 ? "done" : ""}`} />
			</div>
			<div className="d-flex flex-column align-items-center">
				<div className={`activate-step-counter bold ${step === 3 ? "current": step > 3 ? "done" : "shadow-inner dark_grey"}`}><p>3</p></div>
				<p className={`bold mt-2 ${step === 3 ? "current": step > 3 ? "primary" : "dark_grey"}`}>{t("subscription.confirm")}</p>
			</div>
		</div>
	)

    const renderStep1 = () => {

		const purchaseSummaryMobile = () => {
			return(
				<div className="position-absolute bottom-0 start-0 end-0 px-3 pb-3">
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<div className="d-flex flex-row justify-content-between">
							<div className="d-flex flex-column">
								<p className="dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
								<div className="d-flex flex-column">
									<div className="d-flex">
										<h1>{calculatePrice()}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-end">
								<Button onClick={() => nextStep()} btnClass="btn-primary gradient-button-primary">{t("subscription.proceed")}</Button>
							</div>
						</div>
					</div>
				</div>
			)
		}

		const purchaseSummaryDesktop = () => {
			return(
				<div className="ms-4">
					<div className="d-flex flex-row align-items-center px-3 opacity-0 mb-2">
						<h4>""</h4>
						<div className="centered_line ms-2" />
					</div>
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<p className="align-self-center mb-3 bold dark_grey">{t("subscription.current_summary")}</p>

                        <p className="mt-4 dark_grey">{t("subscription.device")}</p>
						<h4 className="mt-2">{selectedDevice ? selectedDevice?.name : "--"}</h4>
						<hr/>
						<p className="mt-2 dark_grey">{t("subscription.subscription")}</p>
						<h4 className="mt-2">{selectedPlan}</h4>
						<hr/>
						<p className="mt-2 dark_grey">{t("subscription.billing_period")}</p>
						<h4 className="mt-2">{selectedDevice && selectedDevice?.period}</h4>
						<hr/>


						<p className="mt-2 dark_grey">{t("subscription.addons")}</p>
						<h4 className="mt-2">{ssf ? "SSF" : t("subscription.none")}</h4>
						<hr/>

						{/* <p className="mt-2 dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
						<div className="d-flex flex-column mb-4">
							<div className="d-flex">
								<h1>{calculatePrice()}</h1>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
							</div>
							{console.log(getCheckedDevices())}
							<p className={`${period || getCheckedDevices() < 1 ? "d-none" : "dark_grey"}`}>{t("subscription.total_savings")}:</p>
							<div className={`${period || getCheckedDevices() < 1 ? "d-none" : "green d-flex"}`}>
								<h2>{calculateDifference()}</h2>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
							</div>
						</div> */}

						<Button onClick={() => nextStep()} btnClass={`btn-primary gradient-button-primary w-100 mt-auto ${getCheckedDevices() < 1 ? "disabled": ""}`}>{t("subscription.proceed_to_modify")}</Button>
					</div>
				</div>
			)
		}
		if(MOBILE){
			return (
				<>
					<div className="d-flex flex-column my-3">
						<div className="d-flex flex-row align-items-center px-3 mb-2">
							<h4 className="text-nowrap">{t("subscription.select_devices")}</h4>
							<div className="centered_line ms-3" />
						</div>
							<div className="border-12 subscription_device_list scrollable-y shadow-medium">
								{devices.map((device, index) => (
									<DeviceActivationList
										key={index}
										text={device.name}
										subtitle={device.uniqueid}
										index={index}
										selected={device.selected ? true : false}
										onClick={() => selectDevice(index)}
									/>
								))}
							</div>
					</div>
					{devices.some(device => device.selected) &&
						purchaseSummaryMobile()
					}
				</>
			)
		}else{
			return(
				<>
					<div className="d-flex flex-row justify-content-center mt-4">
						<div className="d-flex flex-column w-75" style={{maxWidth: "500px"}}>
							<div className="d-flex flex-row align-items-center px-3 mb-2">
								<h4 className="text-nowrap">{t("subscription.select_device")}</h4>
								<div className="centered_line ms-3" />
							</div>
							<div className="border-12 subscription_device_list scrollable-y shadow-medium">
                                {/* <DeviceActivationList 
									text={t("devices.choose_all")}
									selected={deactivatableDevices.some(device => !device.selected) ? false : true}
									onClick={() => setAllSelected(!allSelected)}
								/> */}
								{devices?.map((device, index) => (
									<DeviceActivationList
										key={index}
										text={device.name}
										subtitle={device.uniqueid}
										index={index}
										selected={device.selected ? true : false}
										onClick={() => selectDevice(index)}
									/>
								))}
							</div>
						</div>
	
						<div className="w-50" style={{maxWidth: "350px"}}>
							{purchaseSummaryDesktop()}
						</div>
					</div>
				</>
			)
		}	
	}

	const renderStep2 = () => {

		const purchaseSummaryMobile = () => {
			return(
				<div className="position-absolute bottom-0 start-0 end-0 px-3 pb-3">
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<div className="d-flex flex-row justify-content-between">
							<div className="d-flex flex-column">
								<p className="dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
								<div className="d-flex flex-column">
									<div className="d-flex">
										<h1>{calculatePrice()}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-end">
								<Button onClick={() => nextStep()} btnClass="btn-primary gradient-button-primary">{t("subscription.proceed")}</Button>
							</div>
						</div>
					</div>
				</div>
			)
		}

		const purchaseSummaryDesktop = () => {
			return(
				<div className="ms-4">
					<div className="d-flex flex-row align-items-center px-3 opacity-0 mb-2">
						<h4>""</h4>
						<div className="centered_line ms-2" />
					</div>
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<p className="align-self-center mb-3 bold dark_grey">{t("subscription.current_summary")}</p>
						<MonthYearSegment 
							segments={segments} 
							period={period} 
							callback={(val) => setPeriod(val)}
							segmentWidth={"w-100"}
						/>
                        <p className="mt-4 dark_grey">{t("subscription.device")}</p>
						<h4 className="mt-2">{selectedDevice ? selectedDevice?.name : "--"}</h4>
						<hr/>
						<p className="mt-2 dark_grey">{t("subscription.subscription")}</p>
						<h4 className="mt-2">{selectedPlan}</h4>
						<hr/>
						<p className="mt-2 dark_grey">{t("subscription.subscription")}</p>
						<h4 className="mt-2">{period ? t("subscription.monthly") : t("subscription.yearly")}</h4>
						<hr/>

						<p className="mt-2 dark_grey">{t("subscription.addons")}</p>
						<h4 className="mt-2">{ssf ? "SSF" : t("subscription.none")}</h4>
						<hr/>

						<p className="mt-2 dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
						<div className="d-flex flex-column mb-4">
							<div className="d-flex">
								<h1>{calculatePrice()}</h1>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
							</div>
							{console.log(getCheckedDevices())}
							<p className={`${period || getCheckedDevices() < 1 ? "d-none" : "dark_grey"}`}>{t("subscription.total_savings")}:</p>
							<div className={`${period || getCheckedDevices() < 1 ? "d-none" : "green d-flex"}`}>
								<h2>{calculateDifference()}</h2>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
							</div>
						</div>

						<Button onClick={() => nextStep()} btnClass={`btn-primary gradient-button-primary w-100 mt-auto ${getCheckedDevices() < 1 ? "disabled": ""}`}>{t("subscription.proceed_to_modify")}</Button>
					</div>
				</div>
			)
		}
		if(MOBILE){
			return (
				<>
					<div className="d-flex flex-column my-3">
						<div className="d-flex flex-row align-items-center px-3 mb-2">
							<h4 className="text-nowrap">{t("subscription.select_devices")}</h4>
							<div className="centered_line ms-3" />
						</div>
							<div className="border-12 subscription_device_list scrollable-y shadow-medium">
								{devices.map((device, index) => (
									<DeviceActivationList
										key={index}
										text={device.name}
										subtitle={device.uniqueid}
										index={index}
										selected={device.selected ? true : false}
										onClick={() => selectDevice(index)}
									/>
								))}
							</div>
					</div>
					{devices.some(device => device.selected) &&
						purchaseSummaryMobile()
					}
				</>
			)
		}else{
			return(
				<>
					<div className="d-flex flex-row justify-content-center mt-4">
						<div className="d-flex flex-column w-75" style={{maxWidth: "500px"}}>
							<div className="d-flex flex-row align-items-center px-3 mb-2">
								<h4 className="text-nowrap">{t("subscription.select_dataplan")}</h4>
								<div className="centered_line ms-3" />
							</div>
							{/* BASIC CARD*/}
							<div className="card p-3 d-flex flex-row justify-content-between border-20 border-0 shadow-small align-items-center">
								<h2 onClick={() => openModal("basic")}>{t("subscription.basic")}<sup><img className="size_15" src={icons.info_icon} alt=""></img></sup></h2>
								<div className="d-flex flex-row">
									<div className={`d-flex flex-column align-items-end me-3 ${period ? "primary" : "dark_grey"}`}>
										<div className="d-flex">
											<h1>{period ? pricing[country].basic.monthly : pricing[country].basic.yearly}</h1>
											{'\u00A0'}
											<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
										</div>
										<p>{t("subscription.monthly")}</p>
									</div>
									<div className={`d-flex flex-column align-items-end ${!period ? "primary" : "dark_grey"}`}>
										<div className="d-flex">
											<h1>{period ? (pricing[country].basic.monthly * 12)  : (pricing[country].basic.yearly * 12)}</h1>
											{'\u00A0'}
											<h4 className="align-self-end mb-1"> {pricing[country].currency}</h4>
										</div>
										<p>{t("subscription.yearly")}</p>
									</div>
									<div className="d-flex flex-column justify-content-center ms-3">
										<Button onClick={() => setSelectedPlan("basic")} btnClass={`${selectedPlan === "basic" ? "btn-primary w-100 gradient-button-primary" : "btn-secondary"}`}>{selectedPlan === "basic" ? t("subscription.selected") : t("subscription.choose")}</Button>
									</div>
								</div>
							</div>
						{/* BOOST CARD */}
							<div className="card p-3 d-flex flex-row justify-content-between border-20 border-0 shadow-small align-items-center my-3">
								<h2 onClick={() => openModal("boost")}>{t("subscription.boost")}<sup className="secondary position-absolute top-0 start-0 mb-5"><h4>{t("subscription.best_value")}</h4></sup><sup><img className="size_15" src={icons.info_icon} alt=""></img></sup></h2>
								<div className="d-flex">
									<div className={`d-flex flex-column align-items-end me-3 ${period ? "primary" : "dark_grey"}`}>
										<div className="d-flex">
											<h1>{period ? pricing[country].boost.monthly : pricing[country].boost.yearly}</h1>
											{'\u00A0'}
											<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
										</div>
										<p>{t("subscription.monthly")}</p>
									</div>
									<div className={`d-flex flex-column align-items-end ${!period ? "primary" : "dark_grey"}`}>
										<div className="d-flex">
											<h1>{period ? (pricing[country].boost.monthly * 12)  : (pricing[country].boost.yearly * 12)}</h1>
											{'\u00A0'}
											<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
										</div>
										<p>{t("subscription.yearly")}</p>
									</div>
									<div className="d-flex flex-column justify-content-center ms-3">
										{/* {stats("boost")} */}
										<Button onClick={() => setSelectedPlan("boost")} btnClass={`${selectedPlan === "boost" ? "btn-primary w-100 gradient-button-primary" : "btn-secondary"}`}>{selectedPlan === "boost" ? t("subscription.selected") : t("subscription.choose")}</Button>
									</div>
								</div>
								
							</div>
						{/* PREMIUM CARD */}
							<div className="card p-3 d-flex flex-row justify-content-between border-20 border-0 shadow-small align-items-center mb-3">
								<h2 onClick={() => openModal("premium")}>{t("subscription.premium")}<sup><img className="size_15" src={icons.info_icon} alt=""></img></sup></h2>
								<div className="d-flex">
									<div className={`d-flex flex-column align-items-end me-3 ${period ? "primary" : "dark_grey"}`}>
										<div className="d-flex">
											<h1>{period ? pricing[country].premium.monthly : pricing[country].premium.yearly}</h1> 
											{'\u00A0'}
											<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
										</div>
										<p>{t("subscription.monthly")}</p>
									</div>
									<div className={`d-flex flex-column align-items-end ${!period ? "primary" : "dark_grey"}`}>
										<div className="d-flex">
											<h1>{period ? (pricing[country].premium.monthly * 12)  : (pricing[country].premium.yearly * 12)}</h1>
											{'\u00A0'}
											<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
										</div>
										<p>{t("subscription.yearly")}</p>
									</div>
									<div className="d-flex flex-column justify-content-center ms-3">
										{/* {stats("premium")} */}
										<Button onClick={() => setSelectedPlan("premium")} btnClass={`${selectedPlan === "premium" ? "btn-primary w-100 gradient-button-primary" : "btn-secondary"}`}>{selectedPlan === "premium" ? t("subscription.selected") : t("subscription.choose")}</Button>
									</div>
								</div>
								
							</div>
							{!period 
								?
								<>
								<div className="card p-4 shadow-medium border-12 d-flex flex-column border-0 ">
									<div className="d-flex flex-row justify-content-between align-items-start w-100">
										<div className="d-flex align-items-center">
											<img className="me-2" src={icons.ssf} alt=""/>
											<h3>{t("subscription.ssf_support")}</h3>
										</div>
										<div className="d-flex justify-content-end ms-2">
											<h2>120</h2>
											{'\u00A0'}
											<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
											<div className="d-flex flex-column ps-2">
												<p className="text-wrap ">{t("subscription.per_device")}</p>
												<p className="text-wrap ">{t("subscription.yearly")}</p>
											{/* <p className="text-wrap ">{t("subscription.ssf_price_desc")}</p>
											<p className="text-wrap ">{t("subscription.ssf_price_desc")}</p> */}
											</div>
										</div>
									</div>
									<p className="dark_grey py-3">{t("subscription.ssf_subtitle")}</p>
									<Button onClick={() => setSsf(!ssf)} btnClass="btn-primary float-end align-self-end">{ssf ? t("subscription.remove") : t("subscription.add")}</Button>
								</div>
								</>
								: <h3 className="align-self-center dark_grey mt-3">{t("subscription.no_available")}</h3>
							}
						</div>

						<div className="w-50" style={{maxWidth: "350px"}}>
							{purchaseSummaryDesktop()}
						</div>
					</div>
				</>
			)
		}	
	}


	const renderStep3 = () => {

		const purchaseSummaryMobile = () => {
			return(
				<div className="position-absolute bottom-0 start-0 end-0 px-3 pb-3">
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<div className="d-flex flex-row justify-content-between">
							<div className="d-flex flex-column">
								<p className="dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
								<div className="d-flex flex-column">
									<div className="d-flex">
										<h1>{calculatePrice()}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-end">
								<Button onClick={() => nextStep()} btnClass="btn-primary gradient-button-primary">{t("subscription.proceed")}</Button>
							</div>
						</div>
					</div>
				</div>
			)
		}

		const purchaseSummaryDesktop = () => {
			return(
				<div className="ms-4">
					<div className="d-flex flex-row align-items-center px-3 opacity-0 mb-2">
						<h4>""</h4>
						<div className="centered_line ms-2" />
					</div>
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<p className="align-self-center mb-3 bold dark_grey">{t("subscription.current_summary")}</p>
						<MonthYearSegment 
							segments={segments} 
							period={period} 
							callback={(val) => setPeriod(val)}
							segmentWidth={"w-100"}
						/>
                        <p className="mt-4 dark_grey">{t("subscription.device")}</p>
						<h4 className="mt-2">{selectedDevice ? selectedDevice?.name : "--"}</h4>
						<hr/>
						<p className="mt-2 dark_grey">{t("subscription.subscription")}</p>
							<div className="d-flex mt-2">
								<div className="d-flex flex-column me-5">
									<p className="dark_grey uppercase bold">{t("subscription.old")}</p>
									<h4 className="mt-2">{selectedDevice?.plan}</h4>
								</div>
								<div className="d-flex flex-column ms-5">
									<p className="dark_grey uppercase bold">{t("subscription.new")}</p>
									<h4 className="mt-2">{selectedPlan}</h4>
								</div>
							</div>
						<hr/>
						<p className="mt-2 dark_grey">{t("subscription.billing_period")}</p>
							<div className="d-flex mt-2">
								<div className="d-flex flex-column me-5">
									<p className="dark_grey uppercase bold">{t("subscription.old")}</p>
									<h4 className="mt-2">{selectedDevice?.period}</h4>
								</div>
								<div className="d-flex flex-column ms-5">
									<p className="dark_grey uppercase bold">{t("subscription.new")}</p>
									<h4 className="mt-2">{period ? t("subscription.monthly") : t("subscription.yearly")}</h4>
								</div>
							</div>
						<hr/>

						<p className="mt-2 dark_grey">{t("subscription.addons")}</p>
							<div className="d-flex mt-2">
								<div className="d-flex flex-column me-5">
									<p className="dark_grey uppercase bold">{t("subscription.old")}</p>
									<h4 className="mt-2">{ssf ? "SSF" : t("subscription.none")}</h4>
								</div>
								<div className="d-flex flex-column ms-5">
									<p className="dark_grey uppercase bold">{t("subscription.new")}</p>
									<h4 className="mt-2">{ssf ? "SSF" : t("subscription.none")}</h4>
								</div>
							</div>
						<hr/>
						<p className="mt-2 dark_grey">{t("subscription.payment_method")}</p>
						<h4 className="mt-2">{paymentType === "card" ? "Card" : paymentType === "invoice" ? "Invoice" : "--"}</h4>
						<hr/>


						<p className="mt-2 dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
						<div className="d-flex flex-column mb-4">
							<div className="d-flex">
								<h1>{calculatePrice()}</h1>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
							</div>
							{console.log(getCheckedDevices())}
							<p className={`${period || getCheckedDevices() < 1 ? "d-none" : "dark_grey"}`}>{t("subscription.total_savings")}:</p>
							<div className={`${period || getCheckedDevices() < 1 ? "d-none" : "green d-flex"}`}>
								<h2>{calculateDifference()}</h2>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country].currency}</h4>
							</div>
						</div>

						<Button onClick={() => nextStep()} btnClass={`btn-primary gradient-button-primary w-100 mt-auto ${getCheckedDevices() < 1 ? "disabled": ""}`}>{t("subscription.proceed_to_modify")}</Button>
					</div>
				</div>
			)
		}
		if(MOBILE){
			return (
				<>
					<div className="d-flex flex-column my-3">
						<div className="d-flex flex-row align-items-center px-3 mb-2">
							<h4 className="text-nowrap">{t("subscription.select_devices")}</h4>
							<div className="centered_line ms-3" />
						</div>
							<div className="border-12 subscription_device_list scrollable-y shadow-medium">
								{devices.map((device, index) => (
									<DeviceActivationList
										key={index}
										text={device.name}
										subtitle={device.uniqueid}
										index={index}
										selected={device.selected ? true : false}
										onClick={() => selectDevice(index)}
									/>
								))}
							</div>
					</div>
					{devices.some(device => device.selected) &&
						purchaseSummaryMobile()
					}
				</>
			)
		}else{
			return(
				<>
					<div className="d-flex flex-row justify-content-center mt-4">
						<div className="d-flex flex-column w-75" style={{maxWidth: "500px"}}>
						<div className="d-flex flex-row align-items-center px-3 mb-2">
								<h4 className="text-nowrap">{t("subscription.payment_method")}</h4>
								<div className="centered_line ms-3" />
							</div>
							<div className="card border-0 p-4 w-100 shadow-medium border-12">
								<div className="d-flex flex-row justify-content-between align-items-center">
									<h4>{t("subscription.card_payment")}</h4>
									<img onClick={() => setPaymentType(paymentType === "card" ? "" : "card")} src={paymentType === "card" ? icons.radio_checked : icons.radio_unchecked} alt=""/>
								</div>
								<Collapse in={paymentType === "card"}>
									<div className="d-flex flex-column mt-4">
										
										<p className="bold mb-1">{t("subscription.add_card")}</p>
										<Elements stripe={stripePromise}>
											<AddCard />
										</Elements>
										<div className="centered_line my-4" />

										<div className="d-flex flex-row align-items-center mb-2">
											<p className="bold">{t("subscription.payment_method")}</p>
										</div>
									
										{cards.map((card, index) => (
											<CardRow key={index} data={card}/>
										))}
									</div>
								</Collapse>
							</div>
							<div className="card border-0 p-4 w-100 mt-4 shadow-medium border-12">
								<div className="d-flex flex-row justify-content-between align-items-center">
									<h4>{t("subscription.invoice")}</h4>
									<img onClick={() => setPaymentType(paymentType === "invoice" ? "" : "invoice")} src={paymentType === "invoice" ? icons.radio_checked : icons.radio_unchecked} alt=""/>
								</div>
								<Collapse in={paymentType === "invoice"}>
									Hejsan
								</Collapse>
							</div>
						</div>
	
						<div className="w-50" style={{maxWidth: "350px"}}>
							{purchaseSummaryDesktop()}
						</div>
					</div>
				</>
			)
		}	
	}

	useEffect(() => {
		let intervalId;
		if(thinking){
			let dotCount = 0
			intervalId = setInterval(() => {
				dotCount = (dotCount % 3) + 1; // Loop through 1, 2, 3 dots
				setDots('.'.repeat(dotCount))
			}, 500)
		}

		return () => {
			clearInterval(intervalId); // Clean up interval
		  };
	}, [thinking])

	const renderStep4 = () => {
		return(
			<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
				<div className="card p-4 shadow-small border-12 d-flex">
					<h3 className="align-self-center mt-5">{t("subscription.processing")+dots}</h3>
				</div>
			</div>
		)
	}

	const renderStep5 = () => {
		return(
			<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
				<div className="card p-4 w-100 shadow-small border-12 d-flex flex-column align-items-center">
					<h3 className="mt-5">{t("subscription.purchase_successful")}</h3>
					<p>{t("subscription.purchase_successful_body")}</p>
					<hr className="w-100"/>
					<div className="d-flex flex-row justify-content-between flex-fill w-100">
						<div className="d-flex flex-column">
							<p>{t("subscription.dataplan")}</p>
							<p>{selectedPlan}</p>
						</div>
						<div className="d-flex flex-column">
							<p>{t("subscription.devices")}</p>
							<p>{getCheckedDevices() + " " + t("devices.device", {count : getCheckedDevices()})}</p>
						</div>
						<div className="d-flex flex-column">
							<p>{t("subscription.addons")}</p>
							<p>{ssf ? "SSF" : t("subscription.none")}</p>
						</div>
						<div className="d-flex flex-column">
							<p>{t("subscription.payment_method")}</p>
							{/* <label>Card **** **** **** {cards[0].card.last4} {capitalizeFirstLetter(cards[0].card.brand)}</label> */}
						</div>
					</div>
					<hr className="w-100"/>
					<div className="d-flex flex-column align-items-center">
						<p>{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
						<p>{calculatePrice()}</p>
						<Button  onClick={() => resetData()} btnClass="btn-primary gradient-button-primary mt-4">{t("subscription.done")}</Button>
					</div>
				</div>
			</div>
		)
	}

	const renderError = () => (
		<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
			<div className="card p-4 shadow-small border-12 d-flex">
				<h3 className="align-self-center mt-5">{t("subscription.something_went_wrong")}</h3>
				<Button  onClick={() => resetData()} btnClass="btn-primary gradient-button-primary mt-4">{t("subscription.go_back")}</Button>
			</div>
		</div>
	)
    
    return(
        <>
            <Button className="align-self-start mt-3" btnClass={`btn-left-section ${step > 5 ? "d-none" : ""}`} icon={icons.back} notext={true} onClick={() => step > 1 ? setStep(step - 1) : resetData()}/>
            {stepCounter()}
            {	step === 1
                ? renderStep1()
                : step === 2
				? renderStep2()
				: step === 3
				? renderStep3()
				: step === 4
				? renderStep4()
				: step === 5
				? renderStep5()
				: step === 6
				? renderError()
				: null
            }
        </>
    )
}

export default Modify;