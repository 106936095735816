// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import * as request from "../services/FetchService";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBwkFHiHGBmMA6Xcydd3ibnDzUcchf3dG0",
  authDomain: "app-v5-345909.firebaseapp.com",
  projectId: "app-v5-345909",
  storageBucket: "app-v5-345909.appspot.com",
  messagingSenderId: "889052267649",
  appId: "1:889052267649:web:b325399bef83f641d6f980",
  measurementId: "G-M5M3ENNWEG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics if the window object is available
let analytics;
if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
}

// Check if the browser supports Notifications, Service Workers, and Push API before initializing Firebase Messaging
let messaging;
if ('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window) {
  console.log('Browser supports Firebase Messaging');
  try {
    messaging = getMessaging(app);
  } catch (error) {
    console.error('Error initializing Firebase Messaging:', error);
  }
} else {
  console.log('Browser does not support Firebase Messaging.');
}

// Function to request and send FCM Token to the backend
export const requestForToken = async () => {
  try {
    // Only proceed if Firebase Messaging is initialized
    if (!messaging) {
      console.log('Firebase Messaging is not supported on this browser.');
      return;
    }

    // Register the service worker to handle background notifications
    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
    console.log('Service Worker registered with scope:', registration.scope);

    // Retrieve the current FCM token, linking it with the service worker registration
    const currentToken = await getToken(messaging, {
      vapidKey: "BPsqR-dKZMiIGN8YJcH6ZcadFFqBH6f1Hx9nUPkV-s2-1KAbLGBWFNaoNtYYS3nRO9UGYMWTpAPFFqAwSdK8bO0",
      serviceWorkerRegistration: registration  // Ensure that the service worker is passed in here
    });

    if (currentToken) {
      console.log('FCM Token:', currentToken);

      // Prepare the data to send to your backend
      let data = {
        insert: true,
        phonetoken: currentToken,
        languages: localStorage.getItem("language") ?? "en",
        clientos: "web"
      };

      console.log("DATA to send to backend: ", data);

      // Send the token to your backend for saving
      const response = await request.post("", "notification/updatephonetoken", data);
      console.log("Response from backend:", response);

      return currentToken;
    } else {
      console.log('No FCM token retrieved. Request permission to generate one.');
    }
  } catch (err) {
    console.error('An error occurred while retrieving the token or registering the service worker:', err);
  }
};

// Function to listen for foreground messages
export const onMessageListener = (callback) => {
  try {
    if (!messaging) {
      console.log('Firebase Messaging is not supported, cannot listen for messages.');
      return;
    }

    // Use onMessage to continuously listen for incoming messages in the foreground
    onMessage(messaging, (payload) => {
      console.log('Foreground message received: ', payload);
      // Call the provided callback with the message payload
      if (callback) {
        callback(payload);
      }
    });
  } catch (error) {
    console.error('Error receiving foreground message: ', error);
  }
};

// Export the Firebase app for use in other parts of the app
export { app, analytics };