import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';

const ShowLocationsModal = (props) => {
    const MOBILE = useIsMobile();
    const [show, setShow] = useState(props.show)
    const { t } = useTranslation();

    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }
    useEffect(() => {
        setShow(props.show)
    },[props.show])

    const openExternal = () => {
        const url = "https://maps.google.com/?q=" + props.address;
        window.open(url, "_blank");
    }
    
    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                    <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                        <div className='d-flex align-items-center'>
                            <img src={icons.marker} alt=""/>
                            <h3 className="ms-2">{t("settings.open_link")}</h3>
                        </div>
                        <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
                    </div>
                    <div className="p-3">
                        <p className="thin dark-grey text-center pb-3">{t("settings.open_external")}</p>
                    </div>
                    <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
                        <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
                        <Button onClick={() => openExternal()} btnClass="btn-primary">{t("settings.open")}</Button>
                    </div>
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default ShowLocationsModal;