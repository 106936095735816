import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF, Circle } from '@react-google-maps/api';
import { GOOGLE_MAPS_KEY } from "../../helpers/constants";
import { lightMode } from './mapStyles';

import { getMapMarker } from '../../helpers/helperFunctions';
// import {darkMode, lightMode} from "./mapStyles.js"
const containerStyle = {
	height: '100%',
	borderRadius: '12px'
  };

  const center = {
	lat: 59.30190008066058,
	lng: 18.104984453399872
  };

const SafetyZoneMap = (props) => {
	const [map, setMap] = useState(null)

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: GOOGLE_MAPS_KEY
	})
	const options = {
		minZoom: 1,
		restriction: {
			latLngBounds: {
					east: 179.9999,
					north: 85,
					south: -85,
					west: -179.9999
				},
				strictBounds: true
		},
		// styles: mode==="dark"?darkMode:lightMode,
		clickableIcons: false,
		mapTypeId: "roadmap",
		mapTypeControl: false,
		streetViewControl: false,
		keyboardShortcuts: false,
		fullscreenControl: false,
		zoomControl: false,
		backgroundColor: "rgba(0,0,0,0)",
		styles: lightMode,
        gestureHandling: "none", // Disable gesture handling
        draggable: false, // Disable map dragging
        scrollwheel: false, // Disable scroll wheel zoom,
        tilt: 45,

	}


	  const onLoad = useCallback((map) => {
		setMap(map);
	  }, []);
	
	  const onUnmount = useCallback(() => {
		setMap(null);
	  }, []);

    useEffect(() => {
        if(map){
            map.setCenter({lat: props.device.latitude, lng: props.device.longitude})
            map.setZoom(11)
        }
    }, [props.device, map])


	return isLoaded ? (
		<div className="w-100 h-100">
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={11}
				onLoad={onLoad}
				onUnmount={onUnmount}
				options={options}
                tilt={20}
			>				
			<>
            <MarkerF
                position={{ lat: props.device.latitude, lng: props.device.longitude }}
                icon={{
                        url: getMapMarker(props.device.mapicon, true),
                        size: new window.google.maps.Size(45, 45), // Setting the size here
                        scaledSize: new window.google.maps.Size(45, 45), // Optional, for scaling the icon
                        anchor: new window.google.maps.Point(22.5, 40) // Set the anchor point to the center of the icon
                    }}
                />
                <Circle
                    options={{
                    fillColor: "#000000",
                    fillOpacity: "0.5",
                    strokeColor: "#000000",
                    strokeWeight: "2",
                    }}
                    center={{ lat: props.device.latitude, lng: props.device.longitude }}
                    radius={2000}
                />
            </>
			</GoogleMap>
			</div>
	) : <></>
}

export default SafetyZoneMap;
