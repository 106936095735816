import { Accordion, AccordionSummary, Collapse } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { INDEX_URL, GOOGLE_MAPS_KEY, icons } from "../../helpers/constants";
import { downloadPDF, fixDate } from "../../helpers/helperFunctions";
import { useTranslation } from "react-i18next";
import { useIsMobile } from '../../helpers/customHooks';

const ReceiptRow = (props) => {
    const { t } = useTranslation();
	const MOBILE = useIsMobile();


    return (
        <div className={`${MOBILE ? "py-3" : "py-2"} row m-0 p-0 primary ${props.index % 2 === 0 ? "bg-white" : "bg-light-grey"}`}>
                <p className="col-5 bold align-self-center">{fixDate(props.data.date, true)}</p>
                <p className="col-5 bold align-self-center">{props.data.type}</p>
                <div className="col-2 d-flex justify-content-center">
                    <img className="size_15 cursor-pointer" src={icons.download} alt="" onClick={() => downloadPDF(props.data.receiptlink, false)}/>
                </div>
        </div>
    )

}

export default ReceiptRow