import { Accordion, AccordionSummary, Collapse } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from '../../../helpers/helperFunctions';
import { icons } from '../../../helpers/constants';

const CardRow = (props) => {
    const { t } = useTranslation();
    const card = props.data.card
    const year_short = String(props.data.card.exp_year).slice(-2); // Extract the last two characters

    return (
        <div className="card d-flex flex-row p-3 justify-content-between align-items-center border-12 grey-bg">
            <h4 className="me-2">**** **** **** {card.last4} {card.exp_month}/{year_short} {capitalizeFirstLetter(card.brand)}</h4>
            <img src={icons.radio_checked} alt=""/>
        </div>
    )
}

export default CardRow;