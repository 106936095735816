import { useEffect } from 'react';
import axios from 'axios';
import { useContextData } from '../components/ContextData';
import { useNavigate } from 'react-router-dom';

const AxiosSetup = () => {
    const { accessToken } = useContextData();
    const navigate = useNavigate()
    useEffect(() => {
        if(!accessToken){
        //    navigate("/login")
            return
        }
        // Add a request interceptor to attach the access token to each request
        const interceptor = axios.interceptors.request.use(
            config => {
                // Add the access token to the request headers
                config.headers.Authorization = `${accessToken}`;
                return config;
            },
            error => {
                // Handle request errors
                return Promise.reject(error);
            }
        );

        // Clean up the interceptor when component unmounts
        return () => {
            axios.interceptors.request.eject(interceptor);
        };
    }, [accessToken]);

    return null; // This component doesn't render anything
};

export default AxiosSetup;