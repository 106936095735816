import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons, STRIPE_TEST_KEY } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCard from '../Subscription/Stripe/AddCard';
import CardRowEdit from '../Subscription/Stripe/CardRowEdit';


const stripePromise = loadStripe(STRIPE_TEST_KEY);

const FailedPaymentModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)

    useEffect(() => {
        setShow(props.show)
    },[props.show])

    useEffect(() => {
    }, [props.cards])

    
    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }

    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                    <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                        <div className='d-flex align-items-center'>
                            <img src={icons.sidebar.card_active} alt=""/>
                            <h3 className="ms-2">{t("subscription.retry_charge")}</h3>
                        </div>
                        <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
                    </div>
                    <div className="p-3">
                        <p className="thin dark-grey text-center pb-3">{t("subscription.failed_reason")}</p>
                        <hr/>
                        <p className="bold">{t("subscription.affected_device", {count : 2})}</p>
                        <hr/>
                        <p className="bold">{t("subscription.payment_summary")}</p> 
                        <Elements stripe={stripePromise}>
                            <AddCard />
                        </Elements>
                    </div>
                    <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
                        <Button onClick={() => alert("tjo_cabncel")} btnClass="btn-text me-2 text-danger underline">{t("subscription.cancel_dataplans")}</Button>
                        <Button onClick={() => alert("tjo")} btnClass="btn-primary">{t("settings.retry_charge")}</Button>
                    </div>
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default FailedPaymentModal;