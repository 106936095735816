import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const flavorLanguages = (flavor) => {
	switch (flavor) {
		case "swetrack":
			return ["cs", "da", "de", "el", "en", "es", "fi", "fr", "it", "nl", "no", "pl", "pt", "ru", "sv"];
		case "dignita":
			return ["en", "sv"];
		case "rawbike":
			return ["en", "sv"];
		case "helkama":
			return ["en", "sv"];
		default:
			return ["en", "sv"];
	}
};

// Dynamically import translation files and handle missing files gracefully
const loadTranslation = async (lang) => {
	try {
		const translation = await import(`./customer/${process.env.REACT_APP_ASSETS}/${lang}/translation.json`);
		return translation;
	} catch (error) {
		console.warn(`No translation found for ${lang}, skipping.`);
		return null; // Return null if the translation file doesn't exist
	}
};

// Initialize i18n with dynamically loaded resources
const initializeI18n = async () => {
	const languages = flavorLanguages(process.env.REACT_APP_ASSETS);
	const resources = {};

	for (const lang of languages) {
		const translation = await loadTranslation(lang);
		if (translation) {
			// Only add the language if the translation was successfully loaded
			resources[lang] = { translation };
		}
	}

	const currentLanguage = localStorage.getItem("language") === "" || localStorage.getItem("language") === null ? navigator.language.split("-")[0] : localStorage.getItem("language");

	i18n.use(initReactI18next).init({
		resources,
		whitelist: Object.keys(resources),
		supportedLngs: Object.keys(resources),
		ns: ["translation"],
		defaultNS: "translation",
		fallbackLng: "en",
		lng: currentLanguage, // Use the current language or fallback
		interpolation: { escapeValue: false },
	});
};

// Call the async function to initialize i18n
initializeI18n()
	.then(() => {
		console.log("i18n initialization successful.");
	})
	.catch((error) => {
		console.error("i18n initialization failed:", error);
	});

export default i18n;
