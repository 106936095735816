import React, { useState, useEffect } from 'react';
import { INDEX_URL, GOOGLE_MAPS_KEY, icons, FLAVOR } from "../../helpers/constants";
import { fixDate, getBatteryIcon } from "../../helpers/helperFunctions";
import SegmentedControl from '../SegmentedControl/SegmentedControl';
import TextField from "@mui/material/TextField";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; // Import dayjs
import 'dayjs/locale/sv';
import { MapTogglesProvider, useMapToggles } from '../Map/ToggleContext'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';
import * as request from "../../services/FetchService";
import { useTranslation } from "react-i18next";


const MessageRow = (props) => {
    const [message, setMessage] = useState(props.message)
    const { t } = useTranslation();

    const getIcon = () => {
        switch(props.message.type){
            case "standard":
                return icons.envelope_gradient
            case "info":
                return icons.battery_empty
            case "share":
                return icons.sharing

                default: return icons.envelope

        }
    }

    const getBgColor = () => {
        if((props.message.type === "standard" || props.message.type === "info") && props.openMessage === props.message.messageid){
            return "bg-primary transition-fast"
        }else if(props.sharedMessage === props.message){
            return "bg-primary transition-fast"
        }else if(props.index % 2){
            return "bg-white transition-fast"
        }else{
            return "bg-light-grey transition-fast"
        }
    }

    const getContent = () => {
        if(props.message.type === "standard" || props.message.type === "info"){
            return(
                <>
                    <div className="position-relative">
                        <div className={`eventImgBg me-3 ${props.openMessage === props.message.messageid ? "bg-white" : "bg-primary"}`}>
                            <img src={getIcon()} alt="" className={`size_20 eventImg transition-fast ${props.openMessage !== props.message.messageid ? "white-icon" : ""}`}/>
                        </div>
                        {props.message.isread === 0 &&
                            <span className="position-absolute dot bg-offline top-0 end-0 me-3"/>
                        }
                    </div>
                    <div className="d-flex flex-column pe-5 me-5 w-100">
                        <h4 className={`${props.openMessage === props.message.messageid ? "white" : "primary"} w-100 fast text-truncate primary`}>{message.title}</h4>
                        <p className={`${props.openMessage === props.message.messageid ? "white" : "primary"} fast`}>{fixDate(message.creationdate, true)}</p>
                    </div>
                </>
            )
        }else if(props.message.type === "share"){
            return(
            <>
                <div className="position-relative">
                    <div className={`rounded-circle message-img-bg me-3 ${props.sharedMessage === props.message ? "bg-white" : "bg-primary"}`}>
                        <img src={getIcon()} alt="" className={`size_20 ${props.sharedMessage !== props.message ? "white-icon" : ""}`}/>
                    </div>
                    <span className="position-absolute dot bg-offline top-0 end-0 me-3"/>
                </div>
                <div className="d-flex flex-column">
                    <h4 className="text-truncate">{t("inapp.share_device_request")}</h4>
                    <p>{t("inapp.share_device_request_sub", {from: props.message.sharedfrom})}</p>
                </div>
            </>    
            )
        }
    }


    return(
        <>
         <div className={`d-flex p-3 cursor-pointer align-items-center ${getBgColor()}`} onClick={() => props.onClick(props.message)}>
            {getContent()}
        </div>   
        </>
    )
}

export default MessageRow;