import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from 'react-i18next';


const CreateGroup = (props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)
    const [errorText, setErrorText] = useState("")
    useEffect(() => {
        setShow(props.show)
    },[props.show])
    
    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }

    const handleChange = (field, e) => {
        setErrorText("")
    }
    
    return(
        <>
        <Dialog open={show} onClose={handleClose}>
            <Card className="add_device_dialog d-flex flex-row">
                <div className="dialog_side_panel disply_none_small w-50">
                </div>
                <div className="dialog_side_panel w-50 bg-white">
                    <div className="w-100 h-100">
                        <div className="dialog_top_row">
                            <h3 className="regular">{t("devices.add_device")}</h3>
                            <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
                        </div>
                        <div className="dialog_body">
                         
                        </div>
                    </div>
       
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default CreateGroup;