import React, { useState, useEffect } from 'react';
import { FLAVOR, icons } from "../helpers/constants";
import { useTranslation } from "react-i18next";
import Account from '../components/Settings/Account';
import Safety from '../components/Settings/Safety';
import Devices from '../components/Settings/Devices';
import Billing from '../components/Settings/Billing';
import Notifications from '../components/Settings/Notifications';
import Support from '../components/Settings/Support';
import Warranty from '../components/Settings/Warranty';
import Manuals from '../components/Settings/Manuals';
import LogoutModal from '../components/Modals/LogoutModal';
import Button from '../components/Buttons/Button';
import { useIsMobile } from '../helpers/customHooks';

const Settings = (props) => {
	const MOBILE = useIsMobile();
	const [selected, setSelected] = useState("")
	const { t } = useTranslation();
	const [showLogout, setShowLogout] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [closeDevice, setCloseDevice] = useState(false)
	useEffect(() => {
	
	},[])

	const renderSettingsType = (type) => {
		switch (type){
			case "account": return <Account />
			case "safety": return <Safety />
			case "devices": return <Devices closeDevice={closeDevice} setIsOpen={(data) => setIsOpen(data)}/>
			case "billing": return <Billing />
			case "notifications": return <Notifications />
			case "support": return <Support />
			case "warranty": return <Warranty />
			case "manuals": return <Manuals />
			default: return null
		}
	}

	const backClick = () => {
		if(!isOpen){
			setSelected("")
		}else{
			setCloseDevice(!closeDevice)
		}
	}
	const firstBatch = ["account", "safety", "logout"]
	const secondBatch = ["bike", "alco"].includes(FLAVOR) ? ["devices"] : ["devices", "billing"]
	const thirdBatch = ["notifications"]
	const fourthBatch = ["alco"].includes(FLAVOR) ? ["support", "manuals"] : ["support", "warranty", "manuals"]
	return(
		<>
		<div className={`${MOBILE ? "py-5 scrollable-y w-100 px-3" : "section-left_bottom_data section-divider position-relative scrollable-y pb-5"}`}>
			<div className="d-flex flex-row mt-4 align-items-center page_header">
				<img src={icons.sidebar.settings_active} className="title_icon primary-icon" alt="..." />
				<h1 className="h1 primary ms-3">{t("settings.settings")}</h1>
			</div>
			{MOBILE && selected !== ""
			?
			<>
			<Button 
			btnClass="btn-left-section"
			className="align-self-start mt-4 mb-3" 
			icon={icons.back} 
			notext={true} 
			onClick={() => backClick()}/>
			{renderSettingsType(selected)}
			</>
			:
			<>
			<p className={`${MOBILE ? "mt-5" : "mt-3"} mb-1 thin`}>{t("settings.my_account")}</p>
				<div className="d-flex flex-column shadow-small settings-radius primary">
					{firstBatch.map((item, index) =>(
						<div 
							key={index} 
							onClick={() => item !== "logout" ? setSelected(item) : setShowLogout(true)}
							className={`p-3 d-flex justify-content-between align-items-center cursor-pointer ${index < firstBatch.length-1 ? "border-settings" : ""} ${selected === item ? "bg-primary" : "bg-white"}`}>
							{
								item === "account"
								? <span className=" body">{t("settings.edit_account")}</span>
								: item === "safety"
								? <span className="body">{t("settings.safety")}</span>
								: <span className="body text-danger">{t("settings.logout")}</span>
							}
							<img className="size_15" src={selected === item ? icons.right_arrow_light : icons.right_arrow_dark} alt={""} />
						</div>
					))}
				</div>

				<p className="mt-3 mb-1 thin">{t("settings.management")}</p>
				<div className="d-flex flex-column shadow-small settings-radius primary">
					{secondBatch.map((item, index) =>(
						<div 
							key={index} 
							onClick={() => setSelected(item)}
							className={`p-3 d-flex justify-content-between align-items-center cursor-pointer ${index < secondBatch.length-1 ? "border-settings" : ""} ${selected === item ? "bg-primary" : "bg-white"}`}>
							{
								item === "devices"
								? <span className="body">{t("settings.devices")}</span>
								: <span className="body">{t("settings.billing")}</span>
							}
							<img className="size_15" src={selected === item ? icons.right_arrow_light : icons.right_arrow_dark} alt={""} />
						</div>
					))}
				</div>
				<p className="mt-3 mb-1 thin">{t("settings.notifications")}</p>
				<div className="d-flex flex-column shadow-small settings-radius primary">
					{thirdBatch.map((item, index) =>(
						<div 
							key={index} 
							onClick={() => setSelected(item)}
							className={`p-3 d-flex justify-content-between align-items-center cursor-pointer ${""} ${selected === item ? "bg-primary" : "bg-white"}`}>
							<span className="body">{t("settings.notifications")}</span>
							<img className="size_15" src={selected === item ? icons.right_arrow_light : icons.right_arrow_dark} alt={""} />
						</div>
					))}
				</div>
				<p className="mt-3 mb-1 thin">{t("settings.support")}</p>
				<div className="d-flex flex-column  shadow-small settings-radius primary">
					{fourthBatch.map((item, index) =>(
						<div 
							key={index} 
							onClick={() => setSelected(item)}
							className={`p-3 d-flex justify-content-between align-items-center cursor-pointer ${index < fourthBatch.length-1 ? "border-settings" : ""} ${selected === item ? "bg-primary" : "bg-white"}`}>
							{
								item === "support"
								? <span className="body">{t("settings.support")}</span>
								: item === "warranty"
								? <span className="body">{t("settings.warranty")}</span>
								: <span className="body">{t("settings.user_manuals")}</span>
							}
							<img className="size_15" src={selected === item ? icons.right_arrow_light : icons.right_arrow_dark} alt={""} />
						</div>
					))}
				</div>
				</>
				}
		</div>

		
		{!MOBILE &&
			<div className={`settings-right-section w-50 scrollable-y`}>
				{renderSettingsType(selected)}
			</div>
		}

        <LogoutModal show={showLogout} onClose={() => setShowLogout(!showLogout)} />
		</>
	)
}

export default Settings;