import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';
import * as request from "../../services/FetchService";
import { fixDate } from '../../helpers/helperFunctions';


const TosModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)
    const [title, setTitle] = useState("")
    const [date, setDate] = useState()
    const [bodyText, setBodyText] = useState("")

    useEffect(() => {
        setShow(props.show)
        if(props.show){
            getTerms()
        }
    },[props.show])

    useEffect(() => {
        getTerms()
    }, [])

    const getTerms = () => {
        let lang = localStorage.getItem("language") ? localStorage.getItem("language") : "en"
        request.post("", "account/gettermsofuse", { language: lang }).then((response) => {
            console.log("REPSONSE", response);
            
            if(response?.data){
                setTitle(response.data.termsofuse.header)
                setDate(response.data.termsofuse.lastupdated)
                let format = response.data.termsofuse.body.replace(/(?:\r\n|\r|\n)/g, '<br>');
                setBodyText(format)
            }
        })
    }

    const convertedText = () => {
		return {__html: bodyText};
	};

    const acceptTos = () => {
        props.accept()
        handleClose()
    }

    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }

return(
    <>
    <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
        <Card className="d-flex flex-column scrollable-y position-relative">
            <div className="w-100">
                <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                    <div className='d-flex align-items-center'>
                        <img src={icons.info_icon} alt=""/>
                        <h3 className="ms-2">{title}</h3>
                    </div>
                    <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
                </div>
                <div className="p-3">
                    <p>{fixDate(date, true)}</p>
                    <div dangerouslySetInnerHTML={convertedText()}/>
                    {/* <div className="position-absolute d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
                        <Button onClick={() => acceptTos()} btnClass="btn-primary">{t("signup.accept")}</Button>
                    </div> */}
                </div>
            </div>
        </Card>
        <div className=" w-100 bg-white position-absolute bottom-0 d-flex flex-row p-3 justify-content-end shadow align-items-center">
            <Button onClick={() => acceptTos()} btnClass="btn-primary">{t("signup.accept")}</Button>
        </div>
    </Dialog>
    </>
)
}
export default TosModal;