import React, { useState, useEffect } from 'react';
import { useIsMobile } from '../helpers/customHooks';
import { icons } from '../helpers/constants';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const DragComponent = ({ dragId, initialH, maxH, minH, close, setClose }) => {
  const [startY, setStartY] = useState(0);
  const { t } = useTranslation();
  const location = useLocation();

  const [dragging, setDragging] = useState(false);
  const MOBILE = useIsMobile();

  const startDrag = (e) => {
    setStartY(e.touches[0].clientY);
    setDragging(true);
  };

  useEffect(() => {
    const resizableElement = document.getElementById(dragId);

    if(close && resizableElement){
        resizableElement.style.height = minH;
        setClose(false)
    }
  }, [close])

  const onDrag = (e) => {
    if (!dragging) return;
    const touchY = e.touches[0].clientY;
    const screenHeight = window.innerHeight;
    const resizableElement = document.getElementById(dragId);
    if (resizableElement) {
      resizableElement.style.height = `${screenHeight - touchY}px`;
    }
  };

  const endDrag = (e) => {
    const resizableElement = document.getElementById(dragId);
    if (resizableElement) {
      const endY = e.changedTouches[0].clientY;
      const screenHeight = window.innerHeight;
      const percentOfScreenHeight = (screenHeight * (maxH / 100)) + 'px' // Calculate percentage based on maxH
      resizableElement.style.height = endY < startY ? percentOfScreenHeight : minH;
    }
    setDragging(false);
  };

  const setTitle = () => {
    let icon;
    let text;
    switch(location.pathname){
        case "/devices":
            icon = icons.sidebar.device_active
            text = t("sidebar.my_devices")
            break;
        case "/events":
            icon = icons.sidebar.bell_active
            text = t("sidebar.alarms")
            break;
        case "/geofence":
            icon = icons.sidebar.geofence_active
            text = t("sidebar.geofence")
            break;
        case "/history":
            icon = icons.sidebar.history_active
            text = t("sidebar.history")
            break;
        case "/subscriptions":
            icon = icons.sidebar.card_active
            text = t("sidebar.subscription")
            break;
        case "/settings":
            icon = icons.sidebar.settings_active
            text = t("sidebar.settings")
            break;

        default: return;
    }

    return (
        <div className="d-flex align-content-center">
            <img className="me-2 primary-icon"src={icon} alt=""/>
            <h4 className="primary">{text}</h4>
        </div>
    )
}

  // Attach the event listeners to the whole document to ensure smooth dragging
  // even when the touch moves outside the resizable element.
  if(MOBILE){
    return (
        <div className="d-flex flex-column" id={dragId} onTouchStart={startDrag} onTouchMove={onDrag} onTouchEnd={endDrag}>
          <div className="pt-3 pb-2 d-flex justify-content-center">
              <div className="drag-element"/>
          </div>
          {setTitle()}
        </div>
      );
  }

};

export default DragComponent;