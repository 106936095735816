import { Accordion, AccordionSummary, Collapse } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { INDEX_URL, GOOGLE_MAPS_KEY, icons } from "../../helpers/constants";
import { fixDate } from "../../helpers/helperFunctions";
import { useTranslation } from "react-i18next";

const ActiveRowInterim = (props) => {
    const { t } = useTranslation();
    const dataplanText = (type) => {
        switch(type){
            case "invoice": return t("subscription.active_until")
            case "demo": return t("subscription.free")
            case "card_active": return t("subscription.next_payment")
            default: return t("")
        }
    }
    return (
        <div className={`row m-0 p-2 primary ${props.index % 2 === 0 ? "bg-white" : "bg-light-grey"}`}>
            <div className="row d-flex align-items-center">
                <div className="col-5">
                    <p className="text-truncate bold">{props.data.name}</p>
                    <p className="m-0">{props.data.uniqueid}</p>
                </div>
                <p className="col-3 bold">{props.data.paymentmethod}</p>
                <p className="col-4 ">{props?.data?.statustext}: {fixDate(props.data.renewedexpiringdate, true)}</p>
            </div>
        </div>
    )
}

export default ActiveRowInterim;