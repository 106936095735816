import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { useContextData } from '../ContextData';
import { useIsMobile } from '../../helpers/customHooks';
import axios from 'axios';


const AccountModal = (props) => {
    const MOBILE = useIsMobile();
    const { setAccessToken, setDeviceListMap, setDeviceListCompact } = useContextData();

    const navigate = useNavigate()
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)
    const [errorText, setErrorText] = useState("")

    useEffect(() => {
        setShow(props.show)

    },[props.show])

    
    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }

    let cancelTokenSource = axios.CancelToken.source();

    // Function to cancel all ongoing requests
    const cancelOngoingRequests = () => {
        cancelTokenSource.cancel('Logout initiated: Cancelling all ongoing requests.');
        // Create a new CancelToken source for future requests
        cancelTokenSource = axios.CancelToken.source();
    };


    const logout = () => {
        cancelOngoingRequests(); // Cancel ongoing requests
        localStorage.clear()
        setAccessToken(null)
        setDeviceListMap([])
        setDeviceListCompact([])
    }

    
    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                <>
                    <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                        <div className='d-flex align-items-center'>
                            <img src={icons.power_off} alt=""/>
                            <h3 className="ms-2">{t("settings.logout")}</h3>
                        </div>
                        <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
                    </div>
                    <div className="p-3">
                        <p>{t("settings.logout_body")}</p>
                    </div>
                    <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
                    <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
                    <Button btnClass="btn-danger" onClick={() => logout()}>{t("settings.logout")}</Button>
                    </div>
                </>
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default AccountModal;