import React, { useState, useEffect } from 'react';

import { Dialog, Card, Collapse } from '@mui/material';
import { images, icons, markers } from "../../helpers/constants";
import { Trans, useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';
import SegmentedControl from '../SegmentedControl/SegmentedControl';
import SafetyZoneMap from '../Map/SafetyZoneMap';
import { downloadPDF } from '../../helpers/helperFunctions';
import toast from 'react-hot-toast';


const DeviceModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)
    const [errorText, setErrorText] = useState("")
    const [devicename, setDevicename] = useState("")
    const [speedlimit, setSpeedlimit] = useState("")
    const [speedTypeValue, setSpeedTypeValue] = useState()
    const [sharingStatus, setSharingStatus] = useState(props.data.sharedstatus.sharedwith ? props.data.sharedstatus.sharedwith : [])
    const [selectedIcon, setSelectedIcon] = useState()
    const [selectedColor, setSelectedColor] = useState()
    const [powersavingMode, setPowersavingMode] = useState(false)
    const [pendingPowersaving, setPendingPowersaving] = useState(false)
    const [wakeupSettings, setWakeupSettings] = useState()
    const [pendingWakeup, setPendingWakeup] = useState(false)
    const [pendingTemperature, setPendingTemperature] = useState(false)
    const [temperatureSettings, setTemperatureSettings] = useState()
    const [pendingHumidity, setPendingHumidity] = useState(false)
    const [selectedAlarmCentral, setSelectedAlarmCentral] = useState(props.data.modelspecificinfo?.alarmcenter?.centerid ? props.data.modelspecificinfo?.alarmcenter?.centerid : -1)
    const [alarmAdditional, setAlarmAdditional] = useState(props.data.modelspecificinfo?.alarmcenter?.additionalinfo ? props.data.modelspecificinfo?.alarmcenter?.additionalinfo : "")
    const wakeArray = [
        {
            value: 7200, // 2 hours in seconds
            label: `2 ${t("settings.hours", {count : 2})}`
        },
        {
            value: 14400, // 4 hours in seconds
            label: `4 ${t("settings.hours", {count : 4})}`
        },
        {
            value: 28800, // 8 hours in seconds
            label: `8 ${t("settings.hours", {count : 8})}`
        },
        {
            value: 43200, // 12 hours in seconds
            label: `12 ${t("settings.hours", {count : 12})}`
        },
        {
            value: 86400, // 1 day in seconds
            label: `1 ${t("settings.days", {count : 1})}`
        },
        {
            value: 172800, // 2 days in seconds
            label: `2 ${t("settings.days", {count : 2})}`
        },
        {
            value: 345600, // 4 days in seconds
            label: `4 ${t("settings.days", {count : 4})}`
        },
        {
            value: 604800, // 7 days in seconds
            label: `7 ${t("settings.days", {count : 7})}`
        }
    ];


    useEffect(() => {
        setShow(props.show)
    },[props.show])

    useEffect(() => {
        if(props.type){
            let specificInfo = props.data.modelspecificinfo
            setSelectedIcon(props.data.mapicon.split("_")[0] ? props.data.mapicon.split("_")[0] : "default")
            setSelectedColor(props.data.mapicon.split("_")[1] ? props.data.mapicon.split("_")[1] : "blue")
            setDevicename(props.data.name)
            setSpeedlimit(specificInfo?.speedlimit?.speedlimitinfo ? specificInfo.speedlimit.speedlimitinfo : 0)
            setSpeedTypeValue(specificInfo?.speedlimit?.speedlimitunitraw ? specificInfo.speedlimit.speedlimitunitraw : 1)
            setSharingStatus(props.data.sharedstatus.sharedwith ? props.data.sharedstatus.sharedwith : [])
            
            setPowersavingMode(specificInfo?.powersavingmode.powersavingmodepending ? specificInfo.powersavingmode.powersavingmodependingnewmode : specificInfo.powersavingmode.powersavingmodeinfo)
            setPendingPowersaving(specificInfo?.powersavingmode.powersavingmodepending)
            
            setPendingWakeup(specificInfo?.wakeupinfo?.newsettings.pending)
            setWakeupSettings(specificInfo?.wakeupinfo?.newsettings.pending ? specificInfo.wakeupinfo?.newsettings : specificInfo.wakeupinfo?.currentsettings)
            setTemperatureSettings(specificInfo?.temp_hum?.currentsettings)
        }
    }, [props])

    useEffect(() => {
        if(props.data.modelspecificinfo.temp_hum?.currentsettings){
            const updatedSettings = Object.entries(props.data.modelspecificinfo.temp_hum?.currentsettings).reduce((acc, [key, value]) => {
                // Replace null with 0, otherwise keep the original value
                if (key.includes('AlarmSwitch')) {
                    acc[key] = value === null ? false : value;
                } else {
                    acc[key] = value === null ? 0 : value;
                }
                return acc;
            }, {});
        
            // Check if the update is necessary to avoid infinite loop
            const isUpdateNeeded = Object.values(updatedSettings).some(value => value === 0);
            setTemperatureSettings(updatedSettings);

        }
    }, [props.data.modelspecificinfo?.temp_hum?.currentsettings]);

    

    useEffect(() => {
        if(pendingWakeup){
            setWakeupSettings(props.data.modelspecificinfo.wakeupinfo?.newsettings)
        }else{
        }
    }, [pendingWakeup])

    const resetPending = () => {
        handleSave(props.type)
    }

    const mapIcons = [
        "default", "airplane", "ambulance", "bicycle", "boat", "bus", "car", "caravan", "container", "electriccar", "engine", "excavator", "forklift", "helicopter", "jet", "monstertruck", "moped", "motorcycle", "pickup", "rv", "sailboat", "snowmobile", "taxi", "tractor", "truck", "truckcontainer", "van"
    ]
    const colors = [
        "blue", "dark", "green", /* "grey",*/ "light", "orange", "yellow"
    ]



    
    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }

    const handleChange = (type, e) => {
        switch(type){
            case "devicename":
                setDevicename(e.target.value)
            break;
            case "speedlimit":
                setSpeedlimit(e.target.value)
            break;
            default: return
        }
        setErrorText("")
    }

    const handleSave = (type) => {
        let data = {}
        let reset = false
        switch(type){
            case "devicename":
                data = { deviceid: props.data.id, name: devicename }
                break;
            case "speedlimit":
                data = { deviceid: props.data.id, speedlimit: parseInt(speedlimit), speedunit: speedTypeValue }
                break;
            case "map_icon":
                let icon = `${selectedIcon}_${selectedColor}`;
                data = { deviceid: props.data.id, mapicon: icon }
                break;
            case "powersaving":
                reset = pendingPowersaving
                data = { deviceid: props.data.id, powersavingswitch: pendingPowersaving ? !powersavingMode : powersavingMode}
                break;
            case "wakeup":
                let current = props.data.modelspecificinfo.wakeupinfo?.currentsettings
                reset = pendingWakeup
                data = { 
                    deviceid: props.data.id,
                    wakebyvibswitch: pendingWakeup ? current.wakebyvib ? true : false : wakeupSettings.wakebyvib ? true : false, 
                    wakebytimeswitch: pendingWakeup ? current.wakebytime ? true : false : wakeupSettings.wakebytime ? true : false,
                    wakebytimeinfo: pendingWakeup ? current.wakebytimeinfo : wakeupSettings.wakebytimeinfo,
                    wakebylightswitch: pendingWakeup ? current.wakebylight ? true : false : wakeupSettings.wakebylight ? true : false,
                    safetyzone: pendingWakeup ? current.safetyzone ? true : false : wakeupSettings.safetyzone ? true : false
                 }
                break;
            case "temperature":
                data = { 
                    deviceid: props.data.id,
                    temptype: temperatureSettings.tempType,
                    tempswitchexceed: temperatureSettings.tempExceedAlarmSwitch,
                    tempexceed: temperatureSettings.tempExceed,
                    tempswitchbelow:temperatureSettings.tempBelowAlarmSwitch,
                    tempbelow: temperatureSettings.tempBelow,
                }
                break;
            case "humidity":
                data = { 
                    deviceid: props.data.id,
                    humswitchexceed: temperatureSettings.humExceedAlarmSwitch,
                    humexceed: temperatureSettings.humExceed,
                    humswitchbelow: temperatureSettings.humBelowAlarmSwitch,
                    humbelow: temperatureSettings.humBelow,
                }
                break;
            case "alarm_central":
                reset = true
                data = {
                    deviceid: props.data.id,
                    alarmcenter: selectedAlarmCentral,
                }
                break;
            case "unlink_alarmcenter":
                reset = true
                data = {
                    deviceid: props.data.id,
                    unlink_alarmcenter: true,
                }
                setSelectedAlarmCentral(-1)
                break;
            case "alarm_central_update":
                data = {
                    deviceid: props.data.id,
                    alarmcenter_additionalinfo: alarmAdditional
                }
                break;
            default: data = {}
        }
        props.onSave(data, reset)
    }

    const devicenameContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.device_active} alt=""/>
                    <h3 className="ms-2">{t("settings.devicename")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                    <p className="thin dark-grey text-center pb-3">{t("settings.devicename_sub")}</p>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();// Prevent the default Enter key behavior
                        handleSave(props.type)
                    }}
                >

                    <div className="form-group d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2"
                            id="name"	
                            defaultValue={devicename}
                            onChange={(e) => {
                                handleChange("devicename", e);
                            }}
                        />
                        <div className="invalid-feedback ms-1 bottom">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }

    const speedlimitContent = () => {
        /* 1 for km/h, 2 for mph, 3 for knots */
        const speedType = () => {
            const segments = [
                {
                    label: t("settings.kmh"),
                    value: 1
                },
                {
                    label: t("settings.mph"),
                    value: 2
                },
                {
                    label: t("settings.knots"),
                    value: 3
                }
            ]
            return (
                <SegmentedControl
                    name="psm"
                    callback={(val) => handleChangeSpeedType(val)}
                    value={speedTypeValue}
                    segments={segments}
                />
            )
        }
    
        const handleChangeSpeedType = (value) => {
            setSpeedTypeValue(value)
            // setTemperatureSettings((prevState) => ({
            //     ...prevState,
            //     tempType: value 
            // }))
        }


        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.tachometer} alt=""/>
                    <h3 className="ms-2">{t("settings.speedlimit")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p className="thin dark-grey text-center pb-3">{t("settings.speedlimit_sub")}</p>
                <form>
                    <div className="form-group d-flex align-items-center">
                        <input
                            title='lol'
                            type="text"
                            className="form-control bg-light-grey p-2"
                            id="speed"	
                            defaultValue={`${speedlimit}`}
                            onChange={(e) => {
                                handleChange("speedlimit", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();// Prevent the default Enter key behavior
                                    handleSave(props.type)
                                }
                            }}
                        />
                        <div className="invalid-feedback ms-1 bottom">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
                <div className="mt-3">{speedType()}</div>

            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }

    const mapiconContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.map_icon_title} alt=""/>
                    <h3 className="ms-2">{t("settings.map_icon")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3 notification-scroll">
                <p className="thin dark-grey text-center pb-3">{t("settings.mapicon_sub")}</p>
                <div className="d-flex flex-row slider_x align-items-center align-self-center icon-container">
                    {selectedIcon && mapIcons.map((icon, index) => (
                        <img key={index} onClick={() => setSelectedIcon(icon)} className={`size_45 cursor-pointer ${selectedIcon === icon && "scale-icon"}`} src={markers[selectedColor][icon]} alt=""/>
                    ))}
                </div>
                <div className="mt-3 d-flex justify-content-between mx-5">
                    {selectedColor && colors.map((color, index) => (
                        <div key={index} onClick={() => setSelectedColor(color)}  style={{borderRadius: "4px", width: "25px", height: "25px"}} className={`d-flex justify-content-center cursor-pointer mapicon ${color}`}>
                            <img src={icons.check} alt="" className={`p-1 white-icon ${color === selectedColor ? "": "d-none"}`}/>
                        </div>
                    ))}
                </div>
  
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }

    const powersavingContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.battery_settings} alt=""/>
                    <h3 className="ms-2">{t("settings.powersaving_mode")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                    <p className="thin dark-grey text-center pb-3">{t("settings.powersaving_sub")}</p>
                    {pendingPowersaving &&
                        <div className="d-flex justify-content-center align-items-center green cursor-pointer pb-3" onClick={() => resetPending()}>
                            <p className="gradient">{t("settings.reset_pending")}</p>
                            <img className="size_15 ms-2" src={icons.reset_arrow} alt="..." /> 
                        </div>
                    }
                    <div className={`px-3 py-2 d-flex justify-content-between bg-light-grey align-items-center border-sm`}>
                        <h4 className="regular">{t("settings.powersaving_mode")}</h4>
                        <div className="form-check form-switch">
                            <input className="form-check-input" onChange={() => setPowersavingMode(!powersavingMode)} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={powersavingMode}/>
                        </div>
                    </div> 
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }


    const deleteHistoryContent = () => {
        let sharedByMe = props.data.sharedstatus.sharedbyme
        let sharedWithMe = props.data.sharedstatus.sharedwithme
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.trashcan} alt=""/>
                    <h3 className="ms-2">{t("settings.delete_history")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                {sharedByMe 
                    ? <p>{t("settings.delete_history_body_sharedbyme")}</p>
                    : sharedWithMe
                    ? <p>{t("settings.delete_history_body_sharedwithme")}</p>
                    : <p>{t("settings.delete_history_body")}</p>
                }
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => props.deleteData(props.type)} btnClass="btn-danger">{t("settings.delete")}</Button>
            </div>
            </>
        )
    }

    const deleteDeviceContent = () => {
        let sharedByMe = props.data.sharedstatus.sharedbyme
        let sharedWithMe = props.data.sharedstatus.sharedwithme

        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.trashcan} alt=""/>
                    <h3 className="ms-2">{t("settings.delete_device")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                {sharedByMe 
                    ? <p>{t("settings.delete_device_body_sharedbyme")}</p>
                    : sharedWithMe
                    ? <p>{t("settings.delete_device_body_sharedwithme")}</p>
                    : <p>{t("settings.delete_device_body")}</p>
                }
                
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => props.deleteData(props.type)} btnClass="btn-danger">{t("settings.delete")}</Button>
            </div>
            </>
        )
    }
    const sharingContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.sharing} alt=""/>
                    <h3 className="ms-2">{t("settings.sharing")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                {sharingStatus.map((shared, index) => (
                    
                    <div key={index} className={`d-flex flex-row justify-content-between py-2 ${index !== sharingStatus.length - 1 ? "border-bottom": ""}`}>
                        <div className="d-flex flex-column">
                            <p className="bold">{shared.email}</p>
                            <p className={`${shared.pending ? "" : "d-none"}`}>{t("settings.awaiting_confirmation")}</p>
                        </div>
                        <img className="cursor-pointer" src={icons.cross} onClick={() => props.removeShared(shared.pending, shared.sharedid, shared.email)} alt="..." />
                    </div>
                ))}
                {sharingStatus.length === 0 &&
                    <p>{t("settings.no_shared_accounts")}</p>
                }
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()}btnClass="btn-secondary">{t("settings.close")}</Button>
               {/* <Button btnClass="btn-danger">{t("settings.delete")}</Button> */}
            </div>
            </>
        )
    }

    const updateWakeup = (type) => {
        let currentIndex, newValueIndex;
        switch (type) {
            case "minus":
            case "plus":
                // Find the current index of wakebytimeinfo in the wakeArray
                currentIndex = wakeArray.findIndex(item => item.value === (wakeupSettings.wakebytimeinfo || 0));
                // Determine the new index based on the operation
                if (type === "plus") {
                    newValueIndex = currentIndex + 1 < wakeArray.length ? currentIndex + 1 : currentIndex; // Ensure it does not exceed array bounds
                } else if (type === "minus") {
                    newValueIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : currentIndex; // Ensure it does not go below 0
                }
                // Update the state only if a valid new index is found
                if (newValueIndex !== undefined && newValueIndex !== currentIndex) {
                    setWakeupSettings(prevState => ({
                        ...prevState,
                        wakebytimeinfo: wakeArray[newValueIndex].value
                    }));
                }
                break;
            case "wakebyvib":
            case "wakebytime":
            case "wakebylight":
                setWakeupSettings((prevState) => {
                    // Check if the setting to be toggled is the only one currently set to 1
                    const otherKeys = ['wakebyvib', 'wakebytime', 'wakebylight'].filter(key => key !== type);
                    const allOthersZero = otherKeys.every(key => (prevState[key] === 0 || !prevState[key]));
                    const currentValue = prevState[type];
    
                    if (allOthersZero && (currentValue === 1 || currentValue)) {
                        // Prevent toggling if it is the last remaining '1'
                        toast.error(t("toast.one_active"))
                        return prevState;
                    } else {
                        return {
                            ...prevState,
                            [type]: !currentValue
                        };
                    }
                });
                break;
            case "safetyzone":
                // Toggle the corresponding setting
                if(props.data.latitude == null && props.data.longitude == null){
                    toast.error(t("toast.no_pos_safety_zone"))
                    return
                }
                setWakeupSettings((prevState) => ({
                    ...prevState,
                    [type]: !prevState[type]
                }));
                break;
            default:
                // Do nothing if the type is unrecognized
                break;
        }
    };

    const wakeupContent = () => {
        let waketimeLabel = wakeupSettings && (wakeArray.find(item => item.value === wakeupSettings.wakebytimeinfo)?.label || `${wakeupSettings.wakebytimeinfo}s`);
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.battery_full} alt=""/>
                    <h3 className="ms-2">{t("settings.wakeup_mode")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p className="thin dark-grey text-center pb-3">{t("settings.wakeup_mode_sub")}</p>
                {pendingWakeup &&
                    <div className="d-flex justify-content-center align-items-center gradient cursor-pointer pb-3" onClick={() => resetPending()}>
                        <p className="gradient">{t("settings.reset_pending_wakeup")}</p>
                        <img className="size_15 ms-2" src={icons.reset_arrow} alt="..." /> 
                    </div>
                }
                <div className={`p-3 d-flex justify-content-between bg-light-grey align-items-center border-12 mb-3`}>
                    <h4 className={`regular ${pendingWakeup ? "dark_grey": ""}`}>{t("settings.vibration_wakeup")}</h4>
                    <div className="form-check form-switch">
                        <input className={`form-check-input`} disabled={pendingWakeup} onChange={() => updateWakeup("wakebyvib")} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={wakeupSettings && wakeupSettings.wakebyvib}/>
                    </div>
                </div>
                <div className="p-3 d-flex flex-column bg-light-grey border-12 mb-3">
                    <div className={`d-flex justify-content-between align-items-center`}>
                        <h4 className={`regular ${pendingWakeup ? "dark_grey": ""}`}>{t("settings.time_wakeup")}</h4>
                        <div className="form-check form-switch">
                            <input className={`form-check-input`} disabled={pendingWakeup} onChange={() => updateWakeup("wakebytime")} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={wakeupSettings && wakeupSettings.wakebytime}/>
                        </div>
                    </div> 
                    <div className="d-flex justify-content-between align-items-center my-3">
                        <div className="bg-grey border-sm d-flex cursor-pointer">
                            <p className={`vertical-divider-dark px-3 my-2 cursor-pointer ${pendingWakeup ? "dark_grey": ""}`} onClick={() => !pendingWakeup && wakeupSettings && wakeupSettings.wakebytime && updateWakeup("minus")}>-</p>
                            <p className={`px-3 my-2 cursor-pointer ${pendingWakeup ? "dark_grey": ""}`} onClick={() => !pendingWakeup && wakeupSettings && wakeupSettings.wakebytime && updateWakeup("plus")}>+</p>
                        </div>
                        <p className={`py-2 px-3 bg-grey border-sm ${pendingWakeup ? "dark_grey": ""}`}>{waketimeLabel}</p>
                    </div>
        
             
                    <div className={`d-flex justify-content-between align-items-center`}>
                        <h4 className={`regular ${pendingWakeup || ( wakeupSettings && wakeupSettings.wakebyvib) ? "dark_grey": ""}`}>{t("settings.safety_zone")}</h4>
                        <div className="form-check form-switch">
                            <input className="form-check-input" disabled={pendingWakeup || ( wakeupSettings && wakeupSettings.wakebyvib)} onChange={() => updateWakeup("safetyzone")} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={wakeupSettings && (wakeupSettings.wakebyvib ? false : wakeupSettings.safetyzone)}/>
                        </div>
                    </div>
                    {props.data.latitude !== null && props.data.longitude !== null &&
                        <Collapse in={wakeupSettings && wakeupSettings.safetyzone && !wakeupSettings.wakebyvib}>
                                <p className="thin dark-grey text-center mt-1 pb-3">{t("settings.safety_zone_sub")}</p>
                                
                                <div className="shadow-sm border-12 safety-map-border" style={{width: "auto", height: "200px"}}>
                                    <SafetyZoneMap device={props.data}/>
                                </div>
                        </Collapse>   
                    }
                </div>
                <div className={`p-3 d-flex justify-content-between bg-light-grey align-items-center border-12 mb-3`}>
                    <h4 className={`regular ${pendingWakeup ? "dark_grey": ""}`}>{t("settings.wakeby_light")}</h4>
                    <div className="form-check form-switch">
                        <input className="form-check-input" disabled={pendingWakeup} onChange={() => updateWakeup("wakebylight")} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={wakeupSettings && wakeupSettings.wakebylight}/>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass={`btn-primary ${pendingWakeup ? "disabled" : ""}`}>{t("settings.save")}</Button>
            </div>
            </>
        )
    }

    const updateTemperature = (type) => {
        let data;
        switch(type){
            case "exceeds":
                data = "tempExceedAlarmSwitch";
                break;
            case "falls_below":
                data = "tempBelowAlarmSwitch";
                break;
            default: data = type
        }

        if(data === "plus_exceeds"){
            setTemperatureSettings((prevState) => ({
                ...prevState,
                tempExceed: prevState.tempExceed + 1
            }))
        }else if(data === "minus_exceeds"){
            setTemperatureSettings((prevState) => ({
                ...prevState,
                tempExceed: prevState.tempExceed - 1
            }))
        }else if(data === "plus_below"){
            setTemperatureSettings((prevState) => ({
                ...prevState,
                tempBelow: prevState.tempBelow + 1
            }))
        }else if(data === "minus_below"){
            setTemperatureSettings((prevState) => ({
                ...prevState,
                tempBelow: prevState.tempBelow - 1
            }))
        }else{
            setTemperatureSettings((prevState) => ({
                ...prevState,
                [data]: !prevState[data]
            }))
        }

    }

    const temperatureContent = () => {
        const temperatureType = () => {
            const segments = [
                {
                    label: t("settings.celsius"),
                    value: 0
                },
                {
                    label: t("settings.farenheit"),
                    value: 1
                }
            ]
            return (
                <SegmentedControl
                    name="psm"
                    callback={(val) => handleChangeTemperatureType(val)}
                    value={temperatureSettings && temperatureSettings.tempType}
                    segments={segments}
                />
            )
        }
    
        const handleChangeTemperatureType = (value) => {
            setTemperatureSettings((prevState) => ({
                ...prevState,
                tempType: value 
            }))
        }


        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.battery_full} alt=""/>
                    <h3 className="ms-2">{t("settings.temperature_settings")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                    <p className="thin dark-grey text-center pb-3">{t("settings.temperature_sub")}</p>
                    {pendingTemperature &&
                        <div className="d-flex justify-content-center align-items-center green cursor-pointer pb-3" onClick={() => alert("RESET")}>
                            <p className="gradient">{t("settings.reset_pending_temperature")}</p>
                            <img className="size_15 ms-2" src={icons.reset_arrow} alt="..." /> 
                        </div>
                    }

                    <div className="mb-3">{temperatureType()}</div>

                    <div className="p-3 d-flex flex-column bg-light-grey border-12 mb-3">
                        <div className={`d-flex justify-content-between align-items-center`}>
                            <h4 className="regular">{t("settings.exceeds")}</h4>
                            <div className="form-check form-switch">
                                <input className={`form-check-input`} onChange={() => updateTemperature("exceeds")} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={temperatureSettings && temperatureSettings.tempExceedAlarmSwitch}/>
                            </div>
                        </div> 
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <div className="bg-grey border-sm d-flex cursor-pointer">
                                <p className="vertical-divider-dark px-3 my-2 cursor-pointer" onClick={() => temperatureSettings && temperatureSettings.tempExceed && updateTemperature("minus_exceeds")}>-</p><p className="px-3 my-2 cursor-pointer" onClick={() => temperatureSettings && updateTemperature("plus_exceeds")}>+</p>
                            </div>
                            <p className="py-2 px-3 bg-grey border-sm">{temperatureSettings && temperatureSettings.tempExceed ? temperatureSettings.tempExceed : 0} °{temperatureSettings?.tempType === 0 ? "C" : "F"}</p>
                        </div>
                    </div>
                    <div className="p-3 d-flex flex-column bg-light-grey border-12">
                        <div className={`d-flex justify-content-between align-items-center`}>
                            <h4 className="regular">{t("settings.falls_below")}</h4>
                            <div className="form-check form-switch">
                                <input className={`form-check-input`} onChange={() => updateTemperature("falls_below")} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={temperatureSettings && temperatureSettings.tempBelowAlarmSwitch}/>
                            </div>
                        </div> 
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <div className="bg-grey border-sm d-flex cursor-pointer">
                                <p className="vertical-divider-dark px-3 my-2 cursor-pointer" onClick={() => temperatureSettings && updateTemperature("minus_below")}>-</p><p className="px-3 my-2 cursor-pointer" onClick={() => temperatureSettings && updateTemperature("plus_below")}>+</p>
                            </div>
                            <p className="py-2 px-3 bg-grey border-sm">{temperatureSettings && temperatureSettings.tempBelow ? temperatureSettings.tempBelow : 0} °{temperatureSettings?.tempType === 0 ? "C" : "F"}</p>
                        </div>
                    </div>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }

    const updateHumidity = (type) => {
        let data;
        switch(type){
            case "exceeds":
                data = "humExceedAlarmSwitch";
                break;
            case "falls_below":
                data = "humBelowAlarmSwitch";
                break;
            default: data = type
        }
        if(data === "plus_exceeds"){
            setTemperatureSettings((prevState) => ({
                ...prevState,
                humExceed: prevState.humExceed < 90 ? prevState.humExceed + 5 : 95
            }))
        }else if(data === "minus_exceeds"){
            setTemperatureSettings((prevState) => ({
                ...prevState,
                humExceed: prevState.humExceed > 5 ? prevState.humExceed - 5 : 5
            }))
        }else if(data === "plus_below"){
            setTemperatureSettings((prevState) => ({
                ...prevState,
                humBelow: prevState.humBelow < 90 ? prevState.humBelow + 5 : 95
            }))
        }else if(data === "minus_below"){
            setTemperatureSettings((prevState) => ({
                ...prevState,
                humBelow: prevState.humBelow > 5 ? prevState.humBelow - 5 : 5
            }))
        }else{
            setTemperatureSettings((prevState) => ({
                ...prevState,
                [data]: !prevState[data]
            }))
        }

    }



    const humidityContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.battery_full} alt=""/>
                    <h3 className="ms-2">{t("settings.humidity_settings")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                    <p className="thin dark-grey text-center pb-3">{t("settings.humidity_sub")}</p>
                    {pendingHumidity &&
                        <div className="d-flex justify-content-center align-items-center green cursor-pointer pb-3" onClick={() => alert("RESET")}>
                            <p className="gradient">{t("settings.reset_pending_humidity")}</p>
                            <img className="size_15 ms-2" src={icons.reset_arrow} alt="..." /> 
                        </div>
                    }

                    <div className="p-3 d-flex flex-column bg-light-grey border-12 mb-3">
                        <div className={`d-flex justify-content-between align-items-center`}>
                            <h4 className="regular">{t("settings.exceeds")}</h4>
                            <div className="form-check form-switch">
                                <input className={`form-check-input`} onChange={() => updateHumidity("exceeds")} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={temperatureSettings && temperatureSettings.humExceedAlarmSwitch}/>
                            </div>
                        </div> 
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <div className="bg-grey border-sm d-flex cursor-pointer">
                                <p className="vertical-divider-dark px-3 my-2 cursor-pointer" onClick={() => temperatureSettings && temperatureSettings.humExceed && updateHumidity("minus_exceeds")}>-</p><p className="px-3 my-2 cursor-pointer" onClick={() => temperatureSettings && updateHumidity("plus_exceeds")}>+</p>
                            </div>
                            <p className="py-2 px-3 bg-grey border-sm">{temperatureSettings && temperatureSettings.humExceed ? temperatureSettings.humExceed : 0}%</p>
                        </div>
                    </div>
                    <div className="p-3 d-flex flex-column bg-light-grey border-12">
                        <div className={`d-flex justify-content-between align-items-center`}>
                            <h4 className="regular">{t("settings.falls_below")}</h4>
                            <div className="form-check form-switch">
                                <input className={`form-check-input`} onChange={() => updateHumidity("falls_below")} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={temperatureSettings && temperatureSettings.humBelowAlarmSwitch}/>
                            </div>
                        </div> 
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <div className="bg-grey border-sm d-flex cursor-pointer">
                                <p className="vertical-divider-dark px-3 my-2 cursor-pointer" onClick={() => temperatureSettings && temperatureSettings.tempBelow && updateHumidity("minus_below")}>-</p><p className="px-3 my-2 cursor-pointer" onClick={() => temperatureSettings && temperatureSettings.humBelow && updateHumidity("plus_below")}>+</p>
                            </div>
                            <p className="py-2 px-3 bg-grey border-sm">{temperatureSettings && temperatureSettings.humBelow ? temperatureSettings.humBelow : 0}%</p>
                        </div>
                    </div>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }

    const alarmCentralContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.central} alt=""/>
                    <h3 className="ms-2">{t("settings.alarm_central")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p className="thin dark-grey text-center pb-3">{t("settings.alarm_central_sub")}</p>
                <div className="d-flex justify-content-betwen">
                    <select onChange={(event) => setSelectedAlarmCentral(event.target.value)} value={selectedAlarmCentral} className="form-control bg-light-grey">
                        <option value="">{t("settings.select")}</option>
                        {props.centrals?.map((central, index) => (
                            <option key={index} value={central.id}>{central.name}</option>
                        ))}
                    </select>
                    {/* <Button onClick={() => downloadCentralData(selectedAlarmCentral)} icon={icons.download} notext={true} btnClass="btn-transparent bg-dark-grey ms-3" /> */}
                </div>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }

    const alarmCentralContentSelected = () => {

        const unlinkCentral = () => {
            handleSave("unlink_alarmcenter")
        }

        const downloadCentralData = (value) => {
            const central = props.centrals?.find(item => parseInt(item.id) === parseInt(value));
            if (central && central.url) {
                downloadPDF(central.url)
            } else {
                toast.error(t("toast.error"))
            }        
        }

        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.central} alt=""/>
                    <h3 className="ms-2">{t("settings.alarm_central")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p className="thin dark-grey text-center pb-3">
                            <Trans
                            i18nKey="settings.alarm_central_sub_selected"
                            components={{
                            central: <span className="bold"/>,
                            }}
                            values={{
                            central: props.data.modelspecificinfo?.alarmcenter?.centername,
                            }}
                        />
                </p>
                <p className="mb-3 text-center text-gradient underline cursor-pointer bold" onClick={() => downloadCentralData(selectedAlarmCentral)}>{t("settings.download_contract")}</p>
                <textarea rows={3} placeholder={t("settings.additional_info_placeholder")} defaultValue={alarmAdditional} onChange={(event) => setAlarmAdditional(event.target.value)} className="p pb-3 form-control text-area-boy bg-light-grey" />    
                <p className="mt-3 text-center text-danger underline cursor-pointer bold" onClick={() => unlinkCentral()}>{t("settings.alarm_central_unlink")}</p>
                <p className="thin text-center">{t("settings.alarm_central_unlink_disclaimer")}</p>

            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave("alarm_central_update")} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }


    const typeRender = () => {
        switch(props.type){
            case "devicename": return devicenameContent()
            case "speedlimit": return speedlimitContent()
            case "delete_history": return deleteHistoryContent()
            case "delete_device": return deleteDeviceContent()
            case "sharing": return sharingContent()
            case "map_icon": return mapiconContent()
            case "powersaving": return powersavingContent()
            case "wakeup": return wakeupContent()
            case "temperature": return temperatureContent()
            case "humidity": return humidityContent()
            case "alarm_central": return props.data.modelspecificinfo?.alarmcenter?.centerid === null ? alarmCentralContent() : alarmCentralContentSelected()

            default: return null
        }
    }
    
    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                    {typeRender()}
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default DeviceModal;