import React, { useState, useEffect } from 'react';

const Button = (props) => {
    const [btnClass, setBtnClass] = useState(props.btnClass)
    useEffect(() => {
    
    },[])

    useEffect(() => {
        setBtnClass(props.btnClass)
    }, [props])
    
    return(
        <>
            <button className={`btn ${btnClass ? btnClass : "btn-secondary"} ${props.className ? props.className : ""}`} onClick={props.onClick}>
                {props.icon && <img className={`${props.notext ? "size_15" : "button_icon me-1"} ${props.lightIcon || btnClass === "btn-primary" ? "icon_light" : "icon_dark"}`} src={props.icon} alt={"..."}/>}     
                <label className={`cursor-pointer btn-text bold ${props.notext ? "d-none" : ""}`}>
                    {props.icon && '\u00A0'}
                    {props.children}
                </label>
            </button>
        </>
    )
}

export default Button;