import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons, STRIPE_TEST_KEY } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCard from '../Subscription/Stripe/AddCard';
import CardRowEdit from '../Subscription/Stripe/CardRowEdit';
import AddCardInterim from '../Subscription/Stripe/AddCardInterim';


const stripePromise = loadStripe(STRIPE_TEST_KEY);

const EditCardModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)
    const [errorText, setErrorText] = useState("")
    const [cards, setCards] = useState(props.cards ? props.cards : [])


    useEffect(() => {
        setShow(props.show)
        setCards(props.cards ? props.cards : [])
    },[props.show])

    useEffect(() => {
        setCards(props.cards ? props.cards : [])
    }, [props.cards])

    
    const handleClose = () => {
        props.onClose()
        props.updateCards()
      //  setShow(false)
    }


    const removeCard = (data) => {
        props.deleteCard(data)
    }

    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                    <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                        <div className='d-flex align-items-center'>
                            <img src={icons.user_settings} alt=""/>
                            <h3 className="ms-2">{cards.length > 0 ? t("subscription.edit_card") : t("subscription.add_card")}</h3>
                        </div>
                        <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
                    </div>
                    {cards.length > 0 
                        ?
                        <div className="p-3">
                           {cards.map((card, index) => (
                                <CardRowEdit removeCard={(data) => removeCard(data)} key={index} data={card}/>
                            ))}
                        </div>
                        :
                        <div className="p-3">
                            <Elements stripe={stripePromise}>
                                <AddCardInterim updateCards={() => props.updateCards()} />
                                {/* <AddCard updateCards={() => props.updateCards()}/> */}
                            </Elements>
                        </div>
                    }

                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default EditCardModal;