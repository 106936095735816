import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons } from "../../helpers/constants";
import { fixDate, fixDateEvent, getEventIcon, translateEvents } from '../../helpers/helperFunctions';
import { useTranslation } from "react-i18next";
import { MapTogglesProvider, useMapToggles } from '../Map/ToggleContext'; // Adjust the path as necessary

const GeofenceRow = (props) => {
	const { t } = useTranslation();
    const { activeGeo, setActiveGeo } = useMapToggles();
    const [toggleActive, setToggleActive] = useState(false)


    const toggleFence = (event, id) => {
        event.stopPropagation();
        props.setGeofenceToggle(id)
        props.focusOnMap()

    }

    useEffect(() => {
        setToggleActive(props.geofence.selected)
        props.focusOnMap()

    }, [props.geofence.selected])


    const focusGeofence = (event, geo) => {
        event.stopPropagation();
        setActiveGeo(geo)
        props.focusOnMap()
    }

    return (
        <div className={`m-0 p-0 p-3 ${props.index % 2 === 0 ? "bg-white" : "bg-light-grey shadow-inner"}`}>
            <div className="align-items-center justify-content-between d-flex flex-row">
                <div className="d-flex flex-row align-items-center">

                <div className={`me-3 ${!props.checkboxVisibility ? "d-none" : toggleActive ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1`} onClick={(event) => toggleFence(event, props.geofence)}>
                    {toggleActive && <img className="white-icon" src={icons.check} alt={""} />}
                </div>
                <img className="me-3" src={icons.geofence_active} alt={""} />
                <div className="d-flex flex-column">
                    <h4 className='primary'>{props.geofence.name}</h4>
                </div>
                </div>
                <div className="d-flex flex-row align-items-center me-1">
                    <p className="cursor-pointer primary bold" onClick={() => props.editGeofence(props.geofence)}>{t("geofence.edit")}</p>
                    <img className="size_20 m-0 align-self-center ms-3 cursor-pointer primary-icon" src={icons.marker} alt={""} onClick={(event) => focusGeofence(event, props.geofence)}/>
                </div>
            </div>
        </div>
    )
}

export default GeofenceRow;