import React, { useState, useEffect } from 'react';

const SafetyRowPlaceholder = (props) => {

    return(
        <>
        <div key={props.index} className={`d-flex justify-content-between p-3 placeholder-glow`}>
            <div className="d-flex flex-column w-50">
                <div className="placeholder mb-1 w-25" />
                <p className="bold placeholder w-25" />
            </div>
            <p className="d-flex align-self-end placeholder w-25" />
        </div>
 
        </>
    )
}

export default SafetyRowPlaceholder;