import axios from 'axios';
import {API_URL, CUST} from '../helpers/constants.js';

export async function post(id, url, formData) {
	const headers = {
		'Content-Type':'application/json',
		'cache-control':'no-cache',
        'flavor': `${CUST}`,
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	};
    return axios({
        url: API_URL+url,
        method: "POST",
        config: headers,
        data: formData,
        headers: headers,
        crossDomain: true
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })
}

export async function get(url){
    const headers = {
		'Content-Type':'application/json',
		'cache-control':'no-cache',
        'flavor': `${CUST}`,
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	}; 
    return axios({
        url: API_URL+url,
        method: "GET",
        config: headers,
        headers: headers,
        crossDomain: true
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })
}

export async function getSubscription(url){
    const headers = {
		'Content-Type':'application/json',
		'cache-control':'no-cache',
        'flavor': CUST,
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	}; 
    return axios({
        url: 'https://api.cloudappapi.com/'+url,
        method: "GET",
        config: headers,
        headers: headers,
        crossDomain: true
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })
}


export async function postSubscription(id, url, formData) {
	const headers = {
		'Content-Type':'application/json',
		'cache-control':'no-cache',
        'flavor': CUST,
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	};
    return axios({
        url: 'https://api.cloudappapi.com/'+url,
        method: "POST",
        config: headers,
        data: formData,
        headers: headers,
        crossDomain: true
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })
}


export async function put(url, formData){
    const headers = {
		'cache-control':'no-cache',
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	}; 
    let datapic = new FormData();
    datapic.append('File', formData);
    datapic.append('publictoken', 'wcySegj73wuAA-swet-up-4W6&76+0D/f?f$Q+$f3$5?3D$2b$10$Dlwq5z2fAA2qwcySegj73wu4pf/Cf.33xHaBTrCi7afkascg5IJBgC');
    return axios({
        url: API_URL+url,
        method: "PUT",
        config: headers,
        data: datapic,
        crossDomain: true
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })
}