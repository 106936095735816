import React, { useState, useEffect } from 'react';

const EventRowPlaceholder = (props) => {

    return(
        <>
            <div className="p-3 align-items-center justify-content-between d-flex flex-row placeholder-glow">
                <div className="d-flex flex-row align-items-center w-100">
                    <div className="eventImgBg me-3 placeholder"/>
                    <div className="d-flex flex-column w-50">
                        <span className="bold placeholder w-100"></span>
                        <p className="text-truncate placeholder w-50 mt-1"></p>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center w-50">
                    <div className="d-flex flex-column w-50">
                        <span className="bold placeholder w-100" />
                        <p className="text-truncate placeholder w-100 mt-1"></p>
                    </div>
                    <div className="d-flex flex-column w-50 ms-2">
                        <span className="bold placeholder w-100"></span>
                        <p className="text-truncate placeholder w-100 mt-1"></p>
                    </div>
                </div>
            </div> 
        </>
    )
}

export default EventRowPlaceholder;