import React, { useState, useEffect } from 'react';
import { copyToClipboard, fixDate, getMapMarker } from '../../helpers/helperFunctions';
import { Marker, InfoBox } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";
import { icons } from "../../helpers/constants";
import Button from '../Buttons/Button';

const DeviceMarker = (props) => {
    const { t } = useTranslation();
    const [device, setDevice] = useState(props.device)
    const [specificInfo, setSpecificInfo] = useState()
    const [showInfo, setShowInfo] = useState(false)
    const [address, setAddress] = useState(t("map.calculate_address"))
    const [showNameInner, setShowNameInner] = useState(false)
    const [copy, setCopy] = useState(t("map.copy_position"))
    const latLng = props.device && props.device.latitude ? { lat: props.device.latitude, lng: props.device.longitude } : null;

    useEffect(() => {
        setSpecificInfo(device.communicationmode)
    }, [device])

    useEffect(() => {
        if(device !== props.device){
            setDevice(props.device)
        }
    }, [props.device])


    const getBatteryIcon = (value) => {
        if (value > 95) {
            return icons.battery_full;
        } else if (value > 70) {
            return icons.battery_three_quarter;
        } else if (value > 40) {
            return icons.battery_half;
        } else if (value > 20) {
            return icons.battery_quarter;
        } else {
            return icons.battery_empty;
        }
    }

    const calculateAddress = (lat, lng) => {
        if(address === t("map.calculate_address")){
            const geocoder = new window.google.maps.Geocoder();
            const latandLng = new window.google.maps.LatLng(lat, lng);
            geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === 'OK') {
                if (results[0]) {
                    setAddress(results[0].formatted_address)
                } else {
                    return
                }
                } else {
                    return
                }
            })
        }
    }

    const copyPosition = (lat, lng) => {
        let data = `https://www.google.com/maps/place/${lat},${lng}`;
        copyToClipboard(data)
            .then(result => {
                setCopy(t("map.copy_successful"))
            })
            .catch(error => {
                setCopy(t("map.copy_failed"))
            });

            setTimeout(() => {
                setCopy(t("map.copy_position"))
            }, 2000);
    }

    if(!latLng || (props.hideOffline && device.status === "offline")){
        return null;
    }

    return (
        <Marker
        clusterer={props.clusterer}
        position={latLng}
        onMouseOut={() => setShowNameInner(false)}
        onMouseOver={() => setShowNameInner(true)}
        icon={{
                url: getMapMarker(device.mapicon, device.status === "online" ? true : false),
                size: new window.google.maps.Size(45, 45), // Setting the size here
                scaledSize: new window.google.maps.Size(45, 45), // Optional, for scaling the icon
                anchor: new window.google.maps.Point(22.5, 40) // Set the anchor point to the center of the icon
            }}
        onClick={props.clicked}
        >
            <div>
                {props.isOpen && (
                    <InfoBox
                        position={latLng}
                        options={{
                            disableAutoPan: true,
                            closeBoxURL: "",
                            boxStyle: {
                                width: '300',
                                boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 3px 7px 0px rgba(0, 0, 0, 0.10), 0px 13px 13px 0px rgba(0, 0, 0, 0.09), 0px 28px 17px 0px rgba(0, 0, 0, 0.05), 0px 51px 20px 0px rgba(0, 0, 0, 0.01), 0px 79px 22px 0px rgba(0, 0, 0, 0.00)',
                                borderRadius: "12px",
                            },
                            pixelOffset: new window.google.maps.Size(-175,2, 0)
                        }}
                    >
                        <div className="d-flex flex-column device-info-box">
                            <div className="d-flex flex-row justify-content-between align-items-center border-settings p-3 bg-white">
                                <div className="d-flex flex-row align-items-center w-75">
                                    <img className="size_25 me-2" src={device.status === "online" ? icons.signal_green : icons.signal_red} alt=''/>
                                    <h3 className="me-3 text-truncate primary">{device.name}</h3>
                                </div>
                                <img className="cursor-pointer" src={icons.close_gray} onClick={props.clicked} alt="..." /> 
                            </div>
                            <div className="d-flex flex-column py-3 px-4 bg-white ">
                                <div className="d-flex flex-row">
                                    <div className="d-flex flex-row align-items-center">
                                        <img className="size_25 me-2" src={icons.marker} alt=''/>
                                        <div className="d-flex flex-column align-items-start">
                                            <p className="bold">{t("map.position")}</p>
                                            <p>{latLng.lat.toFixed(5)}, {latLng.lng.toFixed(5)}</p>
                                            <p onClick={() => calculateAddress(latLng.lat, latLng.lng)}className="cursor-pointer">{address}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="d-flex flex-row mt-3 justify-content-between">
                                    <div className="d-flex flex-column align-items-start">
                                        <div className="d-flex flex-row align-items-center">
                                            <img className="size_25 me-2" src={icons.clock} alt=''/>
                                            <div className="d-flex flex-column align-items-start">
                                                <p className="bold">{t("map.connection_time")}</p>
                                                <p>{fixDate(device.lastconnectiontime, false, true)}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row align-items-center mt-3">
                                            <img className="size_25 me-2" src={icons.tachometer} alt=''/>
                                            <div className="d-flex flex-column align-items-start">
                                                <p className="bold">{t("map.speed")}</p>
                                                <p>{specificInfo.speed.speedinfo} {specificInfo.speed.speedlimitunit}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row align-items-center">
                                            <img className="size_25 me-2" src={icons.pin} alt=''/>
                                            <div className="d-flex flex-column align-items-start">
                                                <p className="bold">{t("map.position_time")}</p>
                                                <p>{fixDate(device.lastpositiontime, false, true)}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row align-items-center mt-3">
                                            <img className="size_25 me-2" src={getBatteryIcon(specificInfo.internalbatteryinfo)} alt=''/>
                                            <div className="d-flex flex-column align-items-start">
                                                <p className="bold">{t("map.battery")}</p>
                                                <p>{specificInfo.internalbatteryinfo}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Button btnClass="btn-primary" onClick={() => copyPosition(latLng.lat, latLng.lng)}>{copy}</Button>
                        </div>

                    </InfoBox>
                )}
            </div>
            {(props.showName || (showNameInner && !props.isOpen)) &&
                <InfoBox
                position={latLng}
                options={{
                    disableAutoPan: true,
                    closeBoxURL: "",
                    boxStyle: {
                        width: '100px',
                        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 8px 5px 0px rgba(0, 0, 0, 0.05), 0px 14px 5px 0px rgba(0, 0, 0, 0.01), 0px 21px 6px 0px rgba(0, 0, 0, 0.00)',
                        borderRadius: "6px",
                        maxHeight: '50px',
                        overflow: 'hidden',
                    },
                    pixelOffset: new window.google.maps.Size(-50, 2)
                }}>
                   <div className="d-flex bg-white p-1 justify-content-center p bold primary text-truncate">{device.name}</div>
                </InfoBox>
            }
        </Marker>
    )
}

export default DeviceMarker

