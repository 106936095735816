import React, { useState, useEffect } from "react";
import Main from './components/Main/Main';
import { I18nextProvider } from 'react-i18next';
import { Toaster } from 'react-hot-toast';
import i18n from './translations/i18n'; // The i18n config file you set up
function App() {
	return (
		<React.Fragment>
			<I18nextProvider i18n={i18n}>
				<Toaster 
					position="top-center"
					reverseOrder={false}
					gutter={8}
					containerClassName=""
					containerStyle={{
					top: 8,
			
					}}
					toastOptions={{
					// Define default options
					className: 'toast-text bold primary rounded',
					duration: 5000,
					style: {
						boxShadow: "0px 51px 20px rgba(0, 0, 0, 0.01), 0px 28px 17px rgba(0, 0, 0, 0.05), 0px 13px 13px rgba(0, 0, 0, 0.09), 0px 3px 7px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
					},
					// Default options for specific types
					success: {
					duration: 2000,
					iconTheme: {
						primary: "var(--green)",
						secondary: '#fff',
					},
					},
					error: {
						iconTheme: {
						primary: "var(--red)",
						secondary: '#fff',
						},
						},
					}}
				 />
				<Main/>
			</I18nextProvider>
		</React.Fragment>
	);
}

export default App;
