import React, { useState, useEffect } from 'react';
import { copyToClipboard, fixDate, getMapMarker } from '../../helpers/helperFunctions';
import { MarkerF, AdvancedMarker, InfoBox } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";
import { icons } from "../../helpers/constants";
import Button from '../Buttons/Button';


const HistoryMarker = (props) => {

    const [point, setPoint] = useState(null)
    
    useEffect(() => {
        setPoint(props.point)
    },[props.point])

    const fixedDate = (OGdate) => {
        let date = new Date(OGdate);
        let pad = (num) => num.toString().padStart(2, '0');
		let year = date.getFullYear();
		let month = pad(date.getMonth() + 1); // getMonth() returns 0-11
		let day = pad(date.getDate());
		let hours = pad(date.getHours());
		let minutes = pad(date.getMinutes());
		return `${day}/${month} ${hours}:${minutes}`
    } 

    return (
        point &&
        <>
            <InfoBox
                position={{lat: point.lat, lng: point.lng}}
                options={{
                    closeBoxURL: "",
                    boxStyle: {
                        width: '120px',
                        boxShadow: '0 3px 18px -8px rgba(0,0,0,0.15)',
                        borderRadius: "5px",
                    },
                    pixelOffset: new window.google.maps.Size(-60, 0)
                }}
            >
                <div className="d-flex flex-column cursor-pointer" onClick={() => props.closeWindow()}>
                    <div className={`d-flex flex-column justify-content-between align-items-center shadow py-2 px-2 ${point.speed === 0 ? "bg-danger" : "bg-white"}`}>
                        <h4>{point.speed.toFixed(0)} {point.unit ? point.unit : "km/h"}</h4>
                        <h4>{fixedDate(point.time)}</h4>
                    </div>
                </div>
            </InfoBox>
        </>
    )
}

export default HistoryMarker;