import React, { useState, useEffect } from 'react';
import { useIsMobile } from '../../helpers/customHooks';
import { useTranslation } from "react-i18next";
import { INDEX_URL, CUST, icons, STRIPE_TEST_KEY } from "../../helpers/constants";
import MonthYearSegment from '../PeriodSegment/PeriodSegement';
import Button from '../Buttons/Button';
import DeviceActivationList from '../Devices/DeviceActivationList';
import { Collapse } from '@mui/material';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCard from './Stripe/AddCard';
import CardRow from './Stripe/CardRow';
import SubscriptionModal from '../Modals/SubscriptionModal';
import * as request from "../../services/FetchService.js";
import { useStripe } from "@stripe/react-stripe-js";
import EditCardModal from '../Modals/EditCardModal';


const stripePromise = loadStripe(STRIPE_TEST_KEY);

const ActivatingInterim = (props) => {
    const { t } = useTranslation();
	const MOBILE = useIsMobile();
	const [showModal, setShowModal] = useState(false)
	const [type, setType] = useState("")
	const pricing = require(`./Stripe/Pricing/${process.env.REACT_APP_ASSETS}/pricelist.json`);
	const [country, setCountry] = useState(props.cards && props.cards.length > 0 ? props.cards[0].card.country : "SE")
	const [paymentType, setPaymentType] = useState("card")
	const [thinking, setThinking] = useState(false)
    const [cards, setCards] = useState(props.cards ? props.cards : [])
	const [dots, setDots] = useState('')
	const [step, setStep] = useState(1)
	const [ssf, setSsf] = useState(false)
	const [selectedPlan, setSelectedPlan] = useState("")
	const [allSelected, setAllSelected] = useState(false)
	const [period, setPeriod] = useState(true)
	const [activatableDevices, setActivatableDevices] = useState(props.devices ?? [])
	const stripe = useStripe();
	const [cost, setCost] = useState(49)
	const [currency, setCurrency] = useState(" SEK")
	const [showCard, setShowCard] = useState(false)

	useEffect(() => {
		setCards(props.cards ? props.cards : [])
	}, [props.cards])

	const purchaseDone = () => {

		setPaymentType("")
		setStep(1)
		setSsf(false)
		setSelectedPlan("")
		setPeriod(false)
		setAllSelected(false)
		if (!allSelected) {
			let updatedDevices = [...activatableDevices];
			updatedDevices.forEach((device, index) => {
			  device.selected = false;
			});
			setActivatableDevices(updatedDevices);
		}
        props.finalize()
		//setActivating(false)
	}

	const selectDevice = (index) => {
        let updatedDevices = [...activatableDevices];
        if (index !== -1) {
            var device = updatedDevices[index];
            if (device.selected !== true){
                device.selected = true;
            }else{
                device.selected = false;
            } 
            updatedDevices[index] = device;
            setActivatableDevices(updatedDevices);
          }
    }

    const finalizePurchase = () => {
		let obj = {
			type: period ? "month" : "year",
			plan: selectedPlan,
			//quantity: getCheckedDevices(),
			devices: getCheckedImeis(),
			//deviceTrial: [],
			//trialPeriod: 0
		}

		let data = {
			deviceid: getCheckedIds()
		}
		request.post("", "dataplan/start", data).then(async (response) => {
			if(response.data.error){
				setThinking(false)
				setStep(6)
			}else{
				setThinking(false)
				nextStep()	
			}
		})

		// request.postSubscription("", "payments/dataplan/start", obj).then(async (response) => {
		// 	if(response.data.successful){
		// 		if(response.data.status === "succeeded"){
		// 			setThinking(false)
		// 			nextStep()
		// 		}else{
		// 			const paymentIntent = await stripe.confirmCardPayment(response.data.client_secret);
		// 			if(paymentIntent.paymentIntent.status === "succeeded"){
		// 				setThinking(false)
		// 				nextStep()
		// 			}else{
		// 				setThinking(false)
		// 				setStep(6)	
		// 			}
		// 		}
		// 	}else{
		// 		setThinking(false)
		// 		setStep(6)
		// 	}
		// })
	}

	useEffect(() => {
		priceInfo(country)
	}, [country])

	useEffect(() => {
		if(step === 4){
			setThinking(true)
			finalizePurchase()
		}
	}, [step])

	const getCheckedDevices = () => {
		let checkedDevices = activatableDevices?.filter(device => device.selected);
		return checkedDevices.length
	}

	const getCheckedIds = () => {
		let checkedDevices = activatableDevices?.filter(device => device.selected).map(device => device.deviceid);
		return checkedDevices
	}

	const getCheckedImeis = () => {
		let checkedDevices = activatableDevices?.filter(device => device.selected).map(device => device.uniqueid);
		return checkedDevices
	}

    const priceInfo = (country) => {
        switch(country){
            case "SE":
                setCost(49)
                setCurrency(" SEK")
            break;
            case 'NO':
                setCost(49)
                setCurrency(" NOK")
            break;
            case 'FI':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'AX':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'DK':
                setCost(36)
                setCurrency(" DKK")
            break;
            case 'DE':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'FR':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'GB':
                setCost(4.39)
                setCurrency(" £ GBP")
            break;
            case 'IE':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'PL':
                setCost(22)
                setCurrency(" PLN")
            break;
            case 'NL':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'BE':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'IT':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'ES':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'CH':
                setCost(4.90)
                setCurrency(" CHF")
            break;
            case 'AT':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'CZ':
                setCost(119)
                setCurrency(" CZK")
            break;
            case 'PT':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'GR':
                setCost(4.79)
                setCurrency( "€ EUR")
            break;
            case 'BG':
                setCost(9)
                setCurrency(" LEV")
            break;
            case 'CY':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'EE':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'LV':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'LT':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'HR':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'LU':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'MT':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'RO':
                setCost(23)
                setCurrency(" RON")
            break;
            case 'SK':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            case 'SI':
                setCost(4.79)
                setCurrency(" € EUR")
            break;
            default:
                setCost(5.59)
                setCurrency(" $ USD")
        }
    }

    useEffect(() => {
		if(allSelected){
			let updatedDevices = [...activatableDevices];
            updatedDevices.map((device, index) => {
				device.selected = true
            })
			setActivatableDevices(updatedDevices)
		}else{
			let updatedDevices = [...activatableDevices];
            updatedDevices.map((device, index) => {
				device.selected = false
            })
			setActivatableDevices(updatedDevices)
		}
    }, [allSelected])


	const openModal = (variant) => {
		setShowModal(!showModal)
		setType(variant)
	}

    const nextStep = (plan = selectedPlan) => {
		setSelectedPlan(plan)
		setStep(step + 1)
	}

	const segments = [
		{
			label: t("subscription.monthly"),
			value: true
		},
		{
			label: t("subscription.yearly"),
			value: false
		}
	]

	const resetStep = (stepValue) => {
		setStep(stepValue)
		if(stepValue === 1){
			setSsf(false)
			setSelectedPlan("")
			setPeriod(period)
			setAllSelected(false)
			if (!allSelected) {
				let updatedDevices = [...activatableDevices];
				updatedDevices.forEach((device, index) => {
				  device.selected = false;
				});
				setActivatableDevices(updatedDevices);
			}
		}
	}

    const stepCounter = () => (
		<div className="d-flex flex-row align-items-center justify-content-center mt-3">
			<div className="d-flex flex-column align-items-center" onClick={() => resetStep(1)}>
				<div className={`activate-step-counter bold ${step === 1 ? "current": step > 1 ? "done cursor-pointer" : "shadow-inner dark_grey"}`}><p>1</p></div>
				<p className={`bold mt-2 ${step === 1 ? "current": step > 1 ? "primary" : "dark_grey"}`}>{t("subscription.select_plan")}</p>
			</div>
			<div className="d-flex flex-column align-items-center mx-2">
				<div className={`activate-step-divider mb-4 bold ${step > 1 ? "done" : ""}`} />
			</div>
			<div className="d-flex flex-column align-items-center">
				<div className={`activate-step-counter bold ${step === 2 ? "current": step > 2 ? "done cursor-pointer" : "shadow-inner dark_grey"}`}><p>2</p></div>
				<p className={`bold mt-2 ${step === 2 ? "current": step > 2 ? "primary" : "dark_grey"}`}>{t("subscription.details")}</p>
			</div>
			<div className="d-flex flex-column align-items-center mx-2">
				<div className={`activate-step-divider mb-4 bold ${step > 2 ? "done" : ""}`} />
			</div>
			<div className="d-flex flex-column align-items-center">
				<div className={`activate-step-counter bold ${step === 3 ? "current": step > 3 ? "done cursor-pointer" : "shadow-inner dark_grey"}`}><p>3</p></div>
				<p className={`bold mt-2 ${step === 3 ? "current": step > 3 ? "primary" : "dark_grey"}`}>{t("subscription.checkout")}</p>
			</div>
		</div>
	)



	const renderStep1 = () => {
		if(MOBILE){
			return(
			<>
			{/* BASIC CARD*/}
			<div className="card p-4 d-flex flex-column justify-content-between border-20 border-0 shadow-small mt-3">
				<div className="d-flex flex-row justify-content-between align-items-center">
					<h2 className='primary'>{t("subscription.subscription")}</h2>
					<div className="d-flex">
						<div className={`d-flex flex-column align-items-end primary`}>
							<div className="d-flex">
								<h2>{cost}</h2>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{currency}</h4>
							</div>
							<p>{t("subscription.monthly")}</p>
						</div>
					</div>
				</div>
				<div className="d-flex flex-row mt-3 align-items-center justify-content-between">
					<p className="dark_grey">{t("subscription.basic_subtitle")}</p>
					<div className="d-flex flex-column justify-content-end h-100">
						{/* {stats("basic")} */}
						<Button onClick={() => nextStep("basic")} btnClass="btn-primary gradient-button-primary w-100">{t("subscription.select_plan")}</Button>
					</div>
				</div>
			</div>
			</>
			)
		}else{
			return(
				<div className="mt-4 d-flex flex-column align-items-center">
					<div className="d-flex flex-row mt-4">
						{/* BASIC CARD */}
						<div className="card pt-5 pb-4 px-4 d-flex flex-column border-20 border-0 shadow-small dataplan-card-width">
							<h2 className="align-self-center primary">{t("subscription.subscription")}</h2>
							{/* <p className="align-self-center dark_grey mt-2">{t("subscription.basic_subtitle")}</p> */}
							<div className="d-flex justify-content-center my-4">
								<div className={`d-flex flex-column align-items-end primary`}>
									<div className="d-flex">
										<h1>{cost}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{currency}</h4>
									</div>
									<p>{t("subscription.monthly")}</p>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-end h-100 primary">
								<Button onClick={() => nextStep("basic")} btnClass="btn-primary gradient-button-primary w-100 p-3 border-12">{t("subscription.select_plan")}</Button>
							</div>
						</div>
					</div>
				</div>
			)
		}
		
	}
	const renderStep2 = () => {

		const purchaseSummaryMobile = () => {
			return(
				<div className="position-absolute bottom-0 start-0 end-0 px-3 pb-3">
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<div className="d-flex flex-row justify-content-between">
							<div className="d-flex flex-column">
								<p className="dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
								<div className="d-flex flex-column">
									<div className="d-flex">
										<h1>{cost*getCheckedDevices()}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{currency}</h4>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-end">
								<Button onClick={() => nextStep()} btnClass="btn-primary gradient-button-primary">{t("subscription.proceed")}</Button>
							</div>
						</div>
					</div>
				</div>
			)
		}

		const purchaseSummaryDesktop = () => {
			return(
				<div className="ms-4">
					<div className="d-flex flex-row align-items-center px-3 opacity-0 mb-2">
						<h4>""</h4>
						<div className="centered_line ms-2" />
					</div>
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<p className="align-self-center mb-3 bold dark_grey">{t("subscription.purchase_summary")}</p>
						<p className="mt-2 dark_grey">{t("subscription.devices")}</p>
						<h4 className="mt-2">{getCheckedDevices() + " " + t("devices.device", {count : getCheckedDevices()})}</h4>
						<hr/>

						<p className="mt-2 dark_grey">{t("subscription.total")}/{t("subscription.month")}</p>
						<div className="d-flex flex-column mb-4">
							<div className="d-flex">
								<h1>{cost*getCheckedDevices()}</h1>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{currency}</h4>
							</div>
						</div>

						<Button onClick={() => nextStep()} btnClass={`btn-primary gradient-button-primary w-100 mt-auto ${getCheckedDevices() < 1 ? "disabled": ""}`}>{t("subscription.proceed")}</Button>
					</div>
				</div>
			)
		}
		if(MOBILE){
			return (
				<>
					<div className="d-flex flex-column my-3">
						<div className="d-flex flex-row align-items-center px-3 mb-2">
							<h4 className="text-nowrap">{t("subscription.select_devices")}</h4>
							<div className="centered_line ms-3" />
						</div>
							<div className="border-12 subscription_device_list scrollable-y shadow-medium">
								<DeviceActivationList 
									text={t("devices.choose_all")}
									selected={activatableDevices.some(device => !device.selected) ? false : true}
									onClick={() => setAllSelected(!allSelected)}
								/>
								{activatableDevices.map((device, index) => (
									<DeviceActivationList
										key={index}
										text={device.name}
										subtitle={device.uniqueid}
										index={index}
										selected={device.selected ? true : false}
										onClick={() => selectDevice(index)}
									/>
								))}
							</div>
					</div>
					{activatableDevices.some(device => device.selected) &&
						purchaseSummaryMobile()
					}
				</>
			)
		}else{
			return(
				<>
					<div className="d-flex flex-row justify-content-center mt-4">
						<div className="d-flex flex-column w-75" style={{maxWidth: "500px"}}>
							<div className="d-flex flex-row align-items-center px-3 mb-2">
								<h4 className="text-nowrap">{t("subscription.select_devices")}</h4>
								<div className="centered_line ms-3" />
							</div>
							<div className="border-12 subscription_device_list scrollable-y shadow-medium">
								<DeviceActivationList 
									text={t("devices.choose_all")}
									selected={activatableDevices.some(device => !device.selected) ? false : true}
									onClick={() => setAllSelected(!allSelected)}
								/>
								{activatableDevices.map((device, index) => (
									<DeviceActivationList
										key={index}
										text={device.name}
										subtitle={device.uniqueid}
										index={index}
										selected={device.selected ? true : false}
										onClick={() => selectDevice(index)}
									/>
								))}
							</div>
						</div>
	
						<div className="w-50" style={{maxWidth: "350px"}}>
							{purchaseSummaryDesktop()}
						</div>
					</div>
				</>
			)
		}	
	}
	const renderStep3 = () => {

		const purchaseSummaryMobile = () => {
			return(
				<div className="position-absolute bottom-0 start-0 end-0 px-3 pb-3">
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<div className="d-flex flex-row justify-content-between">
							<div className="d-flex flex-column">
								<p className="dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
								<div className="d-flex flex-column">
									<div className="d-flex">
										<h1>{cost*getCheckedDevices()}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{currency}</h4>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-end">
							<Button onClick={() => nextStep()} btnClass={`btn-primary ${(paymentType !== "card" || !cards || cards.length === 0 || thinking) ? "disabled" : ""} gradient-button-primary w-100 mt-auto ${getCheckedDevices() < 1 ? "disabled": ""}`}>{t("subscription.proceed")}</Button>
							</div>
						</div>
					</div>
				</div>
			)
		}

		const purchaseSummaryDesktop = () => {
			return(
				<>
					<div className="d-flex flex-row align-items-center px-3 mb-2 opacity-0">
						<h4>""</h4>
						<div className="centered_line ms-2" />
					</div>
					<div className="ms-4">
						<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
							<p className="align-self-center mb-3 bold dark_grey">{t("subscription.purchase_summary")}</p>
							<p className="dark_grey">{t("subscription.devices")}</p>
							<h4 className="mt-2">{getCheckedDevices() + " " + t("devices.device", {count : getCheckedDevices()})}</h4>
							<hr/>

							<p className="dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
							<div className="d-flex flex-column mb-4">
								<div className="d-flex">
									<h1>{cost*getCheckedDevices()}</h1>
									{'\u00A0'}
									<h4 className="align-self-end mb-1">{currency}</h4>
								</div>
								<p className={`${period || getCheckedDevices() < 1 ? "d-none" : "dark_grey"}`}>{t("subscription.total_savings")}:</p>
								<div className={`${period || getCheckedDevices() < 1 ? "d-none" : "green d-flex"}`}>
									<h2>{cost}</h2>
									{'\u00A0'}
									<h4 className="align-self-end mb-1">{currency}</h4>
								</div>
							</div>
							<Button onClick={() => nextStep()} btnClass={`btn-primary ${(paymentType !== "card" || !cards || cards.length === 0 || thinking) ? "disabled" : ""} gradient-button-primary w-100 mt-auto ${getCheckedDevices() < 1 ? "disabled": ""}`}>{t("subscription.proceed")}</Button>
						</div>
					</div>
				</>
			)
		}
		if(MOBILE){
			return(
				<div className="d-flex flex-column my-3 mb-5">
					<div className="d-flex flex-row align-items-center px-3 mb-2">
						<h4 className="text-nowrap">{t("subscription.payment_method")}</h4>
						<div className="centered_line ms-3" />
					</div>
					<div className="card border-0 p-4 w-100 shadow-medium border-12">
						<div className="d-flex flex-row justify-content-between align-items-center">
							<h4>{t("subscription.card_payment")}</h4>
						</div>
						{cards.length > 0
							?
							cards.map((card, index) => (
								<CardRow key={index} data={card}/>
							))
							: <>						
								<Button className="mt-3" btnClass="btn-primary" onClick={() => setShowCard(true)}>{t("subscription.add_card")}</Button>
							</>
						}								
					</div>
					{purchaseSummaryMobile()}
				</div>
				
			)
		}else{
			return(
				<div className="d-flex flex-row justify-content-center mt-4">
					<div className="d-flex flex-column w-75" style={{maxWidth: "500px"}}>
							<div className="d-flex flex-row align-items-center px-3 mb-2">
								<h4 className="text-nowrap">{t("subscription.payment_method")}</h4>
								<div className="centered_line ms-3" />
							</div>
							<div className="card border-0 p-4 w-100 shadow-medium border-12">
								<div className="d-flex flex-row justify-content-between align-items-center">
									<h4>{t("subscription.card_payment")}</h4>
								</div>
								{cards.length > 0
									?
									cards.map((card, index) => (
										<CardRow key={index} data={card}/>
									))
									: <>						
										<Button className="mt-3" btnClass="btn-primary" onClick={() => setShowCard(true)}>{t("subscription.add_card")}</Button>
									</>
								}								
							</div>
						</div>
	
						<div className="w-50" style={{maxWidth: "350px"}}>
							{purchaseSummaryDesktop()}
						</div>
				</div>
			)
		}
		
	}

	useEffect(() => {
		let intervalId;
		if(thinking){
			let dotCount = 0
			intervalId = setInterval(() => {
				dotCount = (dotCount % 3) + 1; // Loop through 1, 2, 3 dots
				setDots('.'.repeat(dotCount))
			}, 500)
		}

		return () => {
			clearInterval(intervalId); // Clean up interval
		  };
	}, [thinking])



	const renderStep4 = () => {
		return(
			<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
				<div className="card p-4 shadow-small border-12 d-flex">
					<h3 className="align-self-center mt-5">{t("subscription.processing")+dots}</h3>
				</div>
			</div>
		)
	}
	
	const renderStep5 = () => {
		return(
			<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
				<div className="card p-4 w-100 shadow-small border-12 d-flex flex-column align-items-center">
					<h3 className="mt-5">{t("subscription.purchase_successful")}</h3>
					<p>{t("subscription.purchase_successful_body")}</p>
					<hr className="w-100"/>
					<div className="d-flex flex-column align-items-center">
						<p>{t("subscription.total")}/{t("subscription.month")}</p>
						<p>{cost}{currency}</p>
						<Button  onClick={() => purchaseDone()} btnClass="btn-primary gradient-button-primary mt-4">{t("subscription.done")}</Button>
					</div>
				</div>
			</div>
		)
	}
	const renderError = () => (
		<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
			<div className="card p-4 shadow-small border-12 d-flex">
				<h3 className="align-self-center mt-5">{t("subscription.something_went_wrong")}</h3>
				<Button  onClick={() => purchaseDone()} btnClass="btn-primary gradient-button-primary mt-4">{t("subscription.go_back")}</Button>
			</div>
		</div>
	)
    
    return(
        <>
            <Button className="align-self-start mt-3" btnClass="btn-left-section" icon={icons.back} notext={true} onClick={() => step > 1 ? setStep(step - 1) : props.finalize()}/>
            {stepCounter()}
            {	step === 1
                ? renderStep1()
                : step === 2
                ? renderStep2()
                :step === 3
                ? renderStep3()
                :step === 4
                ? renderStep4()
                : step === 5
                ? renderStep5()
                : step === 6
				? renderError()
				: null
            }
        <SubscriptionModal show={showModal} type={type} onClose={() => setShowModal(!showModal)} />
		<EditCardModal show={showCard} cards={cards} updateCards={() => props.updateCards()} onClose={() => setShowCard(!showCard)} />

        </>
    )
}

export default ActivatingInterim;