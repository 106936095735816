import React, { createContext, useContext, useState, useEffect } from 'react';

// Define initial state for the access token
const initialAccessToken = localStorage.getItem("accesstoken") ? localStorage.getItem("accesstoken") : null;

// Create the context
const ContextData = createContext({
    accessToken: initialAccessToken,
    deviceListMap: [],
    deviceListCompact: [],
    setAccessToken: () => {}, // Provide a default function
    setDeviceListMap: () => {},
    setDeviceListCompact: () => {}

});

// Custom hook to consume the context
export const useContextData = () => useContext(ContextData);

// Context provider component
export const ContextDataProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(initialAccessToken);
    const [deviceListMap, setDeviceListMap] = useState([])
    const [deviceListCompact, setDeviceListCompact] = useState([])
    
    return (
        <ContextData.Provider value={{ 
            accessToken,
            setAccessToken,
            deviceListMap,
            setDeviceListMap,
            deviceListCompact,
            setDeviceListCompact
        }}>
            {children}
        </ContextData.Provider>
    );
};