import React, { useState, useEffect } from 'react';


const DeviceRowPlaceholder = (props) => {

    return(
        <>
            <div className="p-3 align-items-center justify-content-between d-flex flex-row placeholder-glow">
                <div className="d-flex flex-row align-items-center w-100">
                    <p className="text-truncate placeholder w-50 me-5"></p>
                    <p className="text-truncate placeholder w-50 me-2"></p>
                    <div className="w-50">
                        <p className="text-truncate placeholder w-25 me-2"></p>
                        <p className="text-truncate placeholder w-25"></p>
                        <p className="text-truncate placeholder w-25"></p>
                    </div>

                </div>
            </div> 
        </>
    )
}

export default DeviceRowPlaceholder;