import React, { useState, useEffect } from 'react';
import { icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import * as request from "../../services/FetchService";
import { downloadPDF } from '../../helpers/helperFunctions';

const Warranty = (props) => {
	const { i18n, t } = useTranslation();
    const [warrantyList, setWarrantyList] = useState()
    const [loading, setLoading] = useState(true)





    useEffect(() => {
        getWarranty()
    }, [])


    useEffect(() => {
		// This function will be called whenever the language changes
		const onLanguageChanged = () => {
            getWarranty()
		};
	
		// Register the event listener for language changes
		i18n.on('languageChanged', onLanguageChanged);
	
		// Cleanup function to remove the event listener
		return () => {
		  i18n.off('languageChanged', onLanguageChanged);
		};
	  }, [i18n]); // Dependency array to ensure effect is set up correctly


    const getWarranty = () => {
        let lang = localStorage.getItem("language")
        request.post("", "services/warranty", {language: lang ? lang : "en"}).then((response) => {
           setWarrantyList(response.data.warranty)
           setLoading(false)
        })  
    }
    
    return(
        <>
         <p className="thin mb-1">{t("settings.warranty")}</p>
         <div className="d-flex flex-column shadow-small settings-radius">
            {warrantyList && warrantyList.map((warranty, index) => (
                <div 
                key={index} 
                className={`p-3 d-flex justify-content-between align-items-center bg-white ${index < warrantyList.length-1 ? "border-settings" : ""}`}>
                    <h4 className="regular">{warranty.header}</h4>
                    <img className="size_15 cursor-pointer" src={icons.download} alt={""} onClick={() => downloadPDF(warranty.pdfurl)}/>
                </div>

            ))}     
        </div>
        {warrantyList && warrantyList.length === 0 && !loading &&
            <div className="p-3 w-100">
                <h3 className="regular text-center">{t("settings.no_warranties_found")}</h3>
            </div>
        }
        </>
    )
}

export default Warranty;