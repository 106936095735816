import { Collapse } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons } from "../../helpers/constants";
import { MapTogglesProvider, useMapToggles } from '../Map/ToggleContext'; // Adjust the path as necessary
import DeviceSelectionRow from "../../components/Devices/DeviceSelectionRow";
import { useTranslation } from "react-i18next";

const GroupRow = (props) => {
    const { t } = useTranslation();
    const [visibleDetail, setVisibleDetail] = useState(null)
    const [errorText, setErrorText] = useState("")
    const [groupName, setGroupName] = useState(props.group.name)
    const [allSelected, setAllSelected] = useState(false)
    const [devices, setDevices] = useState([])
    const [activeCount, setActiveCount] = useState(0)
    const [inactiveCount, setInactiveCount] = useState(0)
    const { mapToggles, toggleMode } = useMapToggles();

    useEffect(() => {
        getInitialSelected()
    },[])

    const getInitialSelected = () => {
        let availableDevices = props.devices.map(device => {
            // Check if the device is not in a group or is in the current group
            if (!device.groupid || props.group.deviceId.includes(device.id)) {
                // Mark it as selected if it is in the current group
                return { ...device, selected: props.group.deviceId.includes(device.id) };
            }
            return device;
        }).filter(device => 
            // Filter devices that are not in any group or are marked as selected
            !device.groupid || device.selected
        );
        setDevices(availableDevices);
    }

    const handleChange = (field, e) => {
        setErrorText("")
        if (field === "name"){
            setGroupName(e.target.value);
        };
    }

    useEffect(() => {
        if(!visibleDetail){
            setGroupName(props.group.name)
            getInitialSelected()
        }
    }, [visibleDetail])
    
    const toggleDetail = (id) => {
        setVisibleDetail(visibleDetail === id ? null : id);
    }

    const setCount = () => {
        setActiveCount(
            devices?.filter(device => 
                device.status === "online" && props.group.deviceId.includes(device.id)
              ).length)
        setInactiveCount(
            devices?.filter(device => 
                device.status !== "online" && props.group.deviceId.includes(device.id)
              ).length
        )
    }

    useEffect(() => {
        setCount()
    }, [devices])



	const selectDevice = (index) => {
        let updatedDevices = [...devices];
        if (index !== -1) {
            var device = updatedDevices[index];
            if (device.selected !== true){
                device.selected = true;
            }else{
                device.selected = false;
            } 
            updatedDevices[index] = device;
            setDevices(updatedDevices);
          }
    }
    

    useEffect(() => {
        if(visibleDetail){
            toggleMode("show_groups", true, props.group.deviceId)
        }else{
            toggleMode("show_groups", false, null)
        }
    }, [visibleDetail])



	useEffect(() => {
		if(allSelected && devices.length > 0){
			let updatedDevices = [...devices];
            updatedDevices.map((device, index) => {
				device.selected = true
            })
			setDevices(updatedDevices)
		}else{
            if(devices.length>0){
                let updatedDevices = [...devices];
                updatedDevices.map((device, index) => {
                    device.selected = false
                })
                setDevices(updatedDevices)
            }
		}
    }, [allSelected])

    useEffect(() => {
        if(!allSelected && devices.length > 0){
            let updatedDevices = [...devices];
            updatedDevices.map((device, index) => {
                device.selected = false
            })
            setDevices(updatedDevices)
        }
    }, [allSelected])

    const checkChecked = () => {
		let checkedDevices = devices?.filter(device => device.selected).map(device => device.id);;
		return checkedDevices
	}
    
    return(
        <>
            <div className={`p-3 d-flex align-items-center justify-content-between cursor-pointer ${visibleDetail ? "bg-primary" : props.index % 2 === 0 ? "bg-white" : "bg-light-grey shadow-inner"}`} onClick={() => toggleDetail(props.group.id)}>
                <div className="d-flex align-items-center">
                <img src={visibleDetail ? icons.group_select : icons.group} className="size_25 me-3"alt="..."/>
                <div className="d-flex flex-column">
                    <h4 className={`text-truncate ${visibleDetail && "text-white"}`}>
                        {props.group.name}
                    </h4>
                    <p className={`${visibleDetail && "text-white"}`}>
                        {props.group.deviceId.length + " " + t("devices.device", {count : props.group.deviceId.length})}
                    </p>
                </div>
                </div>
                <div className="d-flex flex-column cursor-pointer">
                    <div className="d-flex flex-row cursor-pointer align-items-center">
                        <img src={icons.signal_green} className={`size_20 me-2`} alt="..." />
                        <p className={`cursor-pointer ${visibleDetail && "text-white"}`}>
                            {activeCount + " " + t("devices.device", {count : activeCount})}
                        </p>
                    </div>
                    <div className="d-flex cursor-pointer flex-row align-items-center">
                        <img src={icons.signal_red} className={`size_20 me-2`} alt="..." />
                        <p className={`${visibleDetail && "text-white"} cursor-pointer`}>
                            {inactiveCount + " " + t("devices.device", {count : inactiveCount})}
                        </p>
                    </div>
                </div>
            </div>
            <Collapse in={visibleDetail === props.group.id}>
                <div className="d-flex flex-column align-items-start p-4">
                        <p className="bold">{t("devices.name")}</p>
                        <input className="form-control bg-grey w-100" onChange={(e) => handleChange("name", e)} value={groupName} />
                        <p className="bold mt-3">
                            {t("devices.devices_in_group")}
                        </p>
                        <div className="group_device_list scrollable-y shadow-small w-100">
                            <DeviceSelectionRow 
                                text={t("devices.choose_all")}
                                selected={devices.some(device => !device.selected) ? false : true}
                                onClick={() => setAllSelected(!allSelected)}
                            />
                            {devices.map((device, index) => (
                                <DeviceSelectionRow
                                    key={index}
                                    text={device.name}
                                    index={index}
                                    selected={device.selected ? true : false}
                                    onClick={() => selectDevice(index)}
                                />
                            ))}
                        </div>
                        <div className="d-flex flex-row justify-content-end w-100 mt-3">
                        <button className="btn btn-danger btn-text ms-2 bold" onClick={() => props.deleteGroup(props.group.id)}>{t("devices.delete_group")}</button>
                        <button className="btn btn-primary btn-text ms-2 bold" onClick={() => props.editGroup({groupid: props.group.id, name: groupName, deviceid: checkChecked()})}>{t("devices.edit_group")}</button>
                    </div>  
                </div>
            </Collapse>
        </>
    )
}

export default GroupRow;