import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';


const SubscriptionModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)
    const [errorText, setErrorText] = useState("")

    useEffect(() => {
        setShow(props.show)
    },[props.show])

    
    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }

    const stats = (variant) => {
        return (
            <>
                <div className="d-flex flex-row align-items-center my-2">
                    {stoplight("green")}
                    <p className="ms-2 bold">
                        {variant === "basic"
                            ? t("subscription.120_sec_update")
                            : variant === "boost"
                            ? t("subscription.15_sec_update")
                            : t("subscription.10_sec_update")
                        }
                    </p>
                </div>
                <div className="d-flex flex-row align-items-center my-2">
                    {variant === "basic" || variant === "boost"
                        ?
                        <>
                        {stoplight("yellow")}
                        <p className="ms-2 bold">{t("subscription.eu_tracking")}<sup>1</sup></p>
                        </>
                        :
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.world_tracking")}<sup>2</sup></p>
                        </>
                    }
                </div>
                <div className="d-flex flex-row align-items-center my-2">
                    {variant === "basic"
                        ?
                        <>
                            {stoplight("yellow")}
                            <p className="ms-2 bold">{t("subscription.history_1_week")}</p>
                        </>
                        : variant === "boost"
                        ?
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.history_2_years")}</p>
                        </>
                        :
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.history_5_years")}</p>
                        </>
                    }
                </div>
                <div className="d-flex flex-row align-items-center my-2">
                    {variant === "basic"
                        ?
                        <>
                            {stoplight("yellow")}
                            <p className="ms-2 bold">{t("subscription.geofences_1")}</p>
                        </>
                        : variant === "boost"
                        ?
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.geofences_10")}</p>
                        </>
                        :
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.geofences_unlimited")}</p>
                        </>
                    }
                </div>
                <div className="d-flex flex-row align-items-center my-2">
                    {variant === "basic"
                        ?
                        <>
                        {stoplight("yellow")}
                        <p className="ms-2 bold">{t("subscription.powersaving_always_on")}</p>
                        </>
                        : variant === "boost"
                        ?
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.powersaving_on_off")}</p>
                        </>
                        :
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.powersaving_on_off")}</p>
                        </>
                    }
                </div>
                <div className="d-flex flex-row align-items-center my-2">
                    {variant === "basic"
                        ?
                        <>
                        {stoplight("red")}
                        <p className="ms-2 bold">{t("subscription.angle_detection_off")}</p>
                        </>
                        : variant === "boost"
                        ?
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.angle_detection_on")}</p>
                        </>
                        :
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.angle_detection_on")}</p>
                        </>
                    }
                </div>
                <div className="d-flex flex-row align-items-center my-2">
                    {variant === "basic"
                        ?
                        <>
                        {stoplight("red")}
                        <p className="ms-2 bold">{t("subscription.export_data_off")}</p>
                        </>
                        : variant === "boost"
                        ?
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.export_data_on")}</p>
                        </>
                        :
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.export_data_on")}</p>
                        </>
                    }
                </div>
                <div className="d-flex flex-row align-items-center my-2">
                    {variant === "basic"
                        ?
                        <>
                        {stoplight("red")}
                        <p className="ms-2 bold">{t("subscription.share_account_off")}</p>
                        </>
                        : variant === "boost"
                        ?
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.share_account_5")}</p>
                        </>
                        :
                        <>
                        {stoplight("green")}
                        <p className="ms-2 bold">{t("subscription.share_account_unlimited")}</p>
                        </>
                    }
                </div>
                {variant === "basic" || variant === "boost"
                    ? <p className="my-4 dark_grey"><sup>1</sup> {t("subscription.boost_disclaimer")}</p>
                    : <p className="my-4 dark_grey"><sup>2</sup> {t("subscription.boost_disclaimer_premium")}</p>
                }
            </>
        )
    }

    const stoplight = (val) => {
        let icon = ""
        val === "green"
        ? icon = <img src={icons.stoplight_green} alt=""/>
        : val === "yellow"
        ? icon = <img src={icons.stoplight_yellow} alt=""/>
        : icon = <img src={icons.stoplight_red} alt=""/>
        return icon
    }


    const basicContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <h3 className="dark_grey">{t("subscription.basic")}</h3>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                {stats("basic")}
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary">{t("settings.close")}</Button>
            </div>
            </>
        )
    }

    const boostConent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <h3 className="dark_grey">{t("subscription.boost")}</h3>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                {stats("boost")}
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary">{t("settings.close")}</Button>
            </div>
            </>
        )
    }

    const premiumContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <h3 className="dark_grey">{t("subscription.premium")}</h3>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                {stats("premium")}
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary">{t("settings.close")}</Button>
            </div>
            </>
        )
    }


    const typeRender = () => {
        switch(props.type){
            case "basic": return basicContent()
            case "boost": return boostConent()
            case "premium": return premiumContent()
            default: return null
        }
    }
    
    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                    {typeRender()}
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default SubscriptionModal;