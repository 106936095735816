import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as request from "../../services/FetchService";
import Button from "../Buttons/Button";
import { useContextData } from '../../components/ContextData';
import toast from 'react-hot-toast';
import { staticSort } from "../../helpers/helperFunctions";

const ContactForm = (props) => {
    const { i18n, t } = useTranslation();
    const { deviceListCompact } = useContextData();
    const [deviceList, setDeviceList] = useState()
    const [issueList, setIssueList] = useState()
    const [valid, setValid] = useState(false)
    const [selectedDevice, setSelectedDevice] = useState(-1)
    const [selectedIssue, setSelectedIssue] = useState(-1)
    const [message, setMessage] = useState("")
    const [messageSent, setMessageSent] = useState(false)

    useEffect(() => {
       // getDevices()
        getIssues()
    }, [])

    useEffect(() => {
		// This function will be called whenever the language changes
		const onLanguageChanged = () => {
            getIssues()
		//   getEventsPost(currentPage, currentFilter, currentTypes, currentDevices)

		  // You can perform other actions here when the language changes
		};
	
		// Register the event listener for language changes
		i18n.on('languageChanged', onLanguageChanged);
	
		// Cleanup function to remove the event listener
		return () => {
		  i18n.off('languageChanged', onLanguageChanged);
		};
	  }, [i18n]); // Dependency array to ensure effect is set up correctly

    const getIssues = () => {
        request.post("", "services/support/getissues", {language: localStorage.getItem("language") ? localStorage.getItem("language") : "en"}).then((response) => {
			setIssueList(response.data.issues);
            let sorted = staticSort(response.data.devices, "devicename")
            setDeviceList(sorted);
            setValid(response.data.valid)
		}); 
    }

    const sendMessage = async () => {
        if(!selectedDevice || selectedDevice === -1){
            toast.error(t("toast.no_device_selected"))
            return
        }
        if(!selectedIssue || selectedIssue === -1){
            toast.error(t("toast.no_issue_selected")) 
            return
        }
        if(!message || message === ""){
            toast.error(t("toast.no_message"))
            return
        }
        let data = {
            language: localStorage.getItem("language") ? localStorage.getItem("language") : "en",
            deviceid: parseInt(selectedDevice),
            issueid: parseInt(selectedIssue),
            message
        }
        request.post("", "services/support/send", data).then((response) => {
            if(response.data.successful){
                setMessageSent(true)
            }
		}); 

    }
return (
    <div className={`d-flex flex-column mt-4 mb-5 rounded-top bg-white shadow ${!valid && "d-none"}`}>
        <div className="d-flex flex-column rounded-top p-3">
            <h3>{t("settings.contact_support")}</h3>
            <p className="bold">{t("settings.contact_support_subtitle")}</p>
            <div className="d-flex flex-row flex-fill justify-content-between mt-3">
                <div className="d-flex flex-column flex-fill w-50 me-2">
                    <p className="bold">{t("settings.device")}</p>
                    <select
                        onChange={(event) => setSelectedDevice(event.target.value)}
                        value={selectedDevice}
                        className="form-control bg-light-grey"
                    >
                        <option value="">{t("settings.select")}</option>
                        {deviceList && deviceList.map((device, index) => {
                            return(
                                <option key={index} value={device.id}>{device.devicename}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="d-flex flex-column flex-fill w-50 ms-2">
                    <p className="bold">{t("settings.issue")}</p>
                    <select
                        onChange={(event) => setSelectedIssue(event.target.value)}
                        value={selectedIssue}
                        className="form-control bg-light-grey"
                    >
                        <option value="">{t("settings.select")}</option>
                        {issueList && issueList.map((issue, index) => {
                            return(
                                <option key={index} value={issue.id}>{issue.issuename}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
            <div className="d-flex flex-column mt-3">
                <p className="bold">{t("settings.message")}</p>
                <textarea rows={5} onChange={(event) => setMessage(event.target.value)} className="form-control bg-light-grey" />    
            </div>

        </div>
        <Button onClick={() => sendMessage()} btnClass={`btn-primary contact-button ${messageSent ? "disabled" : ""}`}>{messageSent ? t("settings.sent") : t("settings.send")}</Button>
    </div>
)
}

export default ContactForm;
