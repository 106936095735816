import { Accordion, AccordionSummary, Collapse } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { INDEX_URL, GOOGLE_MAPS_KEY, icons, FLAVOR } from "../../helpers/constants";
import { externalPowerText, fixDate, getBatteryIcon, getBatteryIconHori } from "../../helpers/helperFunctions";
import SegmentedControl from '../SegmentedControl/SegmentedControl';
import TextField from "@mui/material/TextField";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; // Import dayjs
import 'dayjs/locale/sv';
import { MapTogglesProvider, useMapToggles } from '../Map/ToggleContext'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';
import * as request from "../../services/FetchService";
import { useTranslation } from "react-i18next";
import DeviceInfoCard from './DeviceInfoCard';
import Button from '../Buttons/Button';
import toast from 'react-hot-toast';
import ZoneModal from '../Modals/ZoneModal';
import DeviceInfoCardTemporary from './DeviceInfoCardTemporary';


const DeviceRow = (props) => {
    const { t } = useTranslation();
    const [powerSaveValue, setPowerSaveValue] = useState(props.device.modelspecificinfo?.powersavingmode?.powersavingmodeinfo)
    const [powerSwitchValue, setPowerSwitchValue] = useState(props.device.modelspecificinfo?.powerswitch.powerswitchinfo)
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'day'))
    const [endDate, setEndDate] = useState(dayjs())
    const [toggleActive, setToggleActive] = useState(false)
    const [distance, setDistance] = useState(null)
    const [imgLoaded, setImgLoaded] = useState(false)
    const [showZoneModal, setShowZoneModal] = useState(false)
    const { setActiveDevice } = useMapToggles();
    const navigate = useNavigate();

    useEffect(() => {
        let mode = props.device.modelspecificinfo?.powersavingmode?.powersavingmodeinfo;
        setPowerSaveValue(mode);
    },[])
    
    const renderPowerSwitchSegment = (value) => {
        const segments = 
        props.device.modelspecificinfo?.relayswitch?.pending
        ?
        [{
            label: `${t("devices.power_pending")} ${props.device.modelspecificinfo?.relayswitch?.pendinginfo ? "On" : "Off"}`,
            value: !props.device.modelspecificinfo?.relayswitch?.pendinginfo
        }]
        :
        [
            {
                label: t("devices.on"),
                value: true
            },
            {
                label: t("devices.off"),
                value: false
            }
        ]
        return (
            <SegmentedControl
                name="psm"
                callback={(val) => handleChangePowerSwitchValue(val)}
                value={value}
                segments={segments}
                segmentWidth="w-100"
                pending={props.device.modelspecificinfo?.relayswitch?.pending}
            />
        )
    }

    const handleChangePowerSwitchValue = (value) => {
        let data = {
            deviceid: props.device.id,
            relayswitch: value
        }

        let url
        if(process.env.REACT_APP_FLAVOR === "bike"){
            url = "device/update"
        }else{
            url = "device/update-new"
        }

        request.post("", url, data).then((response) => {
            if(response.data.successful){
                props.refresh()
                toast.success(t("toast.success"))
            }else{
                toast.error(t("toast.error"))
            }
      
        }) 
    }

    const renderAlarmSwitch = (status) => {
        const segments = 
        props.device.modelspecificinfo?.alarmcenter?.triggered
        ?
        [{
            label: t("devices.triggered").toUpperCase(),
            value: false
        }]
        :
        [
            {
                label: t("devices.on"),
                value: true
            },
            {
                label: t("devices.off"),
                value: false
            }
        ]
        return(
            <SegmentedControl
            name="psm"
            callback={(val) => handleAlarmValue(val)}
            value={status}
            segments={segments}
            triggered={props.device.modelspecificinfo?.alarmcenter?.triggered}
        /> 
        )
    }

    const handleAlarmValue = (value) => {
        if(props.device.latitude == null && props.device.longitude == null){
            toast.error(t("toast.no_pos_alarm_center"))
            return
        }
        if(value && !props.device.modelspecificinfo?.alarmcenter?.triggered){
            setShowZoneModal(true)
        }else if(!value && props.device.modelspecificinfo?.alarmcenter?.triggered){
            setShowZoneModal(true)
        }else{
            toggleAlarm(false)
        }
    }

    const toggleAlarm = (value) => {
        let data = {
            deviceid: props.device.id,
            alarmcenter_arm: value
        }

        let url
        if(process.env.REACT_APP_FLAVOR === "bike"){
            url = "device/update"
        }else{
            url = "device/update-new"
        }

        request.post("", url, data).then((response) => {
            if(response.data.successful){
                props.refresh()
                setShowZoneModal(false)
                toast.success(t("toast.success"))
            }else{
                toast.error(t("toast.error"))
            }
      
        })  
    }

    const renderLocationHistory = () => {
        return (
            <div className="d-flex justify-content-start m-0 p-0">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='sv'>
                    <DateTimePicker
                    ampm={false}
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    maxDate={endDate}
                    className="date_label me-1"
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='sv'>
                    <DateTimePicker
                    ampm={false}
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    minDate={startDate}
                    className="date_label ms-1"
                    />
                </LocalizationProvider>
                <img src={icons.next_arrow_btn} onClick={() => getLocationHistory(props.device.id)} className="cursor-pointer ms-3" alt=""/>
            </div>
        )
    }

    const getLocationHistory = (id) => {
        let obj = {
			deviceid: id,
			starttime: startDate.toDate(),
			stoptime: endDate.toDate()
		}
        request.post("", "history/list", obj).then((response) => {
			if(!response.data.errorCode){
                navigate("/history", {state: {history: response.data}})
            }else{
				toast.error(response.data.message)
            }
		});
    }

    const showOnMap = (event, device) => {
        event.stopPropagation();
        if(device.latitude && device.longitude){
            setActiveDevice(device.id)
        }else{
            toast.error(t("toast.no_position_device_show"))
        }
    }


    const renderAlcoDetail = () => {
        return(
            <>
                <div className={`detail_container p-3`}>
                    <div className="row m-0 p-0 mb-3">
                        <div className="col-12 mt-2 text-center">
                            <img onLoad={() => setImgLoaded(true)} src={props.device.modelspecificinfo.modelphoto} className="w-50" alt="..."/>
                        </div>
                    </div>
                    <div className="row m-0 p-0 mb-3">
                        <p className="bold m-0 p-0">INFO</p>
                        
                        <div className="col-12 d-flex justify-content-around m-0 p-0">
                        <p><span className="bold">{t("devices.model")}:</span> {props.device.modelspecificinfo?.model}</p>
                        <p><span className="bold">{t("devices.imei")}:</span> {props.device.uniqueid}</p>

                            {/* {visibleToggles.map((toggle, index) => (
                                toggle.visible &&
                                <Button key={index} btnClass={toggle.value ? "btn-primary" : "btn-secondary"} icon={toggle.icon} notext={true} onClick={toggle.onclick} />
                            ))} */}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderBikeDetailTemporary = () => {

        let battery = {
            //valid: props.device.communicationmode.vehiclebattery.valid,
            valid: true,
           // info: `${props.device.communicationmode.vehiclebattery.info ?? 0}%`
           value: props.device.modelspecificinfo?.internalbattery?.internalbatteryinfo ?? 0,
           info: `${props.device.modelspecificinfo?.internalbattery?.internalbatteryinfo ?? 0}%`

        }
        let bikeStatus = {
            //valid: props.device.modelspecificinfo.ignition.ignitionvalid,
            valid: true,
            info: props.device.modelspecificinfo.ignition.ignitioninfo ? t("devices.device_on") : t("devices.device_off")
        }

        let visibleCards = [
            {
                name: "bikeStatus",
                visible: bikeStatus.valid,
                icon: bikeStatus.info ? icons.power_on : icons.power_off,
                label: fixDate(props.device.lastupdate, true),
                value: bikeStatus.info
            },
            {
                name: "battery",
                visible: battery.valid,
                icon: getBatteryIconHori(battery.value),
                label: t("devices.battery"),
                value: battery.info
            },
            {
                name: "show_on_map",
                visible: true,
                icon: icons.marker,
                value: t("devices.show"),
                label: t("devices.open_on_map"),
                onclick: (event) => showOnMap(event, props.device),
                spec: true
            }
        ]
        return(
            <>
                <div className={`detail_container p-3`}>
                    <div className="row m-0 p-0 mb-3">
                        <div className="col-12 mt-2 text-center">
                            <img onLoad={() => setImgLoaded(true)} src={props.device.modelspecificinfo.modelphoto} className="w-50" alt="..."/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        {visibleCards.map((card, index) => (
                            card.visible &&
                            <DeviceInfoCardTemporary key={index} spec={card.spec} onClick={card.onclick} name={card.name} index={index} value={card.value} label={card.label} icon={card.icon}/>
                        ))}
                    </div>
                </div>
            </>
        )
    }


    const renderBikeDetail = () => {
        let powerToggle = {
            valid: true,
            info: false,
        }
        let armedToggle = {
            valid: props.device.communicationmode.alarmswitch.valid,
            info: props.device.communicationmode.alarmswitch.info,  
        }
        let navigationToggle = {
            valid: true,
            info: true,
        }
        let mapToggle = {
            valid: true,
            info: true,
        }

        let battery = {
            valid: props.device.communicationmode.vehiclebattery.valid,
            info: `${props.device.communicationmode.vehiclebattery.info}%`
        }
        let bikeStatus = {
            valid: props.device.modelspecificinfo.ignition.ignitionvalid,
            info: props.device.modelspecificinfo.ignition.ignitioninfo ? "On" : "Off"
        }
        let assistance = {
            valid: props.device.communicationmode.assistancelevel.valid,
            info: props.device.communicationmode.assistancelevel.info
        }
        let distanceLeft = {
            valid: props.device.communicationmode.distanceleft.valid,
            info: props.device.communicationmode.distanceleft.info
        }
        let distanceToday = {
            valid: true,
            info: props.device.statistics.totaltoday.distance ? `${props.device.statistics.totaltoday.distance} ${props.device.statistics.measurement.distance_measurement}` : `0 ${props.device.statistics.measurement.distance_measurement}`
        }
        let candence = {
            valid: false,
            info: ""
        }
        let visibleToggles = [
            {
                name: "power",
                visible: powerToggle.valid,
                icon: icons.sidebar.bell_active,
                value: powerToggle.info,
                onclick: (event) => showOnMap(event, props.device)
            },
            {
                name: "armed",
                visible: armedToggle.valid,
                icon: icons.power_off,
                value: armedToggle.info,
                onclick: (event) => showOnMap(event, props.device)
            },
            {
                name: "navigation",
                visible: navigationToggle.valid,
                icon: icons.central,
                value: navigationToggle.info,
                onclick: (event) => showOnMap(event, props.device)

            },
            {
                name: "map",
                visible: mapToggle.valid,
                icon: icons.marker,
                value: mapToggle.info,
                onclick: (event) => showOnMap(event, props.device)
            }
        ]

        let visibleCards = [
            {
                name: "bikeStatus",
                visible: bikeStatus.valid,
                icon: icons.power_off,
                label: fixDate(props.device.lastupdate, true),
                value: bikeStatus.info
            },
            {
                name: "battery",
                visible: battery.valid,
                icon: icons.battery_half,
                label: t("devices.battery"),
                value: battery.info
            },
            {
                name: "assistance",
                visible: assistance.valid,
                icon: icons.battery_half,
                label: "Assistance",
                value: assistance.info
            },
            {
                name: "distanceLeft",
                visible: distanceLeft.valid,
                icon: icons.battery_half,
                label: t("devices.distance_left"),
                value: distanceLeft.info
            },
            {
                name: "distanceToday",
                visible: distanceToday.valid,
                icon: icons.battery_half,
                label: t("devices.distance_today"),
                value: distanceToday.info
            },
            {
                name: "candence",
                visible: candence.valid,
                icon: icons.battery_half,
                value: candence.info
            },
        ]
        return(
            <>
                <div className={`detail_container p-3`}>
                    <div className="row m-0 p-0 mb-3">
                        <div className="col-12 mt-2 text-center">
                            <img onLoad={() => setImgLoaded(true)} src={props.device.modelspecificinfo.modelphoto} className="w-50" alt="..."/>
                        </div>
                    </div>
                    <div className="row m-0 p-0 mb-3">
                        <p className="bold m-0 p-0">TOGGLES</p>
                        <div className="col-12 d-flex justify-content-between m-0 p-0">
                            {visibleToggles.map((toggle, index) => (
                                toggle.visible &&
                                <Button key={index} btnClass={toggle.value ? "btn-primary" : "btn-secondary"} icon={toggle.icon} notext={true} onClick={toggle.onclick} />
                            ))}
                        </div>
                    </div>
                    <div className="row m-0 p-0">
                        <p className="bold m-0 p-0">STATS</p>
                        <div className="row m-0 p-0">
                            {visibleCards.map((card, index) => (
                                card.visible &&
                                <DeviceInfoCard key={index} name={card.name} index={index} value={card.value} label={card.label} icon={card.icon}/>
                            ))}
                        </div>

                    </div>
                </div>
            </>
        )
    }

    const renderDeviceDetail = () => {
        let powerSupply = {
            valid: props.device.modelspecificinfo?.externalpowersupply?.externalpowervalid,
            value: props.device.modelspecificinfo?.externalpowersupply?.externalpowerinfo
        }

        let externalBattery = {
            valid: props.device.visibleswitch?.devicelist?.battery_external,
            value: props.device.modelspecificinfo.externalbattery.externalbatteryinfo
        }
        let internalBattery = {
            valid: props.device.visibleswitch?.devicelist?.battery_internal,
            value: props.device.modelspecificinfo.internalbattery.internalbatteryinfo ? props.device.modelspecificinfo.internalbattery.internalbatteryinfo : 0  
        }
        let distance = {
            valid: props.device.visibleswitch?.devicelist?.distancetoday,
            value: "HEJ"
        }
        let temperature = {
            valid: props.device.visibleswitch?.devicelist.temperature,
            value: (props.device.modelspecificinfo?.temp_hum?.currentdata?.temperature/100).toFixed(1)
        }
        let humidity = {
            valid: props.device.visibleswitch?.devicelist.humidity,
            value: (props.device.modelspecificinfo?.temp_hum?.currentdata?.humidity/100).toFixed(1)
        }
        let altitude = {
            valid: props.device.visibleswitch?.devicelist.altitude,
            value: props.device.modelspecificinfo?.positioninfo?.altitude ? `${props.device.modelspecificinfo?.positioninfo?.altitude}m ${t("devices.asl")}` : "N/A"
        }
        let ignition = {
            valid: props.device.visibleswitch?.devicelist.ignition,
            value: props.device.modelspecificinfo.ignition.ignitioninfo
        }
        let dataplan = {
            valid: props.device.visibleswitch?.devicelist.dataplan,
            value: new Date(props.device.dataplanexpiringdate) > new Date()
        }
        let relay = {
            // valid: props.device.visibleswitch.devicelist.relay_switch,
            valid: props.device.modelspecificinfo?.relayswitch?.valid,
            value: props.device.modelspecificinfo?.relayswitch?.currentinfo,
            pending: {
                status: props.device.modelspecificinfo?.relayswitch?.pending,
                value: props.device.modelspecificinfo?.relayswitch?.pendinginfo
            }
        }
        let alarmCentral = {
            valid:  props.device.visibleswitch?.devicelist?.alarmcentral_switch,
            value: props.device.modelspecificinfo?.alarmcenter?.armed
        }
        let location = {
            valid:  props.device.visibleswitch?.devicelist?.locationhistory,
        }
        let visibleCards = [
            {
                //External power
                name: "external_battery",
                visible: externalBattery.valid,
                icon: icons.volt,
                label: t("devices.external_power"),
                value: externalBattery.value ? `${externalBattery.value} V` : "N/A"
            },
            {
                //Battery
                name: "internal_battery",
                visible: internalBattery.valid,
                icon: powerSupply.value ? icons.battery.battery_charge : getBatteryIcon(internalBattery.value),
                label: t("devices.battery"),
                value: powerSupply.value ? t(`devices.${externalPowerText(t, props.device.modelspecificinfo?.id)}`) : `${internalBattery.value}%`
            },
            {
                //Distance
                name: "distance",
                visible: distance.valid,
                icon: icons.distance,
                label: t("devices.distance_today"),
                value: `${distance.value} km`
            },
            {
                //Temperature
                name: "temperature",
                visible: temperature.valid,
                icon: icons.temperature,
                label: t("devices.temperature"),
                value: `${temperature.value}${props.device.modelspecificinfo.temp_hum?.currentsettings?.tempType === 1 ? "°F" : "°C"}`
            },
            {
                //Humidity
                name: "humitidty",
                visible: humidity.valid,
                icon: icons.humidity,
                label: t("devices.humidity"),
                value: `${humidity.value}%`
            },
            {
                //Altitude
                name: "altitude",
                visible: altitude.valid,
                icon: icons.back,
                label: t("devices.altitude"),
                value: `${altitude.value}`
            },
            {
                //Ignition
                name: "ignition",
                visible: ignition.valid,
                icon: icons.ignition,
                label: t("devices.ignition"),
                value: `${ignition.info ? t("devices.on") : t("devices.off")}`
            },
            {
                name: "dataplan",
                visible: dataplan.valid,
                icon: icons.sidebar.card_active,
                label: t("devices.dataplan"),
                value: `${dataplan.value ? t("devices.on") : t("devices.off")}`
            }
        ]


        return (
            <>
                <div className={`detail_container pb-3`}>
                    <div className="row m-0 p-0">
                        <div className="col-12 mt-2 text-center">
                            <img onLoad={() => setImgLoaded(true)} src={props.device.modelspecificinfo.modelphoto} className="w-50" alt="..."/>
                        </div>
                    </div>
                    {/* TINY CARDS */}
                    <div className="row align-items-center m-0 p-0 pb-2">
                        <div className="col-1 m-0 p-0 ps-2">
                            <img className='primary-icon size_20' src={icons.info_icon} alt="..." />
                        </div>
                        <div className="col-11 m-0 p-0">
                            <p className="primary bold uppercase">{t("devices.info")}</p>
                        </div>
                    </div>
                    <div className="row align-items-center m-0 p-0 mb-3">
                        <div className="col-1 m-0 p-0 ps-2" />
                        <div className="col-11 m-0 p-0">
                            <div className="row  m-0 p-0">
                                {visibleCards.map((card, index) => (
                                    card.visible &&
                                    <DeviceInfoCard key={index} name={card.name} index={index} value={card.value} label={card.label} icon={card.icon}/>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* ALARM CENTRAL */}
                    {alarmCentral.valid &&
                        <div className="mb-3">
                            <div className="row align-items-center m-0 p-0">
                                <div className="col-1 m-0 p-0 ps-2">
                                    <img className='primary-icon size_20' src={icons.central} alt="..." />
                                </div>
                                <div className="col-11 m-0 p-0">
                                    <p className="primary bold uppercase">{t("devices.alarm_central_arm")}</p>
                                </div>
                            </div>
                            <div className="row align-items-center m-0 p-0">
                                <div className="col-1 m-0 p-0 ps-2" />
                                <div className="col-11 m-0 p-0">
                                    <div className="w-100 pe-3" style={{height: "40px"}}>{renderAlarmSwitch(alarmCentral.value)}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* POWER SWITCH */}
                    {relay.valid &&
                        <div className="mb-3">
                            <div className="row align-items-center m-0 p-0">
                                <div className="col-1 m-0 p-0 ps-2">
                                    <img className='primary-icon size_20' src={icons.relay} alt="..." />
                                </div>
                                <div className="col-11 m-0 p-0">
                                    <p className="primary bold uppercase">{t("devices.power_switch")}</p>
                                </div>
                            </div>
                            <div className="row align-items-center m-0 p-0">
                                <div className="col-1 m-0 p-0 ps-2" />
                                <div className="col-11 m-0 p-0">
                                    <div className="w-100 pe-3" style={{height: "40px"}}>{renderPowerSwitchSegment(relay.pending.status ? relay.pending.value : relay.value)}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* LOCATION HISTORY */}
                    {location.valid &&
                    <>
                        <div className="row align-items-center m-0 p-0">
                            <div className="col-1 m-0 p-0 ps-2">
                                <img className='primary-icon size_20' src={icons.sidebar.history_active} alt="..." />
                            </div>
                            <div className="col-11 m-0 p-0">
                                <p className="primary bold uppercase">{t("devices.location_history")}</p>
                            </div>
                        </div>
                        <div className="row align-items-center m-0 p-0">
                            <div className="col-1 m-0 p-0 ps-2" />
                            <div className="col-11 m-0 p-0">
                                <div className="w-100 pe-3" style={{height: "40px"}}>{renderLocationHistory()}</div>
                            </div>
                        </div>
                    </>
                    }

                </div>
            </>
        )
    }

    const toggleRelay = (event, id) => {
        event.stopPropagation();
        props.setDeviceToggle(id)
    }


    useEffect(() => {
        setToggleActive(props.device.selected)
    }, [props.device.selected])

    

      useEffect(() => {
        if(props.deviceListLength === 1){
            props.setCurrentDevice(props.device.id)
        }
      }, [props.deviceListLength])


    const getUpdateStatus = () => {
        if(props.device.status === "online"){
            return(
                <>
                <span className={`dot bg-online`}/>
                <p className="ps-2">{t("devices.now")}</p>
                </>
            )
        }else if(props.device.lastupdate){
            return(
                <>
                <span className={`dot bg-offline`}/>
                <p className="ps-2">{fixDate(props.device.lastupdate, false, true)}</p>
                </>
            )
        }else{
            return(
                <>
                <span className={`dot bg-neverconnected`}/>
                <p className="ps-2">{t("devices.never_connected")}</p>
                </>
            )
        }
    }
      
    
    return(
        <>
            <div className={`row m-0 cursor-pointer ps-3 py-3 ${props.currentDevice === props.device.id ? "bg-primary" : props.index % 2 === 0 ? "bg-white primary" : "bg-light-grey primary shadow-inner"}`} onClick={() => props.setCurrentDevice(props.currentDevice === props.device.id ? -1 : props.device.id)}>
                <div className="col-4 m-0 p-0 text-truncate d-flex align-items-center">
                    <div className={`me-3 ${!props.checkboxVisibility ? "d-none" : toggleActive ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1`} onClick={(event) => toggleRelay(event, props.device)}>
                        {toggleActive && <img className="white-icon" src={icons.check} alt={""} />}
                    </div>
                
                    <p className="bold text-truncate">{props.device.name}</p>
                </div>
                <div className="p-0 m-0 col-8">
                    <div className={`row d-flex justify-content-around m-0 p-0 device-row align-items-center`}>
                        <div className="col-5 p-0 margin_top_1_point_5 justify-content-around">
                            <div className="d-flex flex-row align-items-center">
                                {getUpdateStatus()}
                            </div>
                        </div>
                        <div className={`col-3 m-0 ps-3 p-0 ${FLAVOR === "bike" ? "opacity-0 disabled" : ""}`}>
                            {props.device.groupname 
                                ? <p className="text-truncate bold">{props.device.groupname}</p>
                                : <p>{""}</p>
                            }
                        </div>
                        <div className="col-2 m-0 p-0">
                            {props.device.modelspecificinfo.internalbattery.internalbatteryvalid &&
                                <p className="bold">{props.device.modelspecificinfo.internalbattery.internalbatteryinfo}%</p>
                            }
                        </div>
                        {(props.device.latitude && new Date(props.device.dataplanexpiringdate) < new Date() && FLAVOR !== "alco" )
                            ?
                            <div className={`d-flex flex-column justify-content-center col-1 m-0 p-0 cursor-pointer`} onClick={(event) => 
                                {
                                    event.stopPropagation();
                                    toast(t("toast.no_position_device"), 
                                    {icon: <img  className="size_20" src={icons.info_icon} alt=""/>})
                                    showOnMap(event, props.device)
                                }
                            }>
                                <img className={`${props.currentDevice === props.device.id ? "white-icon" : ""} device_row_map_icon m-0 align-self-center`} src={icons.info_icon} alt={""}/>
                                <p className="justify-content-center d-none">{distance ? distance + t("devices.km") : ""}</p>
                            </div>
                            :
                            
                            <div className={`d-flex flex-column justify-content-center col-1 m-0 p-0 ${props.device.latitude ? "cursor-pointer" : "opacity-0"}`} onClick={(event) => showOnMap(event, props.device)}>
                                <img className={`${props.currentDevice === props.device.id ? "white-icon" : "primary-icon"} device_row_map_icon m-0 align-self-center`} src={props.device.latitude ? icons.location_marker_online : icons.location_marker_offline} alt={""}/>
                                <p className="justify-content-center d-none">{distance ? distance + t("devices.km") : ""}</p>
                            </div>
                        }


                    </div>

                </div>
            </div>
            <Collapse in={props.currentDevice === props.device.id} mountOnEnter unmountOnExit>
                {FLAVOR === "tracking" ? renderDeviceDetail() : FLAVOR === "bike" ? renderBikeDetailTemporary() : FLAVOR === "alco" ? renderAlcoDetail() : null}
            </Collapse>
            <ZoneModal show={showZoneModal} triggered={props.device.modelspecificinfo?.alarmcenter?.triggered} deactivateAlarm={() => toggleAlarm(false)} activateAlarm={() => toggleAlarm(true)} device={props.device} onClose={() => setShowZoneModal(false)} />
        </>
        
    )
}

export default DeviceRow;