import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons } from "../../helpers/constants";
import { fixDate, fixDateEvent, getEventIcon, translateEvents } from '../../helpers/helperFunctions';
import { useTranslation } from "react-i18next";
import { MapTogglesProvider, useMapToggles } from '../Map/ToggleContext'; // Adjust the path as necessary
import toast from 'react-hot-toast';
import { Collapse } from '@mui/material';
import { useIsMobile } from '../../helpers/customHooks';

const EventRow = (props) => {
	const { t } = useTranslation();
    const { activeEvent, setActiveEvent } = useMapToggles();
    const [toggleActive, setToggleActive] = useState(false)
    const [address, setAddress] = useState()
    const MOBILE = useIsMobile();

    const showOnMap = (event, alarm) => {
        event.stopPropagation();
        if(activeEvent === alarm){
            setActiveEvent(null)
        }else if(alarm.latitude && alarm.longitude){
            setActiveEvent(alarm)
        }else{
            setActiveEvent(null)
            toast(t("toast.no_position"), 
            {icon: <img  className="size_20" src={icons.info_icon} alt=""/>,
            duration: 2000})
        }
    }

    const openEvent = (event, alarm) => {
        event.stopPropagation();
        props.setCurrentEvent(props.currentEvent === alarm.eventid ? -1 : alarm.eventid)
        if(activeEvent === alarm){
            setActiveEvent(null)
        }else if(alarm.latitude && alarm.longitude){
            setActiveEvent(alarm)
        }else{
            setActiveEvent(null)
            if(props.currentEvent !== alarm.eventid){
                toast(t("toast.no_position"), 
                {icon: <img  className="size_20" src={icons.info_icon} alt=""/>,
                duration: 2000})
            }
        }
    }

    const toggleEvent = (event, id) => {
        event.stopPropagation();
        props.setEventToggle(id.eventid)
    }

    useEffect(() => {
        setToggleActive(props.event.selected)
    }, [props.event.selected])


    // useEffect(() => {
    //     if(props.checkedEvents.includes(props.event.eventid)){
    //         setToggleActive(true)
    //     }else{
    //         setToggleActive(false)
    //     }
    // }, [props.checkedEvents])

    const renderExtendedInfo = () => {

        const calculateAddress = (lat, lng) => {
            if(!address){
                const geocoder = new window.google.maps.Geocoder();
                const latandLng = new window.google.maps.LatLng(lat, lng);
                geocoder.geocode({ location: latandLng }, (results, status) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            setAddress(results[0].formatted_address)
                        } else {
                            return
                        }
                    } else {
                        return
                    }
                })
            }
        }
        
        // const margin = (MOBILE && (props.index+1) % 3 === 0) || (!MOBILE && (props.index+1) % 4 === 0) ? "0" : "3"

        const additionalCard = (length, type, info, index)=> {
            let text1;
            let text2;
            let value;
            switch(type){
                case "deviceOverspeed":
                    value = info.value
                    text1 = t("events.speed_limit")
                    text2 = t("events.noted_speed")
                    break;
                case "geofenceEnter":
                    value = info.value
                    text1 = t("events.affected_geofence")
                    text2 = ""
                    break;
                case "geofenceExit":
                    value = info.value
                    text1 = t("events.affected_geofence")
                    text2 = ""
                    break;
                case "deviceWake":
                    value = info.value
                    text1 = t("events.additional_info")
                    text2 = ""
                    break;
                case "alarmcenter":
                    value = info.value
                    text1 = t("events.additional_info")
                    text2 = ""
                    break;
                case "deviceTempExceeded":
                    value = `${(info.value/100).toFixed(2)}°`
                    text1 = t("events.temp_limit")
                    text2 = t("events.temp_noted")
                    break;
                case "deviceTempBelow":
                    value = `${(info.value/100).toFixed(2)}°`
                    text1 = t("events.temp_limit")
                    text2 = t("events.temp_noted")
                    break;
                case "deviceHumExceeded":
                    value = `${(info.value/100).toFixed(2)}`
                    text1 = t("events.hum_limit")
                    text2 = t("events.hum_noted")
                    break;
                case "deviceHumBelow":
                    value = `${(info.value/100).toFixed(2)}`
                    text1 = t("events.hum_limit")
                    text2 = t("events.hum_noted")
                    break;
                default: {}
            }
            return(
            <div key={index} className={`col-${12/length} m-0 p-0 pe-3 pb-${3}`}>
                <div className={`position-relative card border-12 border-0 d-flex flex-column deviceInfoCard justify-content-center align-items-center`}>
                    <img src={getEventIcon(props.event.type)} alt="" className={`size_15 position-absolute top-0 end-0 me-1 mt-1 primary-icon`}/>
                    <h4 className='primary'>{value} {info.unit && info.unit}</h4>
                    <p className="thin dark_grey text-truncate">{index === 0 ? text1 : text2}</p>
                </div>
            </div>
            )
        }

        return(
            <div className="p-3">
                <div className="row m-0 p-0">
                    <div className={`col-${MOBILE ? "4" : "8"} m-0 p-0 pe-3 pb-${3}`}>
                        <div className={`position-relative card border-12 border-0 d-flex flex-column deviceInfoCard justify-content-center align-items-center`}>
                            <img src={icons.sidebar.device_active} alt="" className={`size_15 position-absolute top-0 end-0 me-1 mt-1 primary-icon`}/>
                            <h4 className='primary'>{props.event.devicenameorfrom}</h4>
                            <p className="thin dark_grey text-truncate">{t("events.device")}</p>
                        </div>
                    </div>
                    <div className={`col-${MOBILE ? "4" : "4"} m-0 p-0 pe-3 pb-${3}`}>
                        <div className={`position-relative card border-12 border-0 d-flex flex-column deviceInfoCard justify-content-center align-items-center`}>
                            <img src={icons.clock} alt="" className={`size_15 position-absolute top-0 end-0 me-1 mt-1 primary-icon`}/>
                            <h4 className='primary'>{fixDateEvent(props.event.date, false)}</h4>
                            <p className="thin dark_grey text-truncate">{fixDateEvent(props.event.date)}</p>
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className={`col-${MOBILE ? "4" : "12"} m-0 p-0 pe-3 pb-${3} cursor-pointer`} onClick={() => props.event.latitude === null ? {} : calculateAddress(props.event.latitude, props.event.longitude)}>
                        <div className={`position-relative card border-12 border-0 d-flex flex-column deviceInfoCard justify-content-center align-items-center`}>
                            <img src={icons.search} alt="" className={`size_15 position-absolute top-0 end-0 me-1 mt-1 primary-icon`}/>
                            {(props.event.latitude === null || props.event.longitude === null) 
                            ?
                            <>
                            <h4 className='primary'>{t("events.no_position")}</h4>
                            <p className="thin dark_grey text-truncate">{t("events.additional_info")}</p>
                            </>
                            :
                            <>
                            <h4 className='primary mx-3'>
                                {address ?
                                    address 
                                    : props.event.latitude.toFixed(5) + ", " + props.event.longitude.toFixed(5)
                                }
                                </h4>
                            <p className="thin dark_grey text-truncate">{t("events.tap_to_calculate")}</p>
                            </>
                            }
                        </div>
                    </div>
                </div>
                
                <div className="row m-0 p-0">
                {props.event.additionalinfo && Object.values(props.event.additionalinfo).map((info, index) => {
                    const additionalInfoKeys = Object.keys(props.event.additionalinfo);
                    return(
                        additionalCard(additionalInfoKeys.length, props.event.type, info, index)
                    )
                })}
                </div>

            </div>
        )
    }

    return (
        <>
        {/* <div className={`cursor-pointer ${props.index % 2 === 0 ? "bg-white" : "bg-light-grey shadow-inner"}`} onClick={(event) => showOnMap(event, props.event)}> */}
        <div className={`cursor-pointer ${props.currentEvent === props.event.eventid ? "bg-primary" : props.index % 2 === 0 ? "bg-white" : "bg-light-grey shadow-inner"}`} onClick={(event) => openEvent(event, props.event)}>
            <div className="p-3 align-items-center justify-content-between d-flex flex-row">
                <div className="d-flex flex-row align-items-center">

                    <div className={`me-3 ${!props.checkboxVisibility ? "d-none" : toggleActive ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1`} onClick={(event) => toggleEvent(event, props.event)}>
                        {toggleActive && <img className="white-icon" src={icons.check} alt={""} />}
                    </div>
                    <div className="eventImgBg me-3">
                        <img className="eventImg" src={getEventIcon(props.event.type)} alt={""} />
                    </div>
                    <div className="d-flex flex-column">
                        <h4 className="bold mb-1">{props.event.typedesc}</h4>
                        <p className={`text-truncate ${props.currentEvent === props.event.eventid ? "" : "dark_grey"}`}>{props.event.devicenameorfrom}</p>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center me-3">
                    <div className="d-flex flex-column me-3">
                        <p className="bold mb-1">{t("events.date")}</p>
                        <p className={`${props.currentEvent === props.event.eventid ? "" : "dark_grey"}`}>{fixDateEvent(props.event.date)}</p>
                    </div>
                    <div className="d-flex flex-column">
                        <p className="bold mb-1">{t("events.time")}</p>
                        <p className={`${props.currentEvent === props.event.eventid ? "" : "dark_grey"}`}>{fixDateEvent(props.event.date, false)}</p>
                    </div>
                </div>
            </div>
        </div>
        <Collapse in={props.currentEvent === props.event.eventid}>
            {renderExtendedInfo()}
        </Collapse>
        </>
    )
}

export default EventRow;