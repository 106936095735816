import { useRef, useState, useEffect } from "react";
/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */
const MonthYearSegment = ({
  period,
  name,
  segments,
  callback,
  segmentWidth,
}) => {
  const componentReady = useRef();
  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true;
  }, []);

    const onInputChange = (name, value) => {
        callback(value);
    };

  return (
    <div className={`controls-container ${segmentWidth ? segmentWidth : ""}`}>
      <div
        className={`controls ${
          componentReady.current ? "ready" : "idle"
        } col-12`}
      >
        {segments?.map((item, i) => (
          <div
            key={item.value}
            className={`segment ${
              item.value === period ? "active shadow-small" : "inactive"
            } col`}
            ref={item.ref}
          >
             <p className={`col-12 px-3 bold 
             ${item.value === period ? "white" : "primary"}`} 
             htmlFor={item.label} 
            >
            <input
              type="radio"
              value={item.value}
              id={name}
              name={name}
              onChange={() => onInputChange(name, item.value)}
              checked={item.value === period}
            />
              {item.label}{" "}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthYearSegment;
