import { useRef, useState, useEffect } from "react";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */
const SegmentedControl = ({ value, name, segments, callback, segmentWidth, triggered = false, pending = false}) => {
	const componentReady = useRef();
	// Determine when the component is "ready"
	useEffect(() => {
		componentReady.current = true;
	}, []);

	const onInputChange = (name, value) => {
		callback(value);
	};

	return (
		<div className={`controls-container col-12 px-0 ${segmentWidth ? segmentWidth : ""}`}>
			<div className={`controls device ${componentReady.current ? "ready" : "idle"} col-12`}>
				{segments?.map((item, i) => (
					<div key={item.value} className={`segment device ${triggered ? "triggered" : (item.value === value || pending) ? "active" : "inactive"} col`} ref={item.ref}>
						<p className={`col-12 px-0 bold ${(item.value === value) || triggered || pending ? "white" : "primary"}`} htmlFor={item.label}>
							<input type="radio" value={item.value} id={name} name={name} onChange={() => onInputChange(name, item.value)} checked={item.value === value} />
							{item.label}
						</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default SegmentedControl;
