import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { useContextData } from '../ContextData';
import { useIsMobile } from '../../helpers/customHooks';
import axios from 'axios';
import SafetyZoneMap from '../Map/SafetyZoneMap';


const ZoneModal = (props) => {
    const MOBILE = useIsMobile();
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)

    useEffect(() => {
        setShow(props.show)

    },[props.show])

    
    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }



    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                <>
                    <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                        <div className='d-flex align-items-center'>
                            <img src={icons.central} alt=""/>
                            <h3 className="ms-2">{t("devices.arm_zone")}</h3>
                        </div>
                        <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
                    </div>
                        {props.triggered 
                        ?
                        <>
                        <div className="p-3">
                            <p>{t("devices.arm_zone_body_deactivate", {central: props.device.modelspecificinfo?.alarmcenter?.centername})}</p>
                        </div>
                        <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
                            <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("devices.cancel")}</Button>
                            <Button btnClass="btn-primary" onClick={() => props.deactivateAlarm()}>{t("devices.deactivate")}</Button>
                        </div>
                        </>
                        :
                        <>
                        <div className="p-3">
                            <p>{t("devices.arm_zone_body", {central: props.device.modelspecificinfo?.alarmcenter?.centername})}</p>
                            <div className="shadow-sm border-12 safety-map-border mt-3" style={{width: "auto", height: "200px"}}>
                                <SafetyZoneMap device={props.device}/>
                            </div>
                        </div>
                        <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
                            <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("devices.cancel")}</Button>
                            <Button btnClass="btn-primary" onClick={() => props.activateAlarm()}>{t("devices.confirm")}</Button>
                        </div>
                        </>
                    }

                </>
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default ZoneModal;