import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
 import './assets/css/custom.css';
//  import './assets/css/scolor.css';
//  import './assets/css/fonts.css';
//  import './assets/css/typography.css';
// import './assets/css/main.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import './i18n';
import { createRoot } from 'react-dom/client';
import './translations/i18n';
import { ContextDataProvider } from './components/ContextData';
import AxiosSetup from './services/AxiosSetup';

require(`./assets/customer/${process.env.REACT_APP_ASSETS}/css/scolor.css`);
require(`./assets/customer/${process.env.REACT_APP_ASSETS}/css/fonts.css`);
require(`./assets/customer/${process.env.REACT_APP_ASSETS}/css/typography.css`);

const container = document.getElementById('root')
const root = createRoot(container);
root.render(
        <ContextDataProvider>
            <App />
        </ContextDataProvider>

);

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-kQtW33rZJAHjgefvhyyzcGF3C5TFyBQBA13V1RKPf4uH+bwyzQxZ6CmMZHmNBEfJ" crossorigin="anonymous"></script>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
