import React, { useState, useEffect } from 'react';
import { fixDate } from '../../helpers/helperFunctions';
const SafetyRow = (props) => {
    
    
    return(
        <>
        <div key={props.index} className={`d-flex justify-content-between p-3 ${props.index % 2 === 0 ? "bg-white" : "bg-light-grey shadow-inner"}`}>
            <div className="d-flex flex-column">
                <h4 className="primary mb-1">{props.history.client_model}</h4>
                <p className=" bold primary">{props.history.operating_system}</p>
            </div>
            <p className="d-flex align-self-end primary">{fixDate(props.history.date_time)}</p>
        </div>
        </>
    )
}

export default SafetyRow;